import { Component, OnInit } from '@angular/core';
import { SearchMktService } from '../services/search-mkt.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { roles } from '@shared/master-enum.enum';
import * as _ from 'lodash';
import { HttpService } from '../../../services/http.service';
import { MenuService } from '@core/page-header/service/menu.service';

@Component({
  selector: 'app-search-mkt-dashboard',
  templateUrl: './search-mkt-dashboard.component.html',
  styleUrls: ['./search-mkt-dashboard.component.css']
})
export class SearchMktDashboardComponent implements OnInit {
  dashboardData;
  notifications;
  selectedSite;
  pageNo = 1;
  showMore;
  constructor(
    private searchMktService: SearchMktService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private router: Router,
    private menuService: MenuService
  ) {
   }

  ngOnInit() {
    this.getDashboardData();
    this.getNotifications(0,this.pageNo);
  }

  getDashboardData() {
    this.searchMktService.getDasboardData().subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  gotoCustomer(productType) {
    let currentRole = JSON.parse(localStorage.getItem('userInfo')).roleId;
    let role = _.find(roles, (role) => {
      return role.roleId == currentRole;
    })
    let params = {};
    params = { product_type: productType || null, isNew: false,taskStatus: -1  };
    this.router.navigate([`${role.url}/sites`], { queryParams: params });
  }

  gotoProjects(productType) {
    let currentRole = JSON.parse(localStorage.getItem('userInfo')).roleId;
    let role = _.find(roles, (role) => {
      return role.roleId == currentRole;
    })
    let params = {};
    params = { product_type: productType || null };
    this.router.navigate([`${role.url}/projects`], { queryParams: params });
  }

  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }

}
