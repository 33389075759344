import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { UserService } from '../../admin/users/services/user-service.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { TicketService } from '../../ticket/service/ticket.service';
import { MenuService } from '../../../core/page-header/service/menu.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { from } from 'rxjs';
import * as _ from 'lodash';
import { SiteService } from '../../site/service/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploadService } from '@shared/services/file-upload.service';
import { routes } from 'app/app.routing';
import { HttpService } from '../../../services/http.service';
import { AssetsComponent } from '../../customer/intakes/add-intakes-data/basic/content/assets/assets.component';
import { masterEnum } from '@shared/master-enum.enum';
import { MasterService } from '../../admin/masters/services/master.service';
import { DataService } from '@shared/data-service.service';
import { AccountManager, SiteInfo } from '@shared/interfaces/interfaces';
declare var show: Function;
declare var hide: Function;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {
  baseUrl = environment.imgUrl;
  siteInfo: SiteInfo;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private ticketService: TicketService,
    private menuService: MenuService,
    private renderer: Renderer2,
    private el: ElementRef,
    private httpService: HttpService,
    private router: Router,
    private fileUploadService: FileUploadService,
    public modalService: NgbModal,
    private toastrService: ToastrService,
    private siteService: SiteService,
    private activatedRoute:ActivatedRoute,
    private masterService: MasterService,
    public dataService:DataService
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.activatedUrl = urlPath[urlPath.length - 1].path;
      localStorage.setItem('previousUrl', this.activatedUrl);
    })
  }

  public masterTypeId;
  roles;
  roleName;
  subject;
  loading:boolean=false;
  activatedUrl;
  ticketType;
  userProfile;
  isFileInvalid = false;
  uploadData: FormData = new FormData();
  imgUrl;
  activeTicket;
  resolvedTicket;
  photographs: any = [];
  ticketForm: UntypedFormGroup;
  intakeProduct;
  wpSSOUrl = environment.wpSSO;
  userInfo;
  notifications;
  siteAccountManager: AccountManager;
  selectedSite;
  pageNo = 1;
  page = 1;
  showMore;
  wpSitePath;
  emailPrvoiderPath;
  isLive;
  isMigrated;
  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
    this.getUserProfile();
    this.getSiteInfo(this.selectedSite.siteId);
    this.getAmInfo(this.selectedSite['siteId']); 
    this.getRoles();
  }

  getRoles() {
    this.masterTypeId = masterEnum['role'];
    this.masterService.getMastersListById(this.masterTypeId).subscribe(res => {
      if (res) {
        this.roles = res.masterList;
        _.forEach(this.roles, (role) => {
          if(this.userInfo.roleId==role.id){
            this.roleName=role.name;
          }
        })
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getUserProfile() {
    if (_.isEmpty(this.userService.UserProfile)) {
      this.userService.getUserProfile(this.userInfo.userId).subscribe((res) => {
        if (res) {
          this.initializeForm(res);
          this.userService.UserProfile = res;
        }
      },
        err => {
          throw err;
        })
    } else {
      this.initializeForm(this.userService.UserProfile);
    }
  }

  getAmInfo(siteId) {
    this.userService.getAmInfo(siteId).subscribe((res) => {
      if (res) {
        this.siteAccountManager = res;
      }
    },
      err => {
        throw err;
      })
  }

  initializeForm(user?) {
    let site = _.find(user.site, (val) => {
      return val.siteId == this.selectedSite.siteId;
    })
    this.ticketForm = this.formBuilder.group({
      CreatedBy: ['Customer'],
      Summary: [null,[Validators.required]],
      Description: [null,[Validators.required]],
      WebSite: [site ? site.domainUrl : '',[Validators.required]],
      siteId: [site ? site.siteId : '']
    });
  }
  strip(html){
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    setTimeout(() => {
       tmp.remove();
    }, 2000);
    return tmp.textContent || tmp.innerText || "";
   }
  createTicket(form) {
     if(!form.valid){
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
      return;
     }
    let data = form.value;
    data = _.omit(data, ['WebSite']);
    data.Description=this.strip(data.Description);
    data.attachment = { fileName: this.fileName };
    //form.value.attachment.fileName=this.fileName;
    this.ticketService.createTicket(data).subscribe((res) => {
      if (res) {

        this.toastrService.success('Ticket created', 'Success');
        this.ticketForm.reset();
        this.initializeForm(this.userService.UserProfile);
        this.fileName = '';
        this.photographs = [];
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);

      })
  }
  onFileChanged(event) {
    if (event) {
      let file = event.target.files[0];
      this.uploadData.append('myFile', file, file.name);
      var checkimg = event.target.value.toLowerCase();
      if (!checkimg.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.pdf|\.PDF|\.docx|\.DOCX|\.csv|\.CSV|\.xlsx|\.XLSX)$/)) {
        this.isFileInvalid = true;
        return false;
      } else {
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();

          reader.readAsDataURL(event.target.files[0]); // read file as data url

          reader.onload = (event) => { // called once readAsDataURL is completed
            this.imgUrl = event.target['result'];
            this.isFileInvalid = false;
          }
        }
      }
      this.uploadFile();
    }

  }
  fileName;
  uploadFile() {
    if (this.uploadData.has("myFile")) {
      this.uploadData.append("AttachmentFor", 'JIRA')
      this.userService.uploadProfileImg(this.uploadData).subscribe(res => {
        this.fileName = res.File;
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }
  }

  getTickets(type, page?) {
    this.ticketType = type;
    let params = {
      pageNo: page || 0,
      ticketType: type,
      status: (this.ticketType == 7 || this.ticketType == 21) ? 'NA' : '',
      siteId: this.selectedSite ? this.selectedSite.siteId : null,
      subject:this.subject
    }
    this.ticketService.getTickets(params).subscribe((res) => {
      if (res) {
        if (type ==7)
          this.resolvedTicket = res;
        else
          this.activeTicket = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  getIntakeProductList(siteId) {
    
    this.menuService.getSiteProductTypes(siteId).subscribe((res) => {
      if (res) {
        _.forEach(res['productFamily'], (product) => {
          product['cssClass'] = product.intakeDisplayName.replace(/\s+/g, '-').toLowerCase();
        })
        this.intakeProduct = res['productFamily'];
        
      }
    },
      err => {
        throw err;
      });
  }

  logIntoWP(): any {
    if(!this.isMigrated){
      window.open('http://admin.audiologydesign.com/', "_blank");
      return;
    }
    if(!this.wpSitePath){
      this.httpService.openErrorPopup('Site is not configure');
      return;
    }
    //this.renderer.appendChild(this.el.nativeElement, div);
    this.wpSSOUrl = this.wpSSOUrl.replace('sitePath', this.wpSitePath);
    const wpForm = this.renderer.createElement('form');
    this.renderer.setAttribute(wpForm, 'id', 'wpForm');
    this.renderer.setAttribute(wpForm, 'action', this.wpSSOUrl);
    this.renderer.setAttribute(wpForm, 'target', "_blank");
    this.renderer.setAttribute(wpForm, 'method', "post");

    const inputEmail = this.renderer.createElement('input');
    this.renderer.setAttribute(inputEmail, 'type', 'text');
    this.renderer.setAttribute(inputEmail, 'name', 'email');
    this.renderer.setAttribute(inputEmail, 'value', this.userService.UserProfile.email);
    const inputToken = this.renderer.createElement('input');
    this.renderer.setAttribute(inputToken, 'type', 'text');
    this.renderer.setAttribute(inputToken, 'name', 'token');
    this.renderer.setAttribute(inputToken, 'value', this.userInfo.token);
    this.renderer.appendChild(wpForm, inputEmail);
    this.renderer.appendChild(wpForm, inputToken);
    this.renderer.setStyle(
      wpForm,
      'visibility',
      'hidden'
    );
    this.renderer.appendChild(this.el.nativeElement, wpForm);
    wpForm.submit();
    document.getElementById("wpForm").remove();
  }

  /**
   * Retrieve the information from the site configuration
   *
   * @param siteId
   */
   getSiteInfo(siteId: number) {
    this.siteService.getSiteInfo(siteId).subscribe((res) => {
      if (res) {
        this.siteInfo = res;
        this.isLive = res['isLive'];
        this.wpSitePath = res['wpSitePath'];
        this.emailPrvoiderPath = res.oSiteSetting.emailProvider;
        this.isMigrated = res.isMigrated;
        
        this.getIntakeProductList(this.selectedSite['siteId']);
      }
    },
      err => {
      });
  }

  gotoReports(productId) {
    if(productId !== 11 && productId !== 12 && productId !== 8 && productId !== 14 ){
      this.userService.selectedProductId = productId;
      this.router.navigate(['client/client-reports']);
    }
  }
  manage(intakeTypeId){
    let params={familyId:intakeTypeId}
    switch(intakeTypeId){
      case 1://Basic
      case 2://Enhanced
      case 3://Premium
      case 4://Redesign
      this.logIntoWP()
      break;
      case 5://PPC
      this.router.navigate([`client/site-config/${this.selectedSite.siteId}`],{ queryParams: params });
      break;
      case 6://Facebook Ads
      this.router.navigate([`client/site-config/${this.selectedSite.siteId}`],{ queryParams: params });
      break;
      case 7://Social Media Marketing
      this.router.navigate([`client/site-config/${this.selectedSite.siteId}`],{ queryParams: params });
      break;
      case 8://Social Media Setup
      this.router.navigate([`client/site-config/${this.selectedSite.siteId}`],{ queryParams: params });
      break;
      case 9://ReviewGen
      this.getSSSOUrl();
      break;
      case 10://Call Tracking
      this.router.navigate([`client/site-config/${this.selectedSite.siteId}`],{ queryParams: params });
      break;
      case 11://Email
        this.getEmailPrvoide();
      break;
      case 12://Domain
      break;
      case 13://Citation Clean Up
      break;
      case 14://Logo
      break;

    }
  }
  logIntoReviewGen(){

    //this.siteService.getReviewGen()
  }
  getSSSOUrl(){
    this.siteService.getReviewSSOUrl(this.selectedSite.siteId).subscribe(res=>{
      if(res){
          if(res.url)
            window.open(res.url, "_blank");
          else
            this.httpService.openErrorPopup('ReviewGen Setup Missing');
      }
    },err=>{
      throw err;
    })
  }
  getEmailPrvoide(){
        if(this.emailPrvoiderPath)
            window.open(this.emailPrvoiderPath, "_blank");
          else
          this.httpService.openErrorPopup('Email Setup Missing');
  }


  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = 0;
    model.componentInstance.attachmentFor = 'JIRA';
    model.componentInstance.baseUrl = environment.jiraImagUrl;
    model.componentInstance.selectedFile = this.photographs;
    model.componentInstance.field = fieldName;
    model.componentInstance.isAssets = true;
    model.componentInstance.isMultipleFiles = true;
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if (this.photographs.length) {
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if (!n) {
            this.photographs.push(file);
          }
        })
        this.fileName = this.photographs.join(',');
      }
    }, (reason) => {
      
    });

  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.title = 'Attachments';
    model.componentInstance.baseUrl = environment.apiUrl;
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('\\')[1]})
    });
    model.componentInstance.deleteAssets = (type?) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });
      this.fileUploadService.uploadedFiles['files'] = model.componentInstance.assets;

      this.photographs = [];
      // this.homepagePhotographyForm.controls.photography.setValue('');
      this.fileName = '';
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.fileName = this.photographs.join(',');
      })
      if (type != 'delete' || !this.photographs.length) {
        model.close();
      }
    }
  }

  resetTickets(ticketType){
    // this.filterBy = {};
    this.subject = '';
    this.getTickets(ticketType);
  }

  applySearch(filterBy){
    if(filterBy && filterBy.length >= 3){
      this.getTickets(this.ticketType);
    }
  }

}
