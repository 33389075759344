import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {MasterService} from '../../components/admin/masters/services/master.service';

@Component({
  selector: 'app-cancel-reason',
  templateUrl: './cancel-reason.component.html',
  styleUrls: ['./cancel-reason.component.css']
})
export class CancelReasonComponent implements OnInit {
  reason;
  reasonId;
  message;
  errorMsg;
  isViewMode;
  reasons;
  @Input() productName: any;
  @Input() product: any;


  constructor(
    public activeModal: NgbActiveModal,
    private masterService:MasterService
  ) { }

  ngOnInit() {
     // To check if data is passed correctly

    if(!this.isViewMode)
      this.getCancelReason();
  }

  dismissModal() {
    this.activeModal.close();
  }

  submit(){
    let cancelationBody={
      cancelationNoteId:this.reasonId,
      cancelationNote:this.reason
    };
    this.activeModal.close(cancelationBody);
  }
  
  getCancelReason(){
    this.masterService.getMastersListById(47).subscribe(res=>{
      if(res){
        this.reasons=res['masterList'];
      }
    },
    err=>{
      
    })
  }

}
