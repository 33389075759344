import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { DataService } from '../../../../../../../shared/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../../../../services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentTreeNodeComponent } from '@shared/content-tree-node/content-tree-node.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-practice-info',
  templateUrl: './practice-info.component.html',
  styleUrls: ['./practice-info.component.css']
})
export class PracticeInfoComponent implements OnInit {

  @Input() selectedItem;
  @Input() contentMenu;
  @Input() intakeTypeId;
  @Input() siteId;
  searchKeyword;
  selectedContentMenu;
  message: boolean = false;
  @Output() gotoPageSection = new EventEmitter<object>();
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>(); 
  @Output() changeStatus = new EventEmitter<any>();
  @ViewChild(ContentTreeNodeComponent)
  public contentTreeNodeComponent: ContentTreeNodeComponent;
  intakeId;
  savedPages = [];
  filteredData = [];
  allPracticeInfoData = [];
  searchTerm: string = '';
  seoDescription: string = '';
  title: string = '';
  checkSavedPages: boolean = false;

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    public modalService: NgbModal,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    this.selectedContentMenu = _.find(this.contentMenu, (menu) => menu.name === this.selectedItem.name);
    
    this.getIntakeSavedPages(this.intakeId, this.selectedItem.id || 11);
  }

  gotoPages() {
    this.gotoPageSection.emit({ "menuId": 2, "sectionId": 11, "sectionName": this.selectedContentMenu.name });
  }

  getIntakeSavedPages(intakeId, pageSectionId) {
    let intakeID = intakeId ? intakeId : this.intakeId;
    let pageSectionID = pageSectionId ? pageSectionId : this.selectedItem.id || 11;
    

    this.intakesService.getIntakeSavedPages(intakeID, pageSectionID).subscribe((res: any) => {
      this.savedPages = [];
      if (res) {
         // Log the complete API response
        this.savedPages = res;
        if (res.length) {
          this.seoDescription = res[0].seoDescription;
          this.title = res[0].title;
          this.checkSavedPages = false;
        } else {
          this.checkSavedPages = true;
          this.title = this.selectedContentMenu.name;
        }
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
        this.allPracticeInfoData = this.flattenData(this.savedPages);
         // Log to verify complete data
      }
    },
    err => {
      
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  flattenData(data) {
    let result = [];
    function flatten(items) {
      items.forEach(item => {
        result.push(item);
        if (item.children && item.children.length) {
          flatten(item.children);
        }
      });
    }
    flatten(data);
    return result;
  }

  editPage() {
    this.messageEvent.emit(this.message);
  }

  expandAll() {
    this.contentTreeNodeComponent.expandAll();
  }

  collapseAll() {
    this.contentTreeNodeComponent.collapseAll();
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.updateSectionStatus();
    this.continueClicked.next();
  }

  updateSectionStatus() {
    let sectionStatusData = {
      intakeId: this.intakeId,
      lstIntakeSection: [{
        productSectionId: this.selectedContentMenu.id,
        status: 2,
        percentage: this.selectedContentMenu.percentage
      }],
      IsContentSection: true
    }
    
    this.postIntakeSection(sectionStatusData);
  }

  getProductSectionData(familyId, intakeId) {
    
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage, isSection: true });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    });
  }

  postIntakeSection(sectionStatus) {
    
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
    err => {
      
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  applyFilter(name) {
    this.searchTerm = name;
    
    this.filteredData = _.cloneDeep(this.allPracticeInfoData);
    if (!_.isEmpty(name)) {
      
      this.filteredData = _.filter(this.filteredData, (data) => {
        const matches = _.includes(data['name'].toLowerCase(), name.toLowerCase());
        
        return matches;
      });
      this.savedPages = this.filteredData;
      
    }
  }
}
