import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { IntakesService } from '../../../../services/intakes.service';
import { DataService } from '../../../../../../../shared/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { LocatoinComponent } from '../locatoin/locatoin.component';
import { StaffBiosComponent } from '../staff-bios/staff-bios.component';
import { AssetsComponent } from '../assets/assets.component';

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.css']
})
export class ContentEditorComponent implements OnInit {
  public Editor = ClassicEditor;

  @ViewChild(LocatoinComponent) locatoinComponent: LocatoinComponent;
  @ViewChild(StaffBiosComponent) staffBiosComponent: StaffBiosComponent;

  @Input() intakeTypeId: any;
  @Input() selectedItem: any;
  @Input() contentMenu: any;
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() changeStatus = new EventEmitter<any>();

  title: string = '';
  intakeId: number = null;
  selectedSiteId: any;
  pages: any;
  files: any;
  message: boolean = true;

  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private dataService: DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.selectedSiteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.dataService.currentData.subscribe(data => {
      if (data) {
        this.pages = data;
        if (this.pages.asset) {
          this.files = this.pages.asset.split(",");
        }
        if (data['itemId'] == 0) {
          this.title = data['title'];
        } else {
          this.title = data['pageName'];
        }
      }
    });
  }

  back() {
    this.messageEvent.emit(this.message);
  }

  updatePage(mode?) {
    this.pages['intakeId'] = this.intakeId;
    this.pages['itemId'] = this.pages.id;
    this.pages['productSectionId'] = this.selectedItem.id;
    this.pages['isComplete'] = true;

    if (this.pages.pageName === 'Locations') {
      this.locatoinComponent.saveLocation(this.locatoinComponent.locationForm);
      if (this.locatoinComponent.locationForm.valid) {
        this.updateIntakePageContent(this.pages, mode);
      }
    } else if (this.pages.pageName === 'Staff') {
      this.staffBiosComponent.saveStaff(this.staffBiosComponent.staffForm);
      if (this.staffBiosComponent.staffForm.valid) {
        this.updateIntakePageContent(this.pages, mode);
      }
    } else {
      this.updateIntakePageContent(this.pages, mode);
    }
  }

  updateIntakePageContent(pages, mode?) {
    this.intakesService.updateIntakePageContent(pages).subscribe((res: any) => {
      if (res) {
        this.changeStatus.emit();
        this.toastrService.success('Updated Successfully', 'Success');
        if (mode === 'continue') {
          this.messageEvent.emit(this.message);
        }
      }
    }, err => {
      this.toastrService.error(err.error.message, 'Error');
    });
  }

  openUploadedFilesPopup(field) {
    const modalRef = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.siteId = this.selectedSiteId;
    modalRef.componentInstance.imageFor = this.title;
    modalRef.componentInstance.imageForId = field.itemId;
    modalRef.componentInstance.isAssets = true;
    modalRef.componentInstance.isMultipleFiles = true;

    modalRef.result.then((result) => {
      if (result) {
        const files = result.map(file => file.name);
        if (field.asset) {
          field.asset += `,${files.join(',')}`;
        } else {
          field.asset = files.join(',');
        }
        this.files = field.asset.split(',');
      }
    }, (reason) => {
      
    });
  }

  openAssetsPopup(field) {
    const modalRef = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.assets = field.asset ? field.asset.split(',').map(file => ({ path: file, name: file.split('/').pop() })) : [];

    modalRef.componentInstance.deleteAssets = () => {
      modalRef.componentInstance.assets = modalRef.componentInstance.assets.filter(asset => !asset.isFileSelected);
      field.asset = modalRef.componentInstance.assets.map(asset => asset.path).join(',');
      this.files = field.asset.split(',');
      modalRef.close();
    };
  }
}
