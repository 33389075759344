import { Component, OnInit } from '@angular/core';
import { ManageCustomerService } from '../services/manage-customer.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  userProfile;
  baseUrl = environment.imgUrl;
  constructor(
    private manageCustomerService: ManageCustomerService,
    private activatedRoute: ActivatedRoute
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.getUserInfo(params.id);
      }
    });
  }

  ngOnInit() {
  }

  getUserInfo(id){
    this.manageCustomerService.getUserProfile(id).subscribe((res)=>{
      if(res){
        this.userProfile = res;
        
      }
    },
    err=>{
      throw err;
    })
  }

}
