import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../service/site.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { Observable, from } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { AddTrialComponent } from '../add-trial/add-trial.component';

@Component({
  selector: 'app-start-billing',
  templateUrl: './start-billing.component.html',
  styleUrls: ['./start-billing.component.css']
})
export class StartBillingComponent implements OnInit {

  @Input() selectedSiteId;
  siteIsLock;
  intakeProductList;
  isCouponTypeAmount: boolean = true;
  isCouponApply: boolean = false;
  durationType: string = 'forever';
  monthlyDis;
  constructor(
    private siteService: SiteService,
    private httpService: HttpService,
    public modalService: NgbModal,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.siteIsLock=this.siteService.siteIsLock;
    this.getProductBySite();
  }
  getProductBySite() {
    let obj = {
      siteId: this.selectedSiteId,
      isCouponApply: this.isCouponApply,
      isCouponTypeAmount: this.isCouponTypeAmount
    }
    this.siteService.getProductBySite(obj).subscribe((res) => {
      if (res) {
        this.intakeProductList = res;
        this.isCouponTypeAmount = res.isCouponTypeAmount;
        this.monthlyDis = res.products[0].discount;
        
      }
    },
      err => {
        throw err;
      })
  };

  getDiscount() {
    this.isCouponApply = true;
    this.getProductBySite();

  };

  callFromParent(site) {
    this.selectedSiteId = site.id;
    this.siteIsLock = site.isLock;  
    this.isCouponApply = false;
    this.isCouponTypeAmount = true;
    this.getProductBySite();
    
  };

  startBilling(product, type) {
    // let subscribedProduct;
    let selectedPaymentType = _.find(this.intakeProductList.products, (product) => {
      return product.paymentType == type;
    })
    let subscribedProduct = _.find(selectedPaymentType.products, (item) => {
      return item.stripeSubscriptionStatus == 'Subscribed'
    })
    
    let body = {
      SiteId: this.selectedSiteId,
      IntakeId: 0,
      IntakeProductIds_ToBeSubscribed: product.productId,
      IsCouponType_Amount: this.isCouponTypeAmount,
      CouponDuration: this.durationType
    };
    if (subscribedProduct) {
      this.postStartBillingData(body);
    } else {
      var model = this.modalService.open(AddTrialComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      model.result.then((days) => {
        if (days >= 0) {
          body['TrialDays'] = days;
          this.postStartBillingData(body);
        }
      }, (reason) => {
        
      });
    }
  };

  postStartBillingData(body) {
    this.siteService.startBilling(body).subscribe((res) => {
      if (res) {
        this.toastrService.success('Billing Started', 'Success');
        this.isCouponTypeAmount = true;
        this.isCouponApply = false;
        this.getProductBySite();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

}
