import { Component, OnInit,Input,Output,EventEmitter,OnChanges } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { AddGoogleGaGscComponent } from './add-google-ga-gsc/add-google-ga-gsc.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-google-ga-gsc',
  templateUrl: './google-ga-gsc.component.html',
  styleUrls: ['./google-ga-gsc.component.css'],
})
export class GoogleGaGscComponent implements OnInit, OnChanges, PageHasTable {
  @Input() siteInfo;
  @Output() updateSite = new EventEmitter<any>();
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  googleForm: UntypedFormGroup;
  hasPermission = hasPermission;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  siteId;
  familyId;
  SEMrushLocation;
  adminAccounts;

  constructor(
    private siteService: SiteService,
    private formBuilder: UntypedFormBuilder,
    private toastrService:ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.siteId = params.siteId;
      this.familyId = params.familyId;
      if (params.siteId) {
        this.getSEMrushLocation(params.siteId);
      }
    });

    this.getAdminAccount();

    this.getTableData();
  }

  ngOnChanges(){
    this.initializeForm();
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      { field: 'locationId', header: 'Location ID' },
      { field: 'locationName', header: 'Location Name' },
    ];
  }

  initializeForm(){
    this.googleForm=this.formBuilder.group({
      siteId:[this.siteInfo.id?this.siteInfo.id:0,[Validators.required]],
      gaViewId:[this.siteInfo.oSiteSetting.gaViewId?this.siteInfo.oSiteSetting.gaViewId:''],
      gaPropertyId:[this.siteInfo.oSiteSetting.gaPropertyId?this.siteInfo.oSiteSetting.gaPropertyId:''],
      seMrushProjectId:[this.siteInfo.oSiteSetting.seMrushProjectId?this.siteInfo.oSiteSetting.seMrushProjectId:''],
      seMrushDomain:[this.siteInfo.oSiteSetting.seMrushDomain?this.siteInfo.oSiteSetting.seMrushDomain:''],
      gscDomain:[this.siteInfo.oSiteSetting.gscDomain?this.siteInfo.oSiteSetting.gscDomain:''],
      gaAdminAccountId:[this.siteInfo.oSiteSetting.gaAdminAccountId?this.siteInfo.oSiteSetting.gaAdminAccountId:null],
      gscAdminAccountId:[this.siteInfo.oSiteSetting.gscAdminAccountId?this.siteInfo.oSiteSetting.gscAdminAccountId:null]
    });
  }

  setUp(form) {
    if (form.valid) {
      this.siteService.siteSetup(form.value).subscribe(res=>{
        if(res){
          this.toastrService.success('SEO Premium Setup Done','success');
          this.updateSite.next(this.siteInfo.id);
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
      }

    }

    getSEMrushLocation(siteId){
      this.siteService.getSEMrushLocation(siteId).subscribe(res=>{
        if(res){
          this.SEMrushLocation=res;
          
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
    }
    openAddPopup() {
      var model = this.modalService.open(AddGoogleGaGscComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      model.componentInstance.siteId = this.siteId;
      model.componentInstance.msg='Added Successfully';
      model.result.then((result) => {
        this.getSEMrushLocation(this.siteId);
        
      }, (reason) => {
        
      });

    }
    goToEdit(location) {
      var model = this.modalService.open(AddGoogleGaGscComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      model.componentInstance.siteId = this.siteId;
      model.componentInstance.existingData = location;
      model.componentInstance.msg='Updatd Successfully';
      model.result.then((result) => {
        setTimeout(() => {
          this.getSEMrushLocation(this.siteId);
        }, 50);
        
      }, (reason) => {
        
      });

    }

    delete(location){
      var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      model.componentInstance.message = 'Are you sure you want to delete?';
      model.result.then((result) => {
        if(result){
        this.siteService.deleteSEMrush(location.id).subscribe(res=>{
          if(res){
            this.getSEMrushLocation(this.siteId);
            this.toastrService.success('Delete successfully', 'success');
          }
        },err=>{
          this.toastrService.error(err, 'Error');
        })
      }
      }, (reason) => {
        
      });
    }
    getAdminAccount(){
      this.siteService.getGeneralMaster(12).subscribe(res=>{
        if(res){
            this.adminAccounts=res;
            this.initializeForm();
        }
      },
      err=>{
        throw err;
      })
    }

}
