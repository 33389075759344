import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { SectionStatus } from '../basic/review/section-model';
import { HttpService } from '../../../../../services/http.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  logoForm: UntypedFormGroup;
  @Input() submenu;
  @Input() intakeTypeId;
  @Input() menuId;
  @Output() changeStatus = new EventEmitter<any>();
  intakeId: number = null;
  siteId: number = null;
  currentImageSrc;
  logoLists = [
    { id: 1, name: 'Repair' }, { id: 2, name: 'Basic' }, { id: 3, name: 'Custom' }
  ]
  baseUrl = environment.imgUrl;
  existingData;
  iconLists;
  fontLists;
  selectedIcon = [];
  selectedFont = [];
  selectedColor = {};
  selectedColorList = [];
  colorPickerToggle;
  @Output() continue = new EventEmitter<any>();
  disableColorPicker = false;
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public modalService: NgbModal,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.getIntakeLogo(this.intakeId);
    //this.getIcon();
    // this.getFont();
    this.initializeForm();
  }

  initializeForm() {
    this.logoForm = this.formBuilder.group({
      intakeId: this.intakeId,
      logoType: new UntypedFormControl(this.existingData && this.existingData.logoType !=0 ? this.existingData.logoType.toString() : ''),
      businessName: new UntypedFormControl(this.existingData ? this.existingData.businessName : ''),
      icon: new UntypedFormControl(this.existingData ? this.existingData.icon : ''),
      font: new UntypedFormControl(this.existingData ? this.existingData.font : ''),
      colorPalette: new UntypedFormControl(this.existingData ? this.existingData.colorPalette : ''),
      existingLogo: new UntypedFormControl(this.existingData && this.existingData.isExistingLogo ? this.existingData.isExistingLogo : null),
      description: new UntypedFormControl(this.existingData ? this.existingData.description : ''),
      dislikes: new UntypedFormControl(this.existingData ? this.existingData.dislikes : ''),
      likes: new UntypedFormControl(this.existingData ? this.existingData.likes : ''),
      associatedImage: new UntypedFormControl(this.existingData ? this.existingData.associatedImage : ''),
      colorPreference: new UntypedFormControl(this.existingData ? this.existingData.colorPreference : ''),
      sampleLogo: new UntypedFormControl(this.existingData ? this.existingData.sampleLogo : ''),
      additionalNotes: new UntypedFormControl(this.existingData ? this.existingData.additionalNotes : ''),
      currentLogo: this.currentImageSrc
    })
  }

  getRequiredFields(logoType) {
    this.intakesService.requiredFields = _.filter(this.intakesService.requiredFields, (item) => {
      return item.sectionId !== 82
    })
    if (logoType == 1) {
      this.intakesService.requiredFields.push(
        { key: 'currentLogo', errorMsg: 'This is Required Field', sectionId: 82, status: false }
      )
    } else if (logoType == 2) {
      this.intakesService.requiredFields.push(
        { key: 'businessName', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'icon', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'font', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'colorPalette', errorMsg: 'This is Required Field', sectionId: 82, status: false }
      )
    } else {
      this.intakesService.requiredFields.push(
        { key: 'additionalNotes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'colorPreference', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'associatedImage', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'likes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'dislikes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'description', errorMsg: 'This is Required Field', sectionId: 82, status: false }
      )
      this.existingLogo();
    }
    this.getRequiredFieldData();
  }

  getRequiredFieldData() {
    let requiredFields = _.filter(this.intakesService.requiredFields, (item) => {
      return item.sectionId == 82;
    })
    _.forEach(this.submenu, (menu) => {
      menu.requiredFields = _.filter(requiredFields, (field) => {
        field.percentage = menu.percentage / requiredFields.length;
        return field.sectionId == menu.id;
      })
    })
  }

  selectLogo(value) {
    if (value == 1) {
      this.logoForm.controls.currentLogo.setValue(this.currentImageSrc);
      this.logoForm.controls.icon.setValue('');
      this.logoForm.controls.font.setValue('');
      this.logoForm.controls.colorPalette.setValue('');
      this.logoForm.controls.businessName.setValue('');
      this.logoForm.controls.description.setValue('');
      this.logoForm.controls.dislikes.setValue('');
      this.logoForm.controls.likes.setValue('');
      this.logoForm.controls.associatedImage.setValue('');
      this.logoForm.controls.additionalNotes.setValue('');
      this.logoForm.controls.colorPreference.setValue('');
      this.logoForm.controls.sampleLogo.setValue('');
      this.selectedIcon = [];
      this.selectedFont = [];
      this.selectedColorList = [];
      this.getRequiredFields(value);
    } else if (value == 2) {
      this.logoForm.controls.currentLogo.setValue(null);
      this.logoForm.controls.businessName.setValue('');
      this.logoForm.controls.description.setValue('');
      this.logoForm.controls.dislikes.setValue('');
      this.logoForm.controls.likes.setValue('');
      this.logoForm.controls.associatedImage.setValue('');
      this.logoForm.controls.additionalNotes.setValue('');
      this.logoForm.controls.colorPreference.setValue('');
      this.logoForm.controls.sampleLogo.setValue('');
      this.currentImageSrc = '';
      this.getRequiredFields(value);

    } else {
      this.logoForm.controls.currentLogo.setValue(this.currentImageSrc);
      this.logoForm.controls.icon.setValue('');
      this.logoForm.controls.font.setValue('');
      this.logoForm.controls.colorPalette.setValue('');
      this.selectedIcon = [];
      this.selectedFont = [];
      this.selectedColorList = [];
      this.getRequiredFields(value);
    }
  }
  existingLogo() {
    this.intakesService.requiredFields = _.filter(this.intakesService.requiredFields, (item) => {
      return item.sectionId !== 82
    })
    if (!this.logoForm.controls.existingLogo.value) {      
      this.logoForm.controls.description.setValue('');
      this.logoForm.controls.dislikes.setValue('');
      this.logoForm.controls.likes.setValue('');
      this.intakesService.requiredFields.push(
        { key: 'additionalNotes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'colorPreference', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'associatedImage', errorMsg: 'This is Required Field', sectionId: 82, status: false },
      )
    } else {
      this.intakesService.requiredFields.push(
        { key: 'additionalNotes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'colorPreference', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'associatedImage', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'likes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'dislikes', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'description', errorMsg: 'This is Required Field', sectionId: 82, status: false },
        { key: 'currentLogo', errorMsg: 'This is Required Field', sectionId: 82, status: false }
      )
    }
    this.getRequiredFieldData();
  }

  getIntakeLogo(intakeId) {
    this.intakesService.getIntakeLogo(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.initializeForm();
        this.currentImageSrc = this.existingData.currentLogo;
        
        this.logoForm.controls.existingLogo.setValue(this.existingData.isExistingLogo);
        this.getIcon();
        this.getFont();
        this.splitColor(this.existingData.colorPalette);
        this.getRequiredFields(this.existingData.logoType);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  isFieldValid(field: string,index:number =0) {
      return !this.logoForm.get(field).valid && this.logoForm.get(field).touched;
   }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }

    });
  }
  saveFormData(form, mode?) {
    // this.existingLogo();

    if(this.logoForm.valid){
      
    form.value.colorPalette = _.toString(_.map(this.selectedColorList, 'value'));
    form.value.isExistingLogo = form.value.existingLogo;
    let logoFormData = form.value;
    this.intakesService.saveIntakeLogo(logoFormData).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success('Saved Successfully', 'Success');
        this.intakesService.updateRequiredFieldsStatus(form.value);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if (mode == 'continue') {
          this.goToNext('parent');
        }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }else{
      
      this.validateAllFormFields(this.logoForm);
      this.httpService.openErrorPopup("Please select logo");
    }

    
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        // this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.httpService.openErrorPopup("Site doesn't exist");
      }
    })
  }
  getIcon() {
    this.intakesService.getIcon().subscribe((res) => {
      if (res) {
        this.iconLists = res['masterList'];
        this.iconLists = this.iconLists.map(function (el) {
          let o = Object.assign({}, el);
          o.isActive = false;
          return o;
        })

        if (this.existingData.icon) {
          _.forEach(this.existingData.icon.split(','), (data) => {
            for (let i = 0; i < this.iconLists.length; i++) {
              if (this.iconLists[i].id.toString() === data) {
                this.iconLists[i].isActive = true;
                this.selectedIcon.push(this.iconLists[i].id);
              }
            }
          });
        }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getFont() {
    this.intakesService.getFont().subscribe((res) => {
      if (res) {
        this.fontLists = res['masterList'];
        this.fontLists = this.fontLists.map(function (el) {
          let o = Object.assign({}, el);
          o.isActive = false;
          return o;
        })

        if (this.existingData.font) {
          _.forEach(this.existingData.font.split(','), (data) => {
            for (let i = 0; i < this.fontLists.length; i++) {
              if (this.fontLists[i].id.toString() === data) {
                this.fontLists[i].isActive = true;
                this.selectedFont.push(this.fontLists[i].id);
              }
            }
          });
        }

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  selectIcon(icon, event) {
    if (event.target.checked) {
      this.selectedIcon.push(icon.id);
    } else {
      for (var i = 0; i < this.selectedIcon.length; i++) {
        if(this.selectedIcon[i]==icon.id)
        this.selectedIcon.splice(i, 1);
      }
    }
    this.logoForm.controls.icon.setValue(this.selectedIcon.join(","));
  }
  selectFont(font, event) {
    if (event.target.checked) {
      this.selectedFont.push(font.id);
    } else {
      for (var i = 0; i < this.selectedFont.length; i++) {
        if(this.selectedFont[i]==font.id)
        this.selectedFont.splice(i, 1);
      }
    }
    this.logoForm.controls.font.setValue(this.selectedFont.join(","));
  }
  openUploadedFilesPopup() {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.result.then((result) => {
      if (result) {

        this.currentImageSrc = this.baseUrl + result[0];
        this.logoForm.controls.currentLogo.setValue(this.currentImageSrc);

      }
    }, (reason) => {
      
    });
  }

  addNewColor(selectedColor) {
    let editColor = _.find(this.selectedColorList, (item) => {
      return item.value == this.selectedColor['value'];
    })
    if (editColor) {
      editColor.value = selectedColor;
    } else {
      let colorObj = {
        index: this.selectedColorList.length,
        value: selectedColor
      }
      this.selectedColorList.push(colorObj);
    }
    this.selectedColor = {};
    this.disableColorPicker = this.selectedColorList.length >= 3 ? true : false;
    this.selectedColor = this.selectedColorList.length >= 3 ? {} : this.selectedColor;
  }

  splitColor(value) {
    if (value) {
      this.selectedColorList = [];
      let splitString = value.split(',');
      _.forEach(splitString, (element, i) => {
        this.selectedColorList.push({
          index: i,
          value: element
        })
      })
      this.disableColorPicker = this.selectedColorList.length >= 3 ? true : false;
      this.selectedColor = this.selectedColorList.length >= 3 ? {} : this.selectedColor;
    }

  }

  editColor(color) {
    this.selectedColor = color;
    this.colorPickerToggle = !this.colorPickerToggle;
    this.disableColorPicker = false;
  }

  deleteColor(color) {
    this.selectedColorList = _.filter(this.selectedColorList, (colorObj) => {
      return colorObj.value != color.value;
    })

    this.disableColorPicker = this.selectedColorList.length >= 3 ? true : false;
  }

  goToNext(menu) {
    this.continue.next(menu);
  }

}
