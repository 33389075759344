import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { UntypedFormBuilder, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { AddLocalListingsComponent} from './add-gmb/add-gmb.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-local-listings',
  templateUrl: './local-listings.component.html',
  styleUrls: ['./local-listings.component.css'],
})
export class LocalListingsComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  hasPermissions = hasPermission;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  siteId;
  familyId;
  SEMrushLocation;

  constructor(
    private siteService: SiteService,
    private formBuilder: UntypedFormBuilder,
    private toastrService:ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public modalService: NgbModal,) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.siteId = params.siteId;
      this.familyId = params.familyId;
      if (params.siteId) {
        this.getSEMrushLocation(params.siteId);
      }
    });

    this.getTableData();
  }

  getSEMrushLocation(siteId){
      this.siteService.getGMBLocation(siteId).subscribe(res=>{
        if(res){
          this.SEMrushLocation=res;
          
        }
      },
      err=>{
        this.toastrService.error(err.error);
      })
    }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      { field: 'gmbAdminAccount', header: 'Location ID' },
      { field: 'locationId', header: 'Location ID' },
      { field: 'locationName', header: 'Location Name' },
    ];
  }

  openAddPopup() {
    var model = this.modalService.open(AddLocalListingsComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.msg='Added Successfully';
    model.result.then((result) => {
      this.getSEMrushLocation(this.siteId);
      
    }, (reason) => {
      
    });

  }
  goToEdit(location) {
    var model = this.modalService.open(AddLocalListingsComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.existingData = location;
    model.componentInstance.msg='Updatd Successfully';
    model.result.then((result) => {
      setTimeout(() => {
        this.getSEMrushLocation(this.siteId);
      }, 50);
      
    }, (reason) => {
      
    });

  }

  delete(location){
    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = 'Are you sure you want to delete?';
    model.result.then((result) => {
      if(result){
      this.siteService.deleteGMB(location.id).subscribe(res=>{
        if(res){
          this.getSEMrushLocation(this.siteId);
          this.toastrService.success('Delete successfully', 'success');
        }
      },err=>{
        this.toastrService.error(err, 'Error');
      })
    }
    }, (reason) => {
      
    });
  }
}
