import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@shared/data-service.service';
import { IntakesService } from '../../../app/components/customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../services/http.service';
import { SectionStatus } from '../../components/customer/intakes/add-intakes-data/basic/review/section-model';
import * as _ from 'lodash';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PageSampleComponent } from '../../components/customer/intakes/add-intakes-data/basic/pages/page-sample/page-sample.component';
import { Title } from '@angular/platform-browser';
import { Button } from 'protractor';
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  providers: [NgbAccordionConfig]
})
export class AccordionComponent implements OnInit {
  activeIds: string[] = ["ngb-panel-0"];
  @Input('data') items: Array<Object>;
  @Input('key') key: string;
  @Input() intakeTypeId;
  intakeId: number = null;
  ProductSectionId: number = null;
  checkedPageList: any[] = [];
  @Output() continueClicked = new EventEmitter<any>();
  localData: Array<Object>;
  checkedPage = {
    "ItemId": null,
    "Type": '',
    "ParentId": null,
    "Name": '',
    "notSubscribed":''
  }
  selectedPageList = {
    "ProductSectionId": this.ProductSectionId,
    "IntakeId": this.intakeId,
    "lstIntakePages": []
  };

  constructor(
    config: NgbAccordionConfig,
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    public modalService: NgbModal,
    private httpService: HttpService,
    private dataService: DataService,
    private renderer2: Renderer2
  ) {
    config.closeOthers = true;
    //config.type = 'info';

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
  }

  ngOnInit() {
    if (this.items && this.items.length) {
      this.getSavedPages(this.items); 
    }
    try {
      setTimeout(() => { this.expandAll(false); }, 1000);
    }
    catch (e) {

    }
   
  };

  getSavedPages(obj) {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] == "object") {
          if (obj[property]['isSavedInDB']) {
            this.checkedPage = {
              "ItemId": obj[property]['id'],
              "Type": obj[property]['type'],
              "ParentId": obj[property]['parent'],
              "Name": obj[property]['name'],
              "notSubscribed":obj[property]['notSubscribed']
            };
            this.intakesService.accordionCheckedPageList.push(this.checkedPage);
          }
          if (obj[property]['children']) {//recursive function calling
            this.getSavedPages(obj[property]['children']);
          }

        } else {
          //we can get all the property(keys)
        }
      }
    }
  };

  selectAll(item, event) {
    event.stopPropagation();
    this.intakesService.accordionCheckedPageList = this.getUnique(this.intakesService.accordionCheckedPageList, 'ItemId');
    let pageData = [];
    pageData.push(item);
    this.iterate(pageData, event);
  };
  stopParentEvt(event) {
    event.stopPropagation();
  }
  iterate(obj, event) {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] == "object") {
          if (event.target.checked) {
            obj[property]['isSavedInDB'] = true;
            this.checkedPage = {
              "ItemId": obj[property]['id'],
              "Type": obj[property]['type'],
              "ParentId": obj[property]['parent'],
              "Name": obj[property]['name'],
              "notSubscribed":obj[property]['notSubscribed']
            };
            this.intakesService.accordionCheckedPageList.push(this.checkedPage);
            //remove duplicate data from the array
            this.intakesService.accordionCheckedPageList = this.getUnique(this.intakesService.accordionCheckedPageList, 'ItemId');
            

          } else {
            obj[property]['isSavedInDB'] = false;
            for (var i = 0; i < this.intakesService.accordionCheckedPageList.length; i++) {
              if (this.intakesService.accordionCheckedPageList[i].ItemId === obj[property]['id']) {
                this.intakesService.accordionCheckedPageList.splice(i, 1);
              }
            }
            //remove duplicate data from the array
            this.intakesService.accordionCheckedPageList = this.getUnique(this.intakesService.accordionCheckedPageList, 'ItemId');
            

          }
          if (obj[property]['children']) {
            this.iterate(obj[property]['children'], event);
          }
        } else {
        }
      }
    }
  };

  onCheckboxChange(option, event) {
    this.intakesService.accordionCheckedPageList = this.getUnique(this.intakesService.accordionCheckedPageList, 'ItemId');
    this.checkedPage = {
      "ItemId": option.id,
      "Type": option.type,
      "ParentId": option.parent,
      "Name": option.name,
      "notSubscribed":option.notSubscribed
    };
    if (event.target.checked) {
      this.intakesService.accordionCheckedPageList.push(this.checkedPage);
    } else {
      for (var i = 0; i < this.intakesService.accordionCheckedPageList.length; i++) {
        if (this.intakesService.accordionCheckedPageList[i].ItemId === option.id) {
          this.intakesService.accordionCheckedPageList.splice(i, 1);

        }
      }
    }
    
  };

  getHomePageMenu(selectedItem) {
    
    let homePageMenu;
    _.forEach(this.intakesService.basicMenu, (menu) => {
      if (menu.id == selectedItem.parent) {
        homePageMenu = _.find(menu.submenu, (sMenu) => {
          return sMenu.name == 'Homepage';
        })
      }
    })
    return homePageMenu;
  }

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }
  saveFormData(selectedItem, mode?) {
    let homepageMenu = this.getHomePageMenu(selectedItem);
    this.ProductSectionId = selectedItem.id;
    this.selectedPageList = {
      "ProductSectionId": this.ProductSectionId,
      "IntakeId": this.intakeId,
      "lstIntakePages": this.getUnique(this.intakesService.accordionCheckedPageList, 'ItemId')
    };
    let sectionStatusData = {
      intakeId: this.intakeId,
      lstIntakeSection: [{
        productSectionId: this.ProductSectionId,
        status: this.selectedPageList.lstIntakePages.length ? 2 : 1,
        percentage: this.selectedPageList.lstIntakePages.length ? selectedItem.percentage : 0
      }]

    }
    if (!homepageMenu.status) {
      let homepageStatus = {
        productSectionId: homepageMenu.id,
        status: 2,
        percentage: homepageMenu.percentage
      }
      sectionStatusData.lstIntakeSection.push(homepageStatus);
    }

    
    this.intakesService.saveIntakePages(this.selectedPageList).subscribe((response) => {
      if (response) {
        this.toastrService.success('Data Saved Successfully.', 'Success');
        this.postIntakeSection(sectionStatusData);
        if (mode == 'continue') {
          this.goToNext();
        }
      }
    }, err => {
      this.httpService.openErrorPopup(err.error.message);
    });

  };

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {

    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  expandAll(rec) {
    try { 
      var elements = document.querySelectorAll('[aria-controls^="ngb-panel-"]');
      if (rec == true && elements && elements.length == 0) {
        return false;
      } 
      for (let i = 0; i < elements.length; i++) {
        var htmlElm = elements[i] as HTMLElement;
        if (htmlElm.classList.contains("collapsed")) {
          htmlElm.click();
        }
      } 
    }
    catch (e) {

    }

  };
  collapseAll() {
    this.activeIds = [];
  };

  getSampleData(page) {
    this.intakesService.getSampleData(page.id).subscribe((res) => {
      this.openSamplePopup(res);
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    })
  }
  openSamplePopup(pageData) {
    
    let model = this.modalService.open(PageSampleComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size: 'lg'});
    let data = {
      pageName: pageData.name,
      content: pageData.content,
      title: ''
    };
    model.componentInstance.data = data;

  }

  goToNext() {
    this.continueClicked.next();
  }


}
