import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntakesService } from '../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { socialMedia } from '@shared/master-enum.enum';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';
import { HttpService } from '../../../../services/http.service';
import { MenuService } from '@core/page-header/service/menu.service';
import { UserService } from '../../../admin/users/services/user-service.service';

@Component({
  selector: 'app-add-intakes-data',
  templateUrl: './add-intakes-data.component.html',
  styleUrls: ['./add-intakes-data.component.css']
})
export class AddIntakesDataComponent implements OnInit {
  basicInfoForm: UntypedFormGroup;
  productFamily;
  selectedItem;
  intakeSubmitted;
  intakeId;
  constructor(
    private intakesService: IntakesService,
    private menuService: MenuService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private router: Router,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeProductList(params.id);
      }
    });
    this.menuService.intakeProductFamily.subscribe(obj => {
      this.productFamily = obj ? obj['family'] : null;
      this.selectedItem = obj ? obj['selectedProduct'] : null;
    });
  }

  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.productFamily = res['sfIntakeProductFamily'];
        if(this.productFamily.length==0){ //for those product which have no intake
          this.httpService.openErrorPopup('There is no intake');
          this.router.navigate(['client/intakes']);
          return;
        }
        var hasUpgrade = _.some(this.productFamily, (pf) => pf.isUpgrade);
        if (this.selectedItem) {
          this.selectedItem = _.find(this.productFamily, (product) => {
            return product.intakeTypeId == this.selectedItem.intakeTypeId;
          })
        } else {
          // if there is an upgrade, get the first family that isn't an upgrade if the upgrade is the same family as the one upgrading from
          // This should prevent the website product for an upgrade to the same family (i.e. basic to starter or enhanced to essential) from being selected.
          this.selectedItem = _.find(this.productFamily, (product) =>
            // if there is no upgrade or if this is an upgrade product and the upgrade from family is not the same
            // as the upgrade's intakeTypeId then return the first product family
            !hasUpgrade || product.isUpgrade && product.upgradeFromFamily != product.intakeTypeId
            ? true
            // otherwise return the first item that isn't an upgrade.
            : !product.isUpgrade
          );
        }
        let site = {"domainUrl": res['domainUrl'], "siteId": res['siteId']};
          localStorage.setItem('selectedSite', JSON.stringify(site));
        // }
        this.menuService.intakeProductFamily.next({ family: this.productFamily, selectedProduct: this.selectedItem });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  ngOnInit() {

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        
        window.history.pushState(null, "", window.location.href);
    };
  }

  ngOnDestroy() {
  this.menuService.intakeProductFamily.next(null);
    window.history.replaceState(this.intakesService.myHistory, "", window.location.href);
    var myHis=this.intakesService.myHistory;
    window.onpopstate = function () {
      if (myHis.length > 0) {
        var pg = myHis.pop();
        window.history.pushState(myHis, "<name>", "<url>");
    };
  }
}

  openPage(item) {
    this.selectedItem = item;
  }
  goToIntake(productFamily) {
    this.selectedItem = productFamily;
    this.menuService.intakeProductFamily.next({ family: this.productFamily, selectedProduct: this.selectedItem });
    this.intakesService.basicInfo = {};
    this.intakeSubmitted = false;
  }

  changeIntakeStatus(isSubmitted) {
    
    this.intakeSubmitted = isSubmitted;
    this.getIntakeProductList(this.intakeId);
  }

  gotoNextProduct(){
    let selectedItem = this.selectedItem;
    let index = _.findIndex(this.productFamily, function (family) {
      return family.intakeTypeId == selectedItem['intakeTypeId'];
    });
    if(this.productFamily[index + 1]){
      this.goToIntake(this.productFamily[index + 1]);
    }else{
      this.router.navigate(['client/intakes']);
    }

  }




}
