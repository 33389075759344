import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from '@shared/models/Subscription';
import { StripeProduct } from '@shared/interfaces/interfaces';
import { Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.css']
})
export class AddSubscriptionComponent implements OnInit {
  @Input() siteId: number;
  @Input() existingSubscriptions: any; //array of dynamic products

  products = new Array<{}>();
  plans = new Array<{}>();
  planSelectMessage = 'Select a plan...';
  subscription: object;

  addSubscriptionForm = new UntypedFormGroup({
    plan: new UntypedFormControl({value: null, disabled: true}, Validators.required ),
    directBilled: new UntypedFormControl(null, Validators.required),
    product: new UntypedFormControl(null, Validators.required),
  });

  componentSubmittable = true;
  errorMessage = '';
  hasError = false;

  constructor(public subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.products = new Array<{name: String, id: String}>();

    this.subscriptionService.queryProductsNoIntake()
      .subscribe((productObservable: {data: []}) => {
        const productIds = this.existingSubscriptions.map(existingSubscription => {
        const allExistingProductIds = [];
        existingSubscription.products.map(product => allExistingProductIds.push(product.stripeProductId));
        return allExistingProductIds;
        }).flat();
        productObservable.data.map((product: StripeProduct) => {
          if(!productIds.includes(product.id)){
            this.products.push({
            name: product.name, //stripe nickname
            value: product.id, //stripeProductId
            });
            }
        });
      });
  }

  addSubscription() {
    const SubscriptionData = new Subscription({
      billingInterval: this.addSubscriptionForm.value.plan.interval,
      directBilled: this.addSubscriptionForm.value.directBilled,
      listPrice: this.addSubscriptionForm.value.plan.amount / 100,
      productMetadata: [{
        key: 'noIntakeRequired',
        value: 'true'
      }],
      siteId: this.siteId,
      stripePriceId: this.addSubscriptionForm.value.product
    });

    this.subscriptionService.postSubscriptions(SubscriptionData.getSubscriptionData())
      .subscribe(
        postObservable => {
          
        },
        (err: HttpErrorResponse) => {
          if (err.error.status === 400) {
            this.hasError = true;
            this.errorMessage = 'There has been an problem submitting your product selection.' +
              ' Please try again later or contact the development team.';
          }
        }
      );

    return this.componentSubmittable;
  }

  formIsValid(): boolean {
    return this.addSubscriptionForm.status === 'VALID';
  }

  showPlans(): void {
    this.subscriptionService.queryPlansByProduct(this.addSubscriptionForm.value.product).subscribe(
      planObservable => {
        this.plans = [];

        planObservable.data.map((plan: PlanData) => {
          this.plans.push({
            amount: plan.amount,
            id: plan.id,
            interval: plan.interval,
            nickname: plan.nickname,
          });
        });

        if (this.plans.length <= 0) {
          this.planSelectMessage = 'There are no available plans for this product.';
        } else {
          this.addSubscriptionForm.controls.plan.enable();
        }
      }
    );
  }

}

interface PlanData {
  id: string;
  amount: number;
  interval: string;
  nickname: string;
}
