import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { environment } from 'environments/environment';
import { Photography } from './photography-model';
import { SectionStatus } from '../../review/section-model';
import { DataService } from '@shared/data-service.service';
import { AssetsComponent } from '../../content/assets/assets.component';
import { HttpService } from '../../../../../../../services/http.service';
@Component({
  selector: 'app-homepage-photography',
  templateUrl: './homepage-photography.component.html',
  styleUrls: ['./homepage-photography.component.css']
})
export class HomepagePhotographyComponent implements OnInit {
  alert:boolean=false;
  intakeId;
  data;
  lifestyleData;
  ageGroupData;
  productsData;
  selectedSiteId;
  ethnicityData;
  smCount = {};
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  homepagePhotographyForm: UntypedFormGroup;
  checkRequiredField: boolean = false;
  photographs: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private toastrService: ToastrService,
    private httpService: HttpService,
    public dataService: DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.selectedSiteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.data = this.intakesService.basicInfo['photography'] ? this.intakesService.basicInfo['photography'] : this.existingData;
    this.getAgeGroupData();
    this.getEthinicityData();
    this.getLifestyleData();
    this.getProductsData();
    this.initializeForm();
    if (this.data.photography) {
      this.photographs = this.data.photography.split(',');
    }
  }

  checkLength(){
         this.alert=false;
  }
  initializeForm() {
    this.homepagePhotographyForm = this.formBuilder.group({
      lSubjectMatterAgeGroup: this.formBuilder.array([this.formBuilder.group({
        checked: new UntypedFormControl()
      })]),
      lSubjectMatterEthnicity: this.formBuilder.array([this.formBuilder.group({
        checked: new UntypedFormControl()
      })]),
      lSubjectMatterLifestyle: this.formBuilder.array([this.formBuilder.group({
        checked: new UntypedFormControl()
      })]),
      lSubjectMatterProducts: this.formBuilder.array([this.formBuilder.group({
        checked: new UntypedFormControl()
      })]),
      photography: new UntypedFormControl(this.data ? this.data.photography : null),
      localLandmarks: new UntypedFormControl(this.data ? this.data.localLandmarks : null)
    })
  }

  getAgeGroupData() {
    this.intakesService.getAgeGroupData().subscribe((res) => {
      if (res) {
        this.ageGroupData = res['masterList'];
        this.homepagePhotographyForm.setControl('lSubjectMatterAgeGroup', this.formBuilder.array(this.ageGroupData.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedData(c.id, this.data, 'lSubjectMatterAgeGroup')),
          id: new UntypedFormControl(c.id)
        }))));
        this.checkLimit(this.homepagePhotographyForm.controls.lSubjectMatterAgeGroup['controls'], 'lSubjectMatterAgeGroup');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getEthinicityData() {
    this.intakesService.getEthinicityData().subscribe((res) => {
      if (res) {
        this.ethnicityData = res['masterList'];
        this.homepagePhotographyForm.setControl('lSubjectMatterEthnicity', this.formBuilder.array(this.ethnicityData.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedData(c.id, this.data, 'lSubjectMatterEthnicity')),
          id: new UntypedFormControl(c.id)
        }))));
        this.checkLimit(this.homepagePhotographyForm.controls.lSubjectMatterEthnicity['controls'], 'lSubjectMatterEthnicity');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getLifestyleData() {
    this.intakesService.getLifestyleData().subscribe((res) => {
      if (res) {
        this.lifestyleData = res['masterList'];
        this.homepagePhotographyForm.setControl('lSubjectMatterLifestyle', this.formBuilder.array(this.lifestyleData.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedData(c.id, this.data, 'lSubjectMatterLifestyle')),
          id: new UntypedFormControl(c.id)
        }))));
        this.checkLimit(this.homepagePhotographyForm.controls.lSubjectMatterLifestyle['controls'], 'lSubjectMatterLifestyle');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getCheckedData(id, dataList, key) {
    if (dataList) {
      let filteredElement = _.filter(dataList[key], (data) => {
        return data.id == id;
      })
      if (filteredElement.length) {
        return true;
      }
    }

  }

  getProductsData() {
    this.intakesService.getProductsData().subscribe((res) => {
      if (res) {
        this.productsData = res['masterList'];
        this.homepagePhotographyForm.setControl('lSubjectMatterProducts', this.formBuilder.array(this.productsData.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedData(c.id, this.data, 'lSubjectMatterProducts')),
          id: new UntypedFormControl(c.id)
        }))));
        this.checkLimit(this.homepagePhotographyForm.controls.lSubjectMatterProducts['controls'], 'lSubjectMatterProducts');
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  saveFormData(form, mode?) {
    let intakeData = new Photography(form.value, this.intakeId);
    this.intakesService.basicInfo['photography'] = intakeData;
    intakeData.intakeId = this.intakeId;
    _.assign(this.intakesService.commonBasicInfoObj, intakeData);

    if (intakeData.lSubjectMatterAgeGroup.length == 0 || intakeData.lSubjectMatterEthnicity.length == 0 || intakeData.lSubjectMatterLifestyle.length == 0 || intakeData.lSubjectMatterProducts.length == 0) {
      this.checkRequiredField = false;
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
      this.alert=true;
    } else {
      this.checkRequiredField = true;
      this.alert=false;
    }

    if (this.checkRequiredField) {
      this.intakesService.saveIntake(this.intakesService.commonBasicInfoObj).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success(this.dataService.successMsg, 'Success');
          this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, intakeData.intakeId);
          this.postIntakeSection(sectionStatus);
          if (mode == 'continue') {
            this.goToNext('parent');
          }
        } else {

        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }

  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext(menu?) {
    this.continueClicked.next('parent');
  }

  checkLimit(sectionData, list) {
    this.smCount[list] = 0;
    _.forEach(sectionData, (data) => {
      if (data.value.checked) {
        this.smCount[list]++;
      }
    })
  }


  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.selectedSiteId;
    model.componentInstance.field = fieldName;
    model.componentInstance.isAssets = true;
    model.componentInstance.isMultipleFiles = true;
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if (this.photographs.length) {
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if (!n) {
            this.photographs.push(file);
          }
        })
        this.homepagePhotographyForm.controls.photography.setValue(this.photographs.join(','));
      }
    }, (reason) => {
      
    });

  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.title = 'Your Photography';
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('/')[file.split('/').length - 1] })
    });
    model.componentInstance.deleteAssets = (type?) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });

      this.photographs = [];
      this.homepagePhotographyForm.controls.photography.setValue('');
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.homepagePhotographyForm.controls.photography.setValue(this.photographs.join(','));
      })
      if (type != 'delete') {
        model.close();
      }
    }
  }

}
