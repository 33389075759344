import { Component, OnInit } from '@angular/core';
import { CcmService } from '../services/ccm.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { roles } from '@shared/master-enum.enum';
import {MenuService} from '../../../core/page-header/service/menu.service';
import * as _ from 'lodash';
import { HttpService } from '../../../services/http.service';
@Component({
  selector: 'app-cc-dashboard',
  templateUrl: './cc-dashboard.component.html',
  styleUrls: ['./cc-dashboard.component.css']
})
export class CcDashboardComponent implements OnInit {

  dashboardData;
  notifications;
  pageNo = 1;
  showMore;
  userInfo=JSON.parse(localStorage.getItem('userInfo'));
  constructor(
    private ccmService: CcmService,
    private toastrService: ToastrService,
    private router: Router,
    private httpService: HttpService,
    private menuService:MenuService
  ) {

  }

  ngOnInit() {
    this.getDashboardData();
    this.getNotifications(0,this.pageNo);
  }

  getDashboardData(){
    this.ccmService.getDasboardData(this.userInfo).subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  gotoCustomer (type?) {
    let params = {};
    if(type == 'new'){
      params = { isNew:true, isActive: true };
    }else if(type == 'cancelled'){
      params = { is_cancel: 1 ,dt: 2, startDate: moment().subtract(7, 'days').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')};
    }
    this.router.navigate(['client-communication-manager/sites'], { queryParams: params });
  }

  gotoprojects () {
    let params={status:0};
    this.router.navigate(['client-communication-manager/project'],{queryParams:params});
  }
  goToCanceledSite(){
    this.router.navigate(['client-communication-manager/cancel-site'], {queryParams: {startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY')}});
  }


  gotopaymentHistory () {
    this.router.navigate(['client-communication-manager/billing'], { queryParams: { status: -1, startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY'), productId: 1 } });
  }
  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }

  gotoActiveCustomer(productType) {
    let currentRole = JSON.parse(localStorage.getItem('userInfo')).roleId;
    let role = _.find(roles, (role) => {
      return role.roleId == currentRole;
    })
    let params = {};
    params = { isLive: true, product_type: productType || null };
    this.router.navigate([`${role.url}/sites`], { queryParams: params });
  }

  gotoCompletedProjects(){
    let currentRole = JSON.parse(localStorage.getItem('userInfo')).roleId;
    let role = _.find(roles, (role) => {
      return role.roleId == currentRole;
    })
    this.router.navigate([`${role.url}/completed-projects`]);
  }

}
