import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { IntakesService } from '../../../services/intakes.service';
import { Ppc } from '../../../models/intakePpc';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SectionStatus } from '../../basic/review/section-model';
import {ClientInfoModel} from '../client-information/clientInfo-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../services/http.service';
@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.css']
})
export class ClientInformationComponent implements OnInit {
  clientInfoForm: UntypedFormGroup;
  selectedIntakeData;
  intakeId;
  siteId;
  imageSrc;
  baseUrl = environment.imgUrl;
  @Input() productType;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus= new EventEmitter<any>(); 
  clientInfoModel=new ClientInfoModel();
  checkLocationField:boolean=false;
  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    public modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
        this.getIntakeProductList(params.id);
        //this.getIntakePpc(this.intakeId);
        
      }
    });
  }

  ngOnInit() {
    if(this.existingData && this.existingData.lstPPCLocation !=null && this.existingData.lstPPCLocation.length > 0){
      this.initializeForm();
      this.setLocationControl(this.existingData && this.existingData.lstPPCLocation ? this.existingData.lstPPCLocation : this.clientInfoModel);
    }else{
      this.existingData.lstPPCLocation=[];
      this.existingData.lstPPCLocation.push(this.clientInfoModel);
      this.initializeForm();
    }
  }

  initializeForm(){
    this.clientInfoForm = this.formBuilder.group({
      intakeId : this.intakeId ? this.intakeId : null,
      intakeTypeId : this.intakeTypeId ? this.intakeTypeId : null,
      multipleLoc: new UntypedFormControl(this.existingData.oIntakeLocation.lstLocation && this.existingData.oIntakeLocation.lstLocation.length > 1 ? true : false),
      date : new UntypedFormControl(this.existingData && this.existingData.date ? new Date(this.existingData.date):null),
      productType : new UntypedFormControl(this.productType.toUpperCase()),
      clientLogo : new UntypedFormControl(this.existingData ? this.existingData.clientLogo : null),
      email : new UntypedFormControl(this.existingData ? this.existingData.email : null),
      // lstPPCLocation: new FormControl([]),
      oIntakeLocation: this.formBuilder.group({
        intakeType:new UntypedFormControl(this.existingData ? this.existingData.oIntakeLocation.intakeType : 5), 
        lstLocation:[]
      }),
      lstPPCLocation: this.formBuilder.array([
        this.newLocationInstance(),
      ])
    });
  }

  setLocationControl(data) {
    this.clientInfoForm.setControl('lstPPCLocation', this.formBuilder.array(data.map(c => 
      this.newLocationInstance(c)
    )))
    
  }
  newLocationInstance(data?) {
    let location = this.formBuilder.group({
              id:new UntypedFormControl(data ? data.id : 0), 
              address1:new UntypedFormControl(data ? data.address1 : ''),
              address2:new UntypedFormControl(data ? data.address2 : ''),
              city: new UntypedFormControl(data ? data.city : ''),
              state:new UntypedFormControl(data ? data.state : ''),
              zipCode:new UntypedFormControl(data ? data.zipCode : ''),
              phoneNumber:new UntypedFormControl(data ? data.phoneNumber : '',[Validators.pattern(this.dataService.phoneRegex)])
    });
    return location;
  }

  updateFormControl(i, action){
    if (action == 'add') {
      this.clientInfoForm.controls.lstPPCLocation['controls'].push(
        this.newLocationInstance()
      );
    } else {
      if(this.clientInfoForm.controls.lstPPCLocation['controls'].length > 1){
        this.clientInfoForm.controls.lstPPCLocation['controls'].splice(i, 1);
      }
    }
    this.clientInfoForm.get('lstPPCLocation').updateValueAndValidity();
  }

  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.selectedIntakeData = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  isFieldValid(field: string,index:number =0) {
    let control=this.clientInfoForm.controls.lstPPCLocation;
     if(field=='address1'){
       for (let i=index;i<control['controls'].length;i++){ 
         return !control['controls'][i].controls.address1.valid && control['controls'][i].controls.address1.touched; 
       }
     }else if(field=='address2'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.address2.valid && control['controls'][i].controls.address2.touched; 
      }
     }else if(field=='city'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.city.valid && control['controls'][i].controls.city.touched; 
      }
     }else if(field=='state'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.state.valid && control['controls'][i].controls.state.touched; 
      }
     }else if(field=='zipCode'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.zipCode.valid && control['controls'][i].controls.zipCode.touched; 
      }
    }else if(field=='phoneNumber'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.phoneNumber.valid && control['controls'][i].controls.phoneNumber.touched; 
      }
     }else{
      return !this.clientInfoForm.get(field).valid && this.clientInfoForm.get(field).touched;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }

    });
  }


  save(form,mode){
    this.clientInfoForm.get('lstPPCLocation').updateValueAndValidity();
    // form.value.oIntakeLocation.lstLocation=form.value.lstLocation;

    if(form.valid){

      let intakeData =form.value;
      intakeData.date = intakeData.date ? intakeData.date.getMonth()+1+"/"+intakeData.date.getDate()+"/"+intakeData.date.getFullYear() : intakeData.date;

      _.assign(this.existingData, intakeData);
      // 
    //   _.forEach(intakeData.oIntakeLocation.lstLocation, (location) => {
    //     this.checkLocationField=true;
    //     // if(location.address1 == '' || location.address1 == null || location.city == '' || location.city == null || location.state == '' || location.state == null || location.zipCode == '' || location.zipCode ==null){
    //     // this.checkLocationField=true;
    //     // this.toastrService.error(this.dataService.requiredMsg, 'Error');
    //     //   return false;
    //     // }
    // })

  // if(this.checkLocationField){
      this.intakesService.savePpcIntake(this.existingData).subscribe(res => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success('Saved successfully', 'Success');
          this.intakesService.updateRequiredFieldsStatus(intakeData);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
          this.postIntakeSection(sectionStatus);
          if(mode == 'continue'){
            this.goToNext();
          }
        } 
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
      // }

    }else{
      this.toastrService.error('Please fill the location information', 'Error');
      this.validateAllFormFields(this.clientInfoForm);
    }
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }
  goBack() {
    this.backClicked.next();
  }

  goToNext(){
    this.continueClicked.next();
  }
  openUploadedFilesPopup(){
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.result.then((result) => {      
      if(result){
        this.imageSrc=result[0];
        this.clientInfoForm.controls.clientLogo.setValue(this.imageSrc);
      }
    }, (reason) => {
      
    });
  }
}
