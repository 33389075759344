import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SetPasswordService } from './services/set-password.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { EqualPasswordsValidator } from '@shared/validators/equalPassword';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  currentYear: number;
  setPasswordForm: UntypedFormGroup;
  userId;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private setPasswordService: SetPasswordService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getToken();

    const d = new Date();
    this.currentYear = d.getFullYear();
  }

  getToken(){
    let token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if(token){
      let tokenObj = {'token': token};
      this.setPasswordService.validateToken(tokenObj).subscribe(res => {
        if(res){
          
          this.userId = res.UserId;
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
          this.router.navigate(['/login']);
        });
    }
  }

  initializeForm(){
    this.setPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
  },
  {
    validator: EqualPasswordsValidator.validate('password', 'confirmPassword')
  });
  }

  setPassword(form){
    if(form.valid){
      let requestParams = {
        // 'id':this.userId,
        'token': this.activatedRoute.snapshot.queryParamMap.get('token'),
        'password':form.value.password
      }
      this.setPasswordService.setPassword(requestParams).subscribe(res => {
        if(res){
          this.toastrService.success('Password set successfully', 'Success');
          this.router.navigate(['/login']);
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });
    }
  }

}
