import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../services/payment.service';
import { from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';
@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  stripe: any;
  elements: any;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  paymentInfo;
  userInfo;
  postData;
  selectedSiteId;
  isActive=true;
  constructor(private cd: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    public paymentService: PaymentService,
    private httpService: HttpService,
    public toastrService: ToastrService
  ) { }
  ngOnInit() {    
    this.userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
    this.getStripKey();
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    this.isActive = false;
    this.postData = {
      description: "",
      amount: this.paymentInfo.amount,
      siteId: this.paymentInfo.siteId,
      intakeId: this.paymentInfo.intakeId
    };
  
    if (!this.paymentInfo.cardNumber) {
      try {
        const timeoutDuration = 5000; // 5 seconds timeout
        const createTokenPromise = this.stripe.createToken(this.card);
        
        const { token, error } = await Promise.race([
          createTokenPromise,
          new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout exceeded')), timeoutDuration))
        ]);
  
        if (error) {
          
        } else {
          this.postData['tokenId'] = token.id;
          
          if (this.paymentInfo.type == "add" || this.paymentInfo.amount == 0) {
            if (this.selectedSiteId) {
              this.postData = {
                SourceToken: token.id,
                SiteId: this.selectedSiteId
              };
            } else {
              this.postData = {
                SourceToken: token.id,
                SiteId: this.paymentInfo.siteId
              };
            }
  
            this.paymentService.addCard(this.postData).subscribe(res => {
              this.activeModal.close();
            },
              err => {
                this.httpService.openErrorPopup(err.error.message);
              });
          } else {
            this.charge();
          }
  
          
          // ...send the token to the your backend to process the charge
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    } else {
      this.postData.tokenId = null;
      this.charge();
    }
    this.isActive = true;
  }  
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }

charge(){
  this.paymentService.Charge(this.postData).subscribe(res => {
    if(res){
      this.toastrService.success('Payment Success', 'Success');
    }
    this.activeModal.close();
  },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
}

  getStripKey(){
    this.paymentService.getStripeKey().subscribe((res)=>{
      if(res){
        this.stripe = Stripe(res.PublishableKey); // use your test publishable key
        this.elements = this.stripe.elements();
        const style = {
          base: {
            lineHeight: '24px',
            fontFamily: 'monospace',
            fontSmoothing: 'antialiased',
            fontSize: '19px',
            '::placeholder': {
              color: 'purple'
            }
          }
        };
    
        this.card = this.elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);
    
        this.card.addEventListener('change', this.cardHandler);
      }
    },
    err=>{
      this.httpService.openErrorPopup(err.error.message);
    })
  }
}
