import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NotificationService } from '../service/notification.service';
import { addEditNotification } from '../models/add-edit-notification';
import { roles } from '@shared/master-enum.enum';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../services/http.service';
import { CorporatePartnerService } from '../../manage-corporate-partner/services/corporate-partner.service';
import { MasterService } from '../../masters/services/master.service';
@Component({
  selector: 'app-add-edit-notification',
  templateUrl: './add-edit-notification.component.html',
  styleUrls: ['./add-edit-notification.component.css']
})

export class AddEditNotificationComponent implements OnInit {

  loading: boolean = false;
  notificationForm: UntypedFormGroup;
  notifications;
  userInfo;
  currentRole;
  roles;
  cps;
  notificationsFor;
  notificationsType;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private corporatePartnerService: CorporatePartnerService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private masterService: MasterService,
    private notificationService: NotificationService,
    public dataService: DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id!=0) {
        this.getSavedNotifications(params.id);
      }
      else
        this.initializeForm(null);
    });
  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getCurrentRole();
    this.getNotificationFor();
    this.getNotificationType();
  }

  getNotificationFor(){
    this.notificationService.getUserType(9).subscribe((res: any) => {
      if (res) {
        this.notificationsFor = res;
      }
    },
      err => {
        this.loading = false;
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getNotificationType(){
    this.notificationService.getNotificationType().subscribe((res: any) => {
      if (res) {
        this.notificationsType = res;
      }
    },
      err => {
        this.loading = false;
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getCorporatePartner(){
    this.corporatePartnerService.getCpData().subscribe(res => {
      if(res){
        this.cps = res;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getRoles(){
    this.masterService.getMastersListById(1).subscribe(res => {
      if(res){
        // this.roles = res['masterList'];
        this.roles = _.filter(res['masterList'], (role) => {
          return role.id <= 10;
        })
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
    
  }

  getCurrentRole(){
    if(this.userInfo){
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == this.userInfo.roleId;
      })
    }
  }
  initializeForm(notifications) {
    this.notificationForm = this.formBuilder.group({
      id: notifications ? notifications[0].id : 0,
      name: new UntypedFormControl(notifications ? notifications[0].name : '', Validators.required),
      notificationFor: new UntypedFormControl(notifications ? notifications[0].notificationFor : '', Validators.required),
      refId: new UntypedFormControl(notifications ? notifications[0].refId : ''),
      type: new UntypedFormControl(notifications ? notifications[0].type : '', Validators.required),
      startDate: new UntypedFormControl(notifications && notifications[0].startDate != null ? new Date(notifications[0].startDate) : null, Validators.required),
      endDate: new UntypedFormControl(notifications && notifications[0].startDate != null ? new Date(notifications[0].endDate) : null, Validators.required),
    })
  }
  getSavedNotifications(id) {
    this.notificationService.getNotificationLists().subscribe((res: any) => {
      if (res) {
        
        this.notifications = _.filter(res, (item) => {
          return item.id == id;
        })
        this.initializeForm(this.notifications);
        this.loadNextField(this.notifications[0].notificationFor);
        this.loading = false;
      }
    },
      err => {
        this.loading = false;
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  saveFormData(form) {
    if (form.value) {
      let formData = form.value;
      formData.startDate = formData.startDate.getMonth() + 1 + "/" + formData.startDate.getDate() + "/" + formData.startDate.getFullYear();
      formData.endDate = formData.endDate.getMonth() + 1 + "/" + formData.endDate.getDate() + "/" + formData.endDate.getFullYear();
      this.notificationService.saveNotification(formData).subscribe((res) => {
        if (res) {
          this.toastrService.success('Saved Successfully', 'Success');
          this.router.navigate([this.currentRole .url+`/notification/`]);
        }
      }, err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }

  }

  loadNextField(notificationFor){
    if(notificationFor == 1||notificationFor == 2){
      this.getCorporatePartner();
    }
  }
} 
