import { Component, OnInit } from '@angular/core';
import { UserService } from '../../admin/users/services/user-service.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TicketService } from '../../ticket/service/ticket.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { MenuService } from '@core/page-header/service/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteService } from '../../site/service/site.service';
import { environment } from 'environments/environment';
import { FileUploadService } from '@shared/services/file-upload.service';
import { roles } from '@shared/master-enum.enum';
import { CorporatePartnerService } from '../services/corporate-partner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { AssetsComponent } from '../../customer/intakes/add-intakes-data/basic/content/assets/assets.component';
import moment from 'moment';
import { HttpService } from '@services/http.service';
import { DataService } from '@shared/data-service.service';
import { Ticket, TicketResponse } from '@shared/interfaces/tickets';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    '../../../core/dashboard/dashboard.component.css',
    './dashboard.component.css',
  ],
})
export class CpDashboardComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  userProfile;
  roles = roles;
  isFileInvalid = false;
  uploadData: FormData = new FormData();
  imgUrl;
  selectedSite;
  sites = [];
  photographs: any = [];
  ticketForm: UntypedFormGroup;
  fileName;
  intakeProduct;
  dashboardData;
  pageNo = 1;
  ticketType;
  userInfo;
  dateRange = {
    startDate: '',
    endDate: '',
    _startDate: '',
    _endDate: '',
    dateRange: '',
  };
  notifications;
  tickets;
  
  ticketList ?: Ticket[];
  siteAccountManager;
  activatedUrl;
  loading: boolean = false;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private ticketService: TicketService,
    private menuService: MenuService,
    private siteService: SiteService,
    private router: Router,
    private httpService: HttpService,
    private fileUploadService: FileUploadService,
    public modalService: NgbModal,
    private corporatePartnerService: CorporatePartnerService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.activatedUrl = urlPath[urlPath.length - 1].path;
    })
  }

  ngOnInit() {
    this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    this.getUserProfile();

    if (this.selectedSite) {
      this.getAmInfo(this.selectedSite.siteId);
    }

    this.getDashboardData();

    this.getTableData();
  }

  getDashboardData() {
    this.corporatePartnerService.getDasboardData().subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      key: 'portalTicketLink',
      createdDate: 'date',
    };

    this.columnHeaders = [
      { field: 'key', header: 'Ticket ID', width: 9},
      { field: 'domain', header: 'Domain'},
      { field: 'businessName', header: 'Business Name'},
      { field: 'cpAccountNumber', header: 'Account #', width: 10},
      { field: 'subject', header: 'Subject'},
      { field: 'auditType', header: 'Audit Type'},
      { field: 'assignee', header: 'Assignee', width: 10},
      // { field: 'priority', header: 'Priority', width: 10},
      { field: 'createdDate', header: 'Created Date'},
    ];    
  }

  getUserProfile() {
    if (_.isEmpty(this.userService.UserProfile)) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.userService.getUserProfile(userInfo.userId).subscribe((res) => {
        if (res) {
          this.initializeForm(res);
          this.userService.UserProfile = res;
        }
      },
        err => {
          throw err;
        })
    } else {
      this.initializeForm(this.userService.UserProfile);
    }
  }

  initializeForm(user?) {
    let site;
    if (this.selectedSite) {
      site = _.find(user.site, (val) => {
        return val.siteId == this.selectedSite.siteId;
      })
    }
    this.ticketForm = this.formBuilder.group({
      CreatedBy: ['Program'],
      Summary: [null, [Validators.required]],
      Description: [null, [Validators.required]],
      // WebSite: [site ? site.domainUrl : ''],
      siteId: [site ? site.siteId : '', [Validators.required]]
    });
  }

  strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    setTimeout(() => {
      tmp.remove();
    }, 2000);
    return tmp.textContent || tmp.innerText || "";
  }

  createTicket(form) {
    if (!this.ticketForm.valid || _.isEmpty(this.ticketForm.value.Summary.replace(/ /g, '')) || _.isEmpty(this.ticketForm.value.Description.replace(/ /g, ''))) {
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
      return;
    }
    let data = form.value;
    data = _.omit(data, ['WebSite']);
    data.Description = this.strip(data.Description);
    data.attachment = { fileName: this.fileName };
    //form.value.attachment.fileName=this.fileName;
    this.ticketService.createTicket(data).subscribe((res) => {
      if (res) {
        this.toastrService.success('Ticket created', 'Success');
        this.ticketForm.reset();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);

      })
  }

  getTickets(type, page?) {
    if (type)
    {      
      this.ticketType = type;
      if (type >= 20 && type <= 23)
      {
        this.columnDataTypes = {
          key: 'portalAuditTicketLink',
          createdDate: 'date',
        };
      }
      
    }
    let params = {
      pageNo: page || 0,
      ticketType: this.ticketType,
      startDate: this.dateRange ? this.dateRange.startDate : '',
      endDate: this.dateRange ? this.dateRange.endDate : '',
      status: (this.ticketType == 7 || this.ticketType == 21) ? 'NA' : '',
      siteId: this.selectedSite ? this.selectedSite.siteId : null
    }

    this.ticketService.getTickets(params).subscribe((res) => {
      if (res) {
        this.tickets = res;
        this.ticketList = this.tickets.tickets;
        
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  onValueChange(event) {
    if(event!=null && event!=""){
    this.dateRange['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.dateRange['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.dateRange['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.dateRange['_endDate'] = moment(event[1]).format('MM/DD/YY');
    }
    if(!this.ticketType){
      this.ticketType = 20;
    }
    this.getTickets(this.ticketType, 0);
  }

  resetParams() {
    this.dateRange = {
      startDate: '',
      endDate: '',
      _startDate: '',
      _endDate: '',
      dateRange:''
    };
    this.getTickets(this.ticketType, 0);
  }

  uploadFile() {
    if (this.uploadData.has("myFile")) {
      this.uploadData.append("AttachmentFor", 'JIRA')
      this.userService.uploadProfileImg(this.uploadData).subscribe(res => {
        this.fileName = res.File;
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }
  }

  onFileChanged(event) {
    if (event) {
      let file = event.target.files[0];
      this.uploadData.append('myFile', file, file.name);
      var checkimg = event.target.value.toLowerCase();
      if (!checkimg.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.pdf|\.PDF|\.docx|\.DOCX|\.csv|\.CSV|\.xlsx|\.XLSX)$/)) {
        this.isFileInvalid = true;
        return false;
      } else {
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();

          reader.readAsDataURL(event.target.files[0]); // read file as data url

          reader.onload = (event) => { // called once readAsDataURL is completed
            this.imgUrl = event.target['result'];
            this.isFileInvalid = false;
          }
        }
      }
      this.uploadFile();
    }

  }

  getBroadcastNotifications() {
    this.corporatePartnerService.getBroadcastNotifications().subscribe((res) => {
      if (res) {
        this.notifications = res;
      }
    },
      err => {
        throw err;
      });
  }

  getAmInfo(siteId) {
    this.userService.getAmInfo(siteId).subscribe((res) => {
      if (res) {
        this.siteAccountManager = res;
      }
    },
      err => {
        throw err;
      })
  }

  gotoAudit() {
    let params = {};
    params = { startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY') };
    this.router.navigate(['corporate-partner/audit-request'], { queryParams: params });
  }

  gotoCustomer() {
    let params = {};
    params = { status: 4 };
    this.router.navigate(['corporate-partner/sites'], { queryParams: params });
  }

  gotoInvoice(status) {
    let params = {};
    if (status == 'failed') {
      params = { status: -1, startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY'), productId: 1 };
    } else {
      params = { startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY'), productId: 1 };
    }
    this.router.navigate(['corporate-partner/billing'], { queryParams: params });
  }

  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = 0;
    model.componentInstance.attachmentFor = 'JIRA';
    model.componentInstance.baseUrl = environment.apiUrl;
    model.componentInstance.selectedFile = this.photographs;
    model.componentInstance.field = fieldName;
    model.componentInstance.isAssets = true;
    model.componentInstance.isMultipleFiles = true;
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if (this.photographs.length) {
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if (!n) {
            this.photographs.push(file);
          }
        })
        this.fileName = this.photographs.join(',');
      }
    }, (reason) => {
      
    });

  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.title = 'Attachments';
    model.componentInstance.baseUrl = environment.apiUrl;
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('\\')[1] })
    });
    model.componentInstance.deleteAssets = (type?) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });
      this.fileUploadService.uploadedFiles['files'] = model.componentInstance.assets;

      this.photographs = [];
      // this.homepagePhotographyForm.controls.photography.setValue('');
      this.fileName = '';
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.fileName = this.photographs.join(',');
      })
      if (type != 'delete' || !this.photographs.length) {
        model.close();
      }
    }
  }
}
