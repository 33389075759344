import { Component, OnInit } from '@angular/core';
import { IntakesService } from '../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../admin/users/services/user-service.service';
import { OtherPaymentComponent } from '../../payment/other-payment/other-payment.component';
import { StripePaymentComponent } from '../../payment/stripe-payment/stripe-payment.component';
import { from } from 'rxjs';
import * as _ from 'lodash';
import { HttpService } from '../../../../services/http.service';
@Component({
  selector: 'app-customer-intake-product-list',
  templateUrl: './customer-intake-product-list.component.html',
  styleUrls: ['./customer-intake-product-list.component.css']
})
export class CustomerIntakeProductListComponent implements OnInit {
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  intakeProductList;
  customerCardInfo;
  totalAmt = 0;
  IntakeId;
  loading = false;
  paymentStatus;
  openTAndC;
  queryParams;
  action;
  hasDirectBilled = false;
  EXHIBITA: boolean = false;
  EXHIBITC: boolean = false;
  constructor(
    private intakesService: IntakesService,
    private router: Router,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public modalService: NgbModal,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.IntakeId = params.id;
        this.loading = true;
        this.getIntakeProductList(params.id);
      }
    });
  }

  ngOnInit() {
    this.queryParams = this.activatedRoute.snapshot.queryParamMap.get('email');
  }

  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.intakeProductList = res;
        this.loadSite(this.intakeProductList);
        this.paymentStatus = this.intakeProductList.paymentStatus;
        this.totalAmt = 0;
        for (var i = 0; i < this.intakeProductList.sfIntakeProduct.length; i++) {
          if (this.intakeProductList.sfIntakeProduct[i].paymentType == "")
            this.totalAmt += this.intakeProductList.sfIntakeProduct[i].totalPrice;
          if (this.intakeProductList.sfIntakeProduct[i].isDirect)
            this.hasDirectBilled = true;
        }
        this.getCard(this.intakeProductList.siteId);

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  loadSite(intakeProductList) {
    let sites = JSON.parse(localStorage.getItem('userInfo')).siteIds.split(',');
    let selectedSite = _.find(sites, (site) => {
      return site == intakeProductList.siteId;
    })
    if (selectedSite) {
      let site = {
        siteId: intakeProductList.siteId,
        domainUrl: intakeProductList.domainUrl
      }
      localStorage.setItem('selectedSite', JSON.stringify(site));
    }
  }
  getCard(siteId) {
    this.intakesService.GetCustomerPayInfo(siteId).subscribe((res) => {
      this.customerCardInfo = res;
      this.loading = false;
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  openAddPopup(componentName, paymentInfo, tnc?) {
    var model = this.modalService.open(componentName, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.paymentInfo = paymentInfo;
    model.result.then((result) => {
      
      this.goToAddIntake(this.intakeProductList);
      this.openTAndC = false;
    }, (reason) => {
      
    });

  }

  goToAddIntake(intake) {
    if (!this.intakeProductList.isAcceptTnC) {
      this.openTAndC = true;
      window.scrollTo(0, 0);
    } else {
      this.router.navigate([`/client/intakes/${intake.intakeId}/${intake.siteId}`]);
      this.intakesService.productFamilyList = this.intakeProductList.sfIntakeProductFamily;
    }
  }

  // openTermsAndConditions(intakeProductList, type?) {
  //   this.action = type;
  //   if (!intakeProductList.isAcceptTnC) {
  //     this.openTAndC = true;
  //   } else {
  //     this.CustomerPayInfo(intakeProductList.siteId);
  //   }
  //   window.scrollTo(0, 0);
  // }

  openExhibit(ExhibitType) {
    if (ExhibitType == 'A') {
      this.EXHIBITA = true;
    } else {
      this.EXHIBITC = true;
    }
    window.scrollTo(0, 0)
  }

  backToProductList() {
    this.openTAndC = false;
    this.getIntakeProductList(this.IntakeId);
  }

  gotoPaymentPopup(intakeProductList) {
    if (!intakeProductList.isAcceptTnC) {
      this.openTAndC = true;
    } else {
      if (
        (!this.customerCardInfo.isCardExists && this.hasDirectBilled)
      ) {
        this.openCardPopup(intakeProductList);
      } else {
        this.goToAddIntake(intakeProductList);
      }
    }
  }

  openCardPopup(productList) {
    this.openTAndC = false;
    let paymentInfo = {};
    let componentName;
    let totalAmount;
    this.intakeProductList = productList;
    paymentInfo = {
      siteId: productList.siteId,
      cardNumber: this.customerCardInfo.last4,
      expirationYear: this.customerCardInfo.expYear,
      expirationMonth: this.customerCardInfo.expMonth,
      intakeId: this.IntakeId,
      userId: this.userInfo.userId,
      amount: this.totalAmt || 0
      // amount: this.paymentStatus == 1 ? 0 : this.totalAmt
    };
    componentName = StripePaymentComponent;
    this.openAddPopup(componentName, paymentInfo);

  }

}
