import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../site/service/site.service';
import moment from 'moment';
import { ExcelService } from '../../reporting/services/excel.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { ReportingService } from '../services/reporting.service';

@Component({
  selector: 'app-amrpc-report',
  templateUrl: './amrpc-report.component.html',
  styleUrls: ['./amrpc-report.component.css']
})
export class AmrpcReportComponent implements OnInit {

  userId;
  sites = [];
  cpList = [];
  siteId = '';
  cpId = '';
  quarterData;
  currentYear = moment().year();
  filterBy = {
    cpId: '',
    siteId: '',
    site: '',
    quarter: moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YY'),
    year: moment().year()
  };
  amrpcReport = [];
  avgAMRPC;

  constructor(
    private siteService: SiteService,
    private reportingService: ReportingService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem('userInfo')).userId;
    this.quarterData = this.reportingService.getQuarters();
    this.getCorporatePartner();
    //this.getSites(0);
    //this.getAmrpcReport(this.filterBy);
  }
  getSites(cpId) {
    this.siteService.getSites(cpId).subscribe((res) => {
      if (res) {
        this.sites = res;
        this.filterBy['site'] = '';
        this.filterBy['siteId'] = null;
        
      }
    },
      err => {
        throw err;
      })
  }

  getCorporatePartner() {
    this.siteService.getCorporatePartner().subscribe((res) => {
      if (res) {
        this.cpList = res;
        if (this.cpList.length == 1) {
          this.filterBy['cpId'] = this.cpList[0].id;
          this.getSites(this.filterBy['cpId']);
          this.getAmrpcReport(this.filterBy);
        } else {
          this.getSites(0);
          this.getAmrpcReport(this.filterBy);
        }
        
      }
    },
      err => {
        throw err;
      })
  }

  getAmrpcReport(filterBy) {
    this.amrpcReport=[];
    this.avgAMRPC ={};
    if (moment(filterBy['quarter'], "MM/DD/YY", true).isValid()) {
      filterBy['formattedStartDate'] = moment(filterBy['quarter']).startOf('quarter').format('MM/DD/YYYY');
      filterBy['formattedEndDate'] = moment(filterBy['quarter']).endOf('quarter').format('MM/DD/YYYY');
    }
    filterBy.siteId = !_.isEmpty(filterBy.site) ? filterBy.site.id : '';

    this.siteService.getAmrpcReport(filterBy).subscribe((res) => {
      if (res) {
        this.amrpcReport = res.lstAMRPCReport;
        this.avgAMRPC = res.AMRPC;
        
      }
    },
      err => {
        throw err;
      })
  }
  exportAsXLSX() {
    let exelData = [
      {
        domainUrl: 'Domain',
        name: 'Name',
        amount: 'Amount'
      }
    ];
    _.forEach(this.amrpcReport, (user) => {
      let data = _.pick(user, ['domainUrl', 'name', 'amount']);
      exelData.push(data);
    })
    let avg = {
      domainUrl: '',
      name: 'AMRPC',
      amount: this.avgAMRPC
    };
    exelData.push(avg);
    this.excelService.exportAsExcelFile(exelData, 'AMRPC-Report');
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.sites.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    formatter = (result: string) => `${result['name'] || ''}`;
    inputFormatter = (result: string) => `${result['name'] || ''}`;
}
