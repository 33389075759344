import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { PaymentService } from "../payment/services/payment.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { SiteService } from "../../site/service/site.service";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { HttpService } from "@services/http.service";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { CorporatePartnerService } from "../../admin/manage-corporate-partner/services/corporate-partner.service";
import { roles } from "@shared/master-enum.enum";
import { CorporatePartner } from "@shared/interfaces/client";
import {
  ColumnDataTypes,
  ColumnHeader,
  PageHasTable,
} from "@shared/interfaces/tables";
import { TableGenericComponent } from "@shared/components/tables/table-generic/table-generic.component";
import {
  PaymentHistory,
  PaymentHistoryFilter,
} from "@shared/interfaces/billing";
import { SiteInfo } from "@shared/interfaces/site";

@Component({
  selector: "app-payment-history",
  templateUrl: "./payment-history.component.html",
  styleUrls: ["./payment-history.component.css"],
  providers: [DatePipe],
})
export class PaymentHistoryComponent implements OnInit, PageHasTable {
  @Input() corporatePartners: CorporatePartner[];
  @Input() selectedSiteId: number | string;
  @Input() site: SiteInfo;

  @ViewChild("dt") table: TableGenericComponent;

  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  nestedTableColumnHeaders?: ColumnHeader[];
  nestedTableData: Array<object>;
  nestedTableDataType: ColumnDataTypes;
  nestedTableQuery: EventEmitter<any>;
  roles = roles;

  userInfo =
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo"))
      : { token: "", userId: null, roleId: null };

  paymentHistory: PaymentHistory[];
  paymentHistoryProcessed: PaymentHistory[] = [];
  corporatePartner;
  allPayment;

  filterBy: PaymentHistoryFilter = {
    site: null,      
    status: "all",   
    siteName: null,
    paymentBy: "",
  };

  loading = true;
  siteId: number | null = null;
  siteIsLock: boolean;
  currentRole: string;
  invoiceStatus: any;
  startDate = null;
  endDate = null;
  status = null;
  sites;
  allSites;
  products;

  constructor(
    public paymentService: PaymentService,
    public toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private corporatePartnerService: CorporatePartnerService,
    private siteService: SiteService
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1]?.path;
    });
  }

  ngOnInit() {
    this.siteIsLock = this.siteService.siteIsLock;
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const paramSiteId = +params.get("siteId") || null;
      const paramStatus = params.get("status");
      const paramStartDate = params.get("startDate");
      const paramEndDate = params.get("endDate");

      this.siteId = this.selectedSiteId ? +this.selectedSiteId : paramSiteId;
      this.filterBy.site = this.siteId != null ? this.siteId : null;

      if (paramStatus) {
        this.filterBy.status = paramStatus;
      }
      if (paramStartDate) {
        this.filterBy.startDate = new Date(paramStartDate);
      }
      if (paramEndDate) {
        this.filterBy.endDate = new Date(paramEndDate);
      }

    });

    this.getInvoiceStatus();
    this.getTableData();

    if (this.userInfo.roleId < 11) {
      this.corporatePartners = this.corporatePartners;
    }
  }

  /**
   */
  getPaymentHistory(filterBy: PaymentHistoryFilter) {
    if (filterBy.site == null) {
      filterBy.site = 0;
    }

    filterBy["formattedStartDate"] = this.datePipe.transform(
      filterBy.startDate,
      "MM-dd-yyyy"
    );
    filterBy["formattedEndDate"] = this.datePipe.transform(
      filterBy.endDate,
      "MM-dd-yyyy"
    );

  
    this.paymentService.getPaymentList(filterBy).subscribe(
      (res) => {
        this.paymentHistoryProcessed = [];

        if (res) {
          res.forEach((paymentHistory) => {
            if (paymentHistory.paymentBy === 0) {
              paymentHistory.paymentBy = "Program";
            } else {
              paymentHistory.paymentBy = "Client";
            }
            this.paymentHistoryProcessed.push(paymentHistory);
          });
          this.paymentHistory = res;
          this.allPayment = [...this.paymentHistory];
        }
        this.loading = false;
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
        this.loading = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
  if (changes.site && changes.site.currentValue) {
    // e.g. load new data if site changed
    this.refreshDataForSite(changes.site.currentValue);
  }
}

private refreshDataForSite(newSite: SiteInfo) {
  // If site has an `id`, use it; otherwise treat as "all sites" = 0
  if (newSite && newSite.id) {
    this.filterBy.site = newSite.id;
    this.siteId = newSite.id;
    this.siteIsLock = newSite.isLock; // e.g. handle locking state
  } else {
    this.filterBy.site = 0;
    this.siteId = null;
  }

  // Then fetch payment history for that site
  this.getPaymentHistory(this.filterBy);
}

  applyFilter(site) {
    this.filterBy.siteName = site.businessName;
    this.filterBy.site = this.siteId || 0;
    this.getPaymentHistory(this.filterBy);
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(
      (res) => {
        if (res) {
          this.corporatePartner = res;
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  getTableData() {
    this.columnDataTypes = {
      invoiceCreatedOn: "date",
      invoiceId: "invoiceUrlLink",
      paymentOn: "date",
      totalAmount: "currency",
    };

    this.columnHeaders = [
      { field: "siteName", header: "Business Name" },
      { field: "totalAmount", header: "Amount" },
      { field: "invoiceCreatedOn", header: "Invoice Creation Date" },
      { field: "invoiceStatus", header: "Invoice Status", filter: "select" },
      { field: "paymentStatus", header: "Payment Status", filter: "select" },
      { field: "invoiceId", header: "Invoice URL" },
      { field: "paymentOn", header: "Payment Date" },
      { field: "paymentBy", header: "Billed To", filter: "select" },
    ];

    this.nestedTableColumnHeaders = [
      { field: "productName", header: "Product Name" },
      { field: "quantity", header: "Quantity" },
      { field: "amount", header: "Amount" },
    ];

    this.nestedTableDataType = {
      amount: "currency",
    };
  }

  resetFilter() {
    this.table.clearFilters();
    this.filterBy = {
      site: this.siteId || 0,
      status: "",
      siteName: null,
      paymentBy: "",
    };
    this.getPaymentHistory(this.filterBy);
  }

  paymentStatusIsComplete(payment: PaymentHistory): boolean {
    return ["Failed", "draft"].includes(payment.paymentStatus);
  }

  // Typeahead
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 3
          ? []
          : this.allSites
              .filter((v) =>
                v["businessName"].toLowerCase().includes(term.toLowerCase())
              )
              .slice(0, 10)
      )
    );
  formatter = (result: string) => `${result["businessName"]}`;
  inputFormatter = (result: string) => `${result["businessName"]}`;

 
  callFromParent(site: any): void {
    if (site && site.id) {
      this.selectedSiteId = site.id;
      this.siteIsLock = site.isLock;
      this.filterBy.site = site.id;
      this.siteId = site.id;
    } else {
      this.selectedSiteId = null;
      this.siteId = null;
      this.filterBy.site = 0;
    }
    this.getPaymentHistory(this.filterBy);
  }

  payInvoice(siteId, invoiceId) {
    const data = { siteId, invoiceId };
    this.paymentService.Charge(data).subscribe(
      (res) => {
        if (res) {
          this.getPaymentHistory(this.filterBy);
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  getInvoiceStatus() {
    this.paymentService.getGeneralMasterByType(13).subscribe(
      (res) => {
        if (res) {
          this.invoiceStatus = res;
        }
      },
      (err) => {
        throw err;
      }
    );
  }
}
