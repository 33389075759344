import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../services/intakes.service';
import * as _ from 'lodash';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit {
  @Input() data;
  @Input() menuId;
  @Input() submenu;
  @Input() selectedMenu;
  @Input() intakeTypeId;
  @Output() continue = new EventEmitter<object>();
  @Output() back = new EventEmitter<object>();
  @Output() changeStatus=new EventEmitter<any>();
  prevSelectedItem;
  selectedSiteId;
  intakeId;
  selectedItem = {};
  constructor(
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        // this.selectedItem = this.submenu[0];
      }
    });
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        
        _.forEach(obj.basicMenu, (menu) => {
          if (this.selectedMenu && menu.id == this.selectedMenu.id) {
            this.selectedMenu.submenu = menu.submenu;
          }
        })
      }
    });
  }

  openPage(item?) {
    this.selectedItem = item ? item : {id:0};
  }

  ngOnInit() {
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = _.find(this.selectedMenu.submenu, (item) => { return item.id == this.selectedMenu.selectedSectionId });
    }
  }

  goBack(menu) {
    if (menu == 'parent') {
      this.back.emit();
    } else {
    let selectedItem = this.selectedItem;
    if (selectedItem['id'] !== 8) {
      let index = _.findIndex(this.selectedMenu.submenu, function (menu) {
        return menu.id == selectedItem['id'];
      });
      this.selectedItem = this.selectedMenu.submenu[index - 1];
    } else {
      this.selectedItem = {};
    }
    window.scroll(0,0);
  }
  }

  goToNext(menu) {
    if (menu == 'parent') {
      this.continue.emit();
    } else {
      let selectedItem = this.selectedItem;
      let index = _.findIndex(this.selectedMenu.submenu, function (menu) {
        return menu.id == selectedItem['id'];
      });
      this.selectedItem = this.selectedMenu.submenu[index + 1];
      window.scroll(0,0);
    }
  }
changeIntakeStatus(){
  this.changeStatus.next();
}



}
