import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { IntakesService } from '../../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@shared/data-service.service';
import { SectionStatus } from '../../review/section-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../../../../../../services/http.service';

@Component({
  selector: 'app-basic-practice',
  templateUrl: './basic-practice.component.html',
  styleUrls: ['./basic-practice.component.css']
})

export class BasicPracticeComponent implements OnInit {
  basicPracticeForm: UntypedFormGroup;
  intakeId;
  data;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus=new EventEmitter<any>();
  pmsProviderData;
  pmsOther;
  primaryGoalsData;
  selectedSiteId;
  requiredFields = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private elementRef:ElementRef,
    public modalService: NgbModal,
    public dataService:DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.selectedSiteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.data = this.intakesService.basicInfo['basicPractice'] ? this.intakesService.basicInfo['basicPractice'] : this.existingData;
    this.getRequiredFields();
    // this.intakesService.updateRequiredFieldsStatus(this.data);
    this.getPmsProviderData();
    this.getPrimaryGoalsData();
    this.initializeForm();

  }

  getRequiredFields() {
    this.requiredFields = _.filter(this.intakesService.requiredFields, (field) => {
      return field.sectionId == this.sectionId;
    });

  }

  initializeForm() {

    this.basicPracticeForm = this.formBuilder.group({
      pmsProviderType: new UntypedFormControl(this.data && this.data.pmsProviderType !=null ? this.data.pmsProviderType :''),
      otherPmsProvider: new UntypedFormControl(this.data ? this.data.otherPmsProvider : null,Validators.pattern(this.dataService.checkWhiteSpace)),
      businessEstablishmentYear: new UntypedFormControl(this.data ? this.data.businessEstablishmentYear : null,Validators.pattern(this.dataService.numberRegex)),
      isDomainTransfer: new UntypedFormControl(this.data ? this.data.isDomainTransfer : null),
      email: new UntypedFormControl(this.data.email),
      isDomainEmail: new UntypedFormControl(this.data.isDomainEmail),
      goal: new UntypedFormControl(this.data && this.data.goal !=null ? this.data.goal : ''),
      city: new UntypedFormControl(this.data ? this.data.city : null,Validators.pattern(this.dataService.checkWhiteSpace)),
      state: new UntypedFormControl(this.data ? this.data.state : null,Validators.pattern(this.dataService.checkWhiteSpace)),
      address: new UntypedFormControl(this.data ? this.data.address : null,),
      zipCode: new UntypedFormControl(this.data ? this.data.zipCode : null),
      isTagline: new UntypedFormControl(this.data && this.data.isTagline != null ? this.data.isTagline : null),
      tagline: new UntypedFormControl(this.data ? this.data.tagline : null,Validators.pattern(this.dataService.checkWhiteSpace)),
      isMissionStatement: new UntypedFormControl(this.data && this.data.isMissionStatement != null ? this.data.isMissionStatement : null),
      missionStatement: new UntypedFormControl(this.data ? this.data.missionStatement : null,Validators.pattern(this.dataService.checkWhiteSpace)),

    });




  }

  getPmsProviderData() {
    this.intakesService.getPmsProviderData().subscribe((res) => {
      if (res) {
        this.pmsProviderData = res['masterList'];
        this.pmsOther = _.find(this.pmsProviderData, (provider) => {
          return provider.name.toLowerCase() == 'other';
        });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getPrimaryGoalsData() {
    this.intakesService.getPrimaryGoalsData().subscribe((res) => {
      if (res) {
        this.primaryGoalsData = res['masterList'];
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  isFieldValid(field: string,index:number =0) {
      return !this.basicPracticeForm.get(field).valid && this.basicPracticeForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }
  saveFormData(form, mode?) {

    if(this.basicPracticeForm.valid){
      
    form.value.tagline = (!form.value.isTagline && form.value.isTagline != null) ? '' : form.value.tagline;
    form.value.missionStatement = (!form.value.isMissionStatement && form.value.isMissionStatement != null) ? '' : form.value.missionStatement;
    let intakeData = this.intakesService.basicInfo['basicPractice'] = form.value;
    intakeData.intakeId = this.intakeId;
    _.assign(this.intakesService.commonBasicInfoObj, intakeData);
    
    this.intakesService.saveIntake(this.intakesService.commonBasicInfoObj).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        if(mode == 'continue' || mode == 'submit'){
          this.toastrService.success(this.dataService.successMsg, 'Success')
        };
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, intakeData.intakeId);
        this.postIntakeSection(sectionStatus);
        if (mode == 'continue') {
          this.goToNext();
        }
      } else {

      }
      // 

    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }else{
      
      this.validateAllFormFields(this.basicPracticeForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }


  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.continueClicked.next();
  }
  taglineChangeEvt(event, tag) {
    if (!(this.basicPracticeForm.controls.isTagline.value) && tag == 'tagline')
      this.basicPracticeForm.controls.tagline.setValue('');
    if (!(this.basicPracticeForm.controls.isMissionStatement.value) && tag == 'mission')
      this.basicPracticeForm.controls.missionStatement.setValue('');
    if (!(this.basicPracticeForm.value.pmsProviderType == this.pmsOther.id) && tag == 'pms')
      this.basicPracticeForm.controls.otherPmsProvider.setValue(null);
  }

  ngAfterViewInit() {
    // this.elementRef.nativeElement.querySelectorAll(['input','select','textarea']).forEach(element => {
    //   element.addEventListener('blur', this.onClick.bind(this));
    // });
  }

  onClick(event) {
    this.saveFormData(this.basicPracticeForm);
  }


}
