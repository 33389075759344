import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user-service.service';
import { User } from '../models/user';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { masterEnum } from '@shared/master-enum.enum';
import { MasterService } from '../../masters/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../../reporting/services/excel.service';
import { SitesPopupComponent } from '../sites-popup/sites-popup.component';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ChangePasswordComponent } from '../../../change-password/change-password.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit, PageHasTable {
  @ViewChild('dt') dt: TableGenericComponent;
  @ViewChild('userSearchInputVC') userSearchinputViewChild: ElementRef;

  columnDataTypes?: ColumnDataTypes;
  public masterTypeId;
  public roles;
  public allUsers: User[];
  public usersList: User[];
  page = 1;
  columnHeaders: Array<ColumnHeader>;
  currentRole;
  parentId;
  isParentCustomer = false;
  filterBy = {role: '', username: '', name: '', mobile: ''};
  userId;
  siteId;
  selectedSite;
  userInfo = localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : {
    'token': '',
    'userId': null,
    'roleId': null,
  };
  allSites;
  site;

  constructor(
    private userService: UserService,
    private router: Router,
    private excelService: ExcelService,
    private masterService: MasterService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    public modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    localStorage.setItem('previousUrl', this.router.url);

    this.userId = JSON.parse(localStorage.getItem('userInfo')).userId;

    const selectedSite = JSON.parse(localStorage.getItem('selectedSite'));

    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
// Retrieve the selected site from localStorage
    const storedSite = localStorage.getItem('selectedSite');
    if (storedSite) {
      this.selectedSite = JSON.parse(storedSite);
    } else {
      this.selectedSite = null; 
    }

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.parentId = params.id;
        this.getUsersByParent(params.id);
        this.isParentCustomer = true;
      } else {
        this.getUsers(this.filterBy, this.siteId);
        this.getRoles();
      }
    });

    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
      // this.mf.setSort('username', 'asc');
      // 
    });

    this.getSites();
  }

  // Focus the search field using ViewChild (autofocus html attribute unreliable)
  ngAfterViewInit() {
    this.userSearchinputViewChild.nativeElement.focus();
  }

  getTableData(): void {
    this.columnHeaders = [
      {field: 'username', header: 'Username'},
      {field: 'fullName', header: 'Full Name'},
      {field: 'phone', header: 'Phone'},
      {field: 'userRoleName', header: 'Role', filter: 'select'},
      {field: 'isActive', header: 'Active', filter: 'activeInactive'},
      { field: 'lastLoggingTime', header: 'Last Login Time'},
    ];

    this.columnDataTypes = {
      'isActive': 'checkmark',
      'phone': 'phone',
    };
  }

  getUsersByParent(parentId) {
    let siteId = JSON.parse(localStorage.getItem('selectedSite')).siteId;
    this.userService.getAllUsersByParent(parentId, siteId).subscribe((res) => {
      if (res) {
        this.allUsers = res;
        this.allUsers = _.filter(this.allUsers, (user) => {
          user.fullName = `${user.firstName} ${user.middleName} ${user.lastName}`;
          return user.isActive;
        });
        this.usersList = Object.assign([], this.allUsers);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getUserList(siteId) {
    this.getUsers(this.filterBy, siteId);
  }

  /**
   * Retrieves all of the users from the API
   *
   * @param {String} filterBy
   * @param {String} siteId
   */
  getUsers(filterBy, siteId: string) {
    this.userService.getAllUsers(filterBy, siteId).subscribe((res) => {
      if (res) {
        this.allUsers = res.sort((a: User, b: User) => a.username.localeCompare(b.username));

        _.forEach(this.allUsers, (user) => {
          user.fullName = `${user.firstName} ${user.middleName} ${user.lastName}`;
          if (user.lastLoggingTime) {
            const date = new Date(user.lastLoggingTime);
        
            // Check for the default invalid date
            if (date.getFullYear() === 1) {
              user.lastLoggingTime = 'Unknown';
            } else {
              // Custom formatted date
              const year = date.getFullYear();
              const month = date.toLocaleString('default', { month: 'long' }); // e.g., 'December'
              const day = date.getDate().toString().padStart(2, '0'); // e.g., '06'
              const hours = date.getHours().toString().padStart(2, '0'); // e.g., '23'
              const minutes = date.getMinutes().toString().padStart(2, '0'); // e.g., '39'
              const seconds = date.getSeconds().toString().padStart(2, '0'); // e.g., '07'
        
              // Format it as: "December 6, 2024, 23:39:07"
              user.lastLoggingTime = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
            }
          } else {
            user.lastLoggingTime = 'Unknown';
          }
        });

        this.usersList = Object.assign([], this.allUsers);

        this.getTableData();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goToEdit(user) {
    // this.userService.existingUser = user;
    this.router.navigate(['/' + this.currentRole + '/user/' + user.id]);
  }
  deleteUser(user) {
    if (confirm("Are you sure to delete " + user.username)) {
      if (this.parentId) {
        this.updateUser(user);
      } else {
        this.delete(user.id);
      }

    }
  }

  delete(id) {
    this.userService.delete(id).subscribe(res => {
      this.getUsers(this.filterBy,this.siteId);
    }, err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  updateUser(user) {
    user.isActive = false;
    this.userService.updateUser(user).subscribe(res => {
      this.getUsersByParent(this.parentId);
    }, err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getRoles() {
    this.masterTypeId = masterEnum['role'];
    this.masterService.getMastersListById(this.masterTypeId).subscribe(res => {
      if (res) {
        this.roles = res.masterList;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  applyFilter(filterBy) {
    if(filterBy.length == 0 || filterBy.length >= 3){
      this.getUsers(this.filterBy,this.siteId);
    }
  }

  openSitesPopup(sites) {
    var model = this.modalService.open(SitesPopupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.sites = sites;
  }

  public resetPagination() {
    // this.mf.setPage(1, this.mf.rowsOnPage);
  }

  getSites(){
    this.userService.getSites().subscribe((res) => {
      if (res) {
        this.allSites = Object.assign([], res);
        // this.sites = res;
        // if(this.selectedSite){
        //   this.site = _.find(this.sites, (site) => {
        //     return site.id == this.selectedSite.siteId;
        //   })
        //   this.getIntakeProductList(this.site.id);
        // }
      }
    },
      err => {
        throw err;
      })
  }
  openChangePasswordPopup(userId) {
    var model = this.modalService.open(ChangePasswordComponent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static"
    });
    model.componentInstance.userId = userId;
    model.result.then(
      result => {
      },
      reason => {
        
      }
    );
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 3 ? []
      : this.allSites.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
  formatter = (result: string) => `${result['name']}`;
  inputFormatter = (result: string) => `${result['name']}`;

}
