import { Component, OnInit, Input } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import * as html2pdf from 'html2pdf.js';
import * as _ from 'lodash';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-sem-rush-report',
  templateUrl: './sem-rush-report.component.html',
  styleUrls: ['./sem-rush-report.component.css']
})
export class SemRushReportComponent implements OnInit {
  @Input() siteId;
  semRushReport;
  yearsData = [];
  reportParams = {
    year:'',
    quarter: ''
  };
  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.getYearsData();
  }

  getYearsData() {
    let currentYear = moment().year();
    for (let i = 0; i <= 3; i++) {
      this.yearsData.push(currentYear - i);
    }
  }

  getReport(siteId, params) {
    this.semRushReport={};
    let date = `${params['quarter']}-${params.year}`;
    if (moment(date, "MM-DD-YYYY", true).isValid()) {
      params['formattedStartDate'] = moment(date).startOf('quarter').format('YYYY-MM-DD');
      params['formattedEndDate'] = moment(date).endOf('quarter').format('YYYY-MM-DD');
    }
    let emptyVal = _.pickBy(params, function (value, key) {
      return _.isEmpty(params[key]);
    });
    if (_.isEmpty(emptyVal)) {
    this.reportingService.getSemRushReport(siteId, params).subscribe((res: any) => {
      if (res) {
        this.semRushReport = res;
        this.semRushReport['healthyPercentage'] = this.semRushReport.siteHealth.healthy / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['brokenPercentage'] = this.semRushReport.siteHealth.broken / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['haveIssuesPercentage'] = this.semRushReport.siteHealth.haveIssues / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['redirectedPercentage'] = this.semRushReport.siteHealth.redirected / this.semRushReport.siteHealth.crowledPages * 100;
        this.semRushReport['blockedPercentage'] = this.semRushReport.siteHealth.blocked / this.semRushReport.siteHealth.crowledPages * 100;
        
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    }else{
      this.toastrService.error("Please fill all required fields", 'Error');
    }
  }

  downloadReport() {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = '<div style="padding: 55px;">' + document.getElementById('report').innerHTML + '</div>';
    var collapsedElements = document.getElementsByClassName('collapse');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    var opt = {
      margin: 0,
      filename: 'sem-rush.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    var output = 'save';
    var worker = html2pdf().from(element).set(opt);
    worker.toPdf().get('pdf').then((pdf) => {
      document.getElementById("downloadButton").style.display = 'block';
    }).output(output, 'sem-rush.pdf');
  }

}
