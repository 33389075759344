import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'console'
})
export class ConsolePipe implements PipeTransform {

  transform<T>(value: T, label?: string): T {
    if (label) {
      console.group(label);
      
      console.groupEnd();
    } else {
      
    }

    return value;
  }

}
