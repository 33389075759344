import {
  Component, EventEmitter, Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { LlListingTag, LocalListingsLocation } from '@shared/interfaces/intakes';
import { ActivatedRoute } from '@angular/router';
import { LocalListingsService } from '@shared/services/local-listings/local-listings.service';
import { IntakeFormComponent, IntakeTag } from '@modules/intakes/intake-component.interface';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { FrameFormContainerComponent } from '@modules/intakes/frame-form-container/frame-form-container.component';
import { IntakesService } from 'app/components/customer/intakes/services/intakes.service';
import { IntakeLocalListingsComponent } from '@modules/intakes/intake-local-listings/intake-local-listings.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { AssetsComponent } from '../../../../components/customer/intakes/add-intakes-data/basic/content/assets/assets.component';
import { FileUploadService } from '@shared/services/file-upload.service';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.css'],
})
export class LocationFormComponent implements IntakeFormComponent, OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() location: LocalListingsLocation;
  @Input() tags: IntakeTag[];
  @Input() intakeTypeId: number;
  @Input() sectionId: number;

  intakeId: string;
  officePhotosFilePath;
  photographs: any = [];
  locationInfoForm = new UntypedFormGroup({
    accessibility: new UntypedFormControl(null),
    address1: new UntypedFormControl(null, Validators.required),
    altPhone: new UntypedFormControl(null),
    amenities: new UntypedFormControl(null),
    appointment_link: new UntypedFormControl(null),
    category: new UntypedFormControl(null, Validators.required),
    city: new UntypedFormControl(null, Validators.required),
    country: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null),
    has_existing_gbp_listing: new UntypedFormControl(null),
    languages_supported: new UntypedFormControl(null),
    locationName: new UntypedFormControl(null),
    mobile_services: new UntypedFormControl(null, Validators.required),
    lstLocationWorkingHours: new UntypedFormArray([
      // Sunday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Sunday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed'),
      }),
      // Monday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Monday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed'),
      }),
      // Tuesday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Tuesday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed'),
      }),
      // Wednesday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Wednesday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed'),
      }),
      // Thursday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Thursday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed'),
      }),
      // Friday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Friday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed'),
      }),
      // Saturday
      new UntypedFormGroup({
        id: new UntypedFormControl(0),
        locationId: new UntypedFormControl(0),
        day: new UntypedFormControl({value: 'Saturday', disabled: true}),
        openTime: new UntypedFormControl('Closed'),
        closeTime: new UntypedFormControl('Closed')
      })
    ], Validators.required),
    phoneNumber: new UntypedFormControl(null),
    service_area: new UntypedFormControl(null),
    serviceOptions: new UntypedFormControl(null),
    state: new UntypedFormControl(null, Validators.required),
    year_established: new UntypedFormControl(null),
    zipCode: new UntypedFormControl(null, Validators.required),
  });
  parentComponent: FrameFormContainerComponent;
  siteId: string;
  businessNameTooltipText = `Your name should reflect your business's real-world name, as used consistently
                            on your storefront, website, stationery, and as known to customers.`;
  ownerEmailTooltipText = `What is the email associated with your existing listings?`;

  constructor(
    private activatedRoute: ActivatedRoute,
    private intakesService: IntakesService,
    private localListingsService: LocalListingsService,
    private notificationsService: NotificationsService,
    private _injector: Injector,
    private intakeLocalListingsComponent: IntakeLocalListingsComponent,
    private fileUploadService: FileUploadService,
    private modalService: NgbModal
  ) {
    this.activatedRoute.params.subscribe(
      (params: { id: string, siteId: string }) => {
        if (params.id && params.siteId) {
          this.intakeId = params.id;
          this.siteId = params.siteId;
        }
      },
    );

    this.parentComponent = this._injector.get<FrameFormContainerComponent>(
      FrameFormContainerComponent,
    );

    this.parentComponent = this._injector.get<FrameFormContainerComponent>(
      FrameFormContainerComponent,
    );
  }

  /**
   * Flag for if a location form has the 'Has Existing GBP Listing' selected
   *
   * @return {Boolean} If a location has an existing Google Business Profile listing
   */
  get hasExistingListing(): boolean {
    return this.locationInfoForm.controls['has_existing_gbp_listing'].value === 1;
  }

  /**
   * Flag for if a location form has mobile services selected
   *
   * @return {Boolean} If a location has mobile services selected
   */
  get hasMobileService(): boolean {
    return this.locationInfoForm.controls['mobile_services'].value == 'true';
  }

  ngOnInit(): void {
    if (this.location) {
      this.patchForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location) {
      this.patchForm();
    }
  }

  /**
   * Creates tag objets from the selected tag IDs
   *
   * @return {LlListingTag[]} An array of of formatted Intake Tag objects
   */
  getLocationTagsFromForm(): LlListingTag[] {
    const listingTags = [];
    const allTags = [
      ...this.locationInfoForm.get('accessibility').value,
      ...this.locationInfoForm.get('amenities').value,
      ...this.locationInfoForm.get('category').value,
      ...this.locationInfoForm.get('serviceOptions').value,
    ];

    for (let i = 0; i < allTags.length; i++) {
      const listingTag = {
        ll_tag_id: allTags[i],
        tagInfo: allTags[i].tagInfo,
        ll_location_id: this.location?.ll_location_id ? this.location.ll_location_id : 0,
      };
      listingTags.push(listingTag);
    }

    return listingTags;
  }

  /**
   * Updated the form with previously retrieved form data
   */
  patchForm(): void {
    const tags = this.intakesService.getLocationTagsByNameOrID(this.location.location_tags, true);

    if(this.location.office_photos)
    {
      var photo_array = this.location.office_photos.split(',');
      for(var i = 0; i < photo_array.length; i++) {
        photo_array[i] = photo_array[i].replace(/^\s*/, "").replace(/\s*$/, "");

        if(!this.photographs.includes(photo_array[i]))
        {
          this.photographs.push(photo_array[i]);
        }
      }  
    }

    if (this.location.hasOwnProperty('intake_location') && this.location.intake_location) {
      this.locationInfoForm.patchValue(this.location);
      this.locationInfoForm.patchValue(this.location.intake_location);
      this.locationInfoForm.patchValue({
        accessibility: tags.hasOwnProperty('Accessibility') ? tags['Accessibility'] : [],
        amenities: tags.hasOwnProperty('Amenities') ? tags['Amenities'] : [],
        category: tags.hasOwnProperty('Category') ? tags['Category'] : [],
        country: this.location.intake_location?.country.toLowerCase(),
        has_existing_gbp_listing: this.location.has_existing_gbp_listing,
        mobile_services: this.location.mobile_services == true ? 'true' : 'false',
        serviceOptions: tags.hasOwnProperty('Service Options') ? tags['Service Options'] : [],
        state: this.location.intake_location?.state.toLowerCase(),
        zipCode: this.location.intake_location.zipCode,
      });
    }
  }

  /**
   * Submits the form data to the API for saving
   */
  saveForm(): void {
    if(this.locationInfoForm.valid){
      const locationTags = this.getLocationTagsFromForm();
      const whArray = this.locationInfoForm.get('lstLocationWorkingHours') as UntypedFormArray;
      const whArrayRawValues = whArray.getRawValue();

      const locationData = {
        appointment_link: this.locationInfoForm.get('appointment_link').value,
        gbp_location_id: null,
        has_existing_gbp_listing: this.locationInfoForm.get('has_existing_gbp_listing').value,
        intake_id: this.intakeId,
        languages_supported: this.locationInfoForm.get('languages_supported').value,
        ll_business_id: this.location.ll_business_id,
        ll_location_id: this.location.ll_location_id,
        location_id: this.location.intake_location.id,
        site_id: this.location.site_id,
        intake_location: {
          address1: this.locationInfoForm.get('address1').value,
          address2: this.locationInfoForm.get('address2')?.value ?? '',
          altPhone: this.locationInfoForm.get('altPhone').value,
          city: this.locationInfoForm.get('city').value,
          country: this.locationInfoForm.get('country').value,
          email: this.locationInfoForm.get('email').value,
          email2: this.location.intake_location.email2,
          faxNumber: this.location.intake_location.faxNumber,
          googleBusinessName: this.locationInfoForm.get('locationName').value,
          has_hours: whArrayRawValues.length > 0,
          id: this.location.intake_location.id,
          intakeType: this.location.intake_location.intakeType,
          locationName: this.locationInfoForm.get('locationName').value,
          lstLocationWorkingHours: whArrayRawValues,
          neighborhoodBuilding: this.location.intake_location.neighborhoodBuilding,
          phoneNumber: this.locationInfoForm.get('phoneNumber').value,
          picture: this.location.intake_location.picture,
          state: this.locationInfoForm.get('state').value,
          type: this.location.intake_location.type,
          zipCode: this.locationInfoForm.get('zipCode').value,
        },
        location_tags: locationTags,
        office_photos: this.photographs.length > 0 ? this.photographs.join(',') : "",
        mobile_services: this.locationInfoForm.get('mobile_services').value,
        service_area: this.locationInfoForm.get('service_area')?.value ?? '',
        year_established: this.locationInfoForm.get('year_established')?.value ?? '',
      };

      locationData['mobile_services'] = locationData['mobile_services'] == 'true';
      if(!locationData.ll_location_id || locationData.ll_location_id == 0)
      {
        this.localListingsService.saveLocationData(locationData).subscribe(
          (res) => {
            if (res) {
              // Updates the header for the parent form container
              this.parentComponent.setHeaderLabel(locationData.intake_location.address1);

              // Show toast
              this.notificationsService.showToastSuccess(
                `${this.locationInfoForm.get('address1').value} has been saved.`,
                'Success'
              );
              this.intakeLocalListingsComponent.getExistingIntake();
            }
          },
          (error) => {
            this.notificationsService.showToastError(error?.error?.message, 'Save Error');
          }
        );
      }
      else
      {
        this.localListingsService.updateLocationData(locationData).subscribe(
          (res) => {
            if (res) {
              // Updates the header for the parent form container
              this.parentComponent.setHeaderLabel(locationData.intake_location.address1);

              // Show toast
              this.notificationsService.showToastSuccess(
                `${this.locationInfoForm.get('address1').value} has been saved.`,
                'Success'
              );
              this.intakeLocalListingsComponent.getExistingIntake();
            }
          },
          (error) => {
            this.notificationsService.showToastError(error?.error?.message, 'Save Error');
          }
        );
      }
    }
  }

  openUploadOfficePhotosPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.selectedFile = this.photographs;
    model.componentInstance.field = fieldName;
    model.componentInstance.isAssets = true;
    model.componentInstance.isMultipleFiles = true;
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if (this.photographs.length) {
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if (!n) {
            this.photographs.push(file);
          }
        })
        this.officePhotosFilePath = this.photographs.join(',');
      }
    }, (reason) => {
      
    });
  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.title = 'Attachments';
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('\\')[1] })
    });
    model.componentInstance.deleteAssets = (type?) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });
      this.fileUploadService.uploadedFiles['files'] = model.componentInstance.assets;

      this.photographs = [];
      // this.homepagePhotographyForm.controls.photography.setValue('');
      this.officePhotosFilePath = '';
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.officePhotosFilePath = this.photographs.join(',');
      })
      if (type != 'delete' || !this.photographs.length) {
        model.close();
      }
    }
  }
}