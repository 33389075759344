import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntakesService } from '../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../services/http.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  submenu = [];
  @Input() menu: any;
  @Input() intakeTypeId: any;
  @Input() selectedMenu: any;
  @Output() continue = new EventEmitter<object>();
  @Output() back = new EventEmitter<object>();
  @Output() changeStatus = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<object>();
  contentSubmenu = [];
  contentSelectedSubmenu: any = {};
  selectedItem: any = {};
  siteId: any;
  intakeId: any;
  message: boolean = true;

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeProductList(this.intakeId);
      }
    });

    this.intakesService.progressBar.subscribe(obj => {
      if (obj) {
        this.menu = obj.basicMenu;
        this.getContentMenu(obj.isSection);
      }
    });
  }

  ngOnInit() {
    this.dataService.currentMessage.subscribe(message => {
      this.message = message;
    });
    this.getContentMenu();
  }

  goToPageSection(event: any) {
    
    this.editClicked.emit({ "menuId": event.menuId, "sectionId": event.sectionId, "sectionName": event.sectionName });
  }

  getContentMenu(isSection?: boolean) {
    let menuClone = _.map(this.menu, _.cloneDeep);
    _.forEach(menuClone, menuItem => {
      if (menuItem.name === "Pages") {
        this.submenu = menuItem.submenu;
        if (this.selectedMenu?.selectedSectionId && !isSection) {
          this.selectedItem = _.find(this.submenu, item => item.name === this.selectedMenu.selectedSectionName);
        }
      }
      if (menuItem.name === "Content") {
        this.contentSubmenu = menuItem.submenu;
        this.contentSelectedSubmenu = this.contentSubmenu[0];
      }
    });
    this.updateSubmenuPercentage();
  }

  updateSubmenuPercentage() {
    _.forEach(this.submenu, menu => {
      _.forEach(this.contentSubmenu, cMenu => {
        if (menu.name === cMenu.name) {
          menu.percentage = cMenu.percentage;
          menu.updatedPercentage = cMenu.status === 2 ? cMenu.percentage : 0;
          menu.status = cMenu.status;
        }
      });
    });
    
  }

  receiveMessage($event: boolean) {
    this.message = $event;
  }

  openPage(item?: any) {
    
    if (item) {
      
      this.selectedItem = item;
      _.forEach(this.contentSubmenu, menu => {
        if (item && item.name === menu.name) {
          this.contentSelectedSubmenu = menu;
        }
      });
      this.message = true;
    } else {
      this.selectedItem = { id: 0 };
      this.message = true;
    }
    
  }
  

  getIntakeProductList(intakeId: any) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res: any) => {
      if (res) {
        this.siteId = res.siteId;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goBack(menu?: string) {
    if (menu === 'parent') {
      this.back.emit();
    } else {
      let selectedItem = this.selectedItem;
      if (selectedItem['id'] !== 11) {
        let index = _.findIndex(this.submenu, menu => menu.id === selectedItem['id']);
        this.selectedItem = this.submenu[index - 1];
      } else {
        this.selectedItem = {};
      }
      window.scroll(0, 0);
    }
  }

  goToNext(menu?: string) {
    if (menu === 'parent') {
      this.continue.emit();
    } else {
      let selectedItem = this.selectedItem;
      let index = _.findIndex(this.submenu, menu => menu.id === selectedItem['id']);
      this.selectedItem = this.submenu[index + 1];
      window.scroll(0, 0);
    }
  }

  changeIntakeStatus() {
    this.changeStatus.emit();
  }

  handleChangeStatus() {
    this.updateSubmenuPercentage();
  }
}
