import { Component, OnInit, Input } from '@angular/core';
import { ProductManagerService } from '../../../components/product-manager/services/product-manager.service';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../admin/masters/services/master.service';
import { DatePipe } from '@angular/common';
import { masterEnum } from '@shared/master-enum.enum';
import * as _ from 'lodash';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { SiteService } from '../../site/service/site.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import { ExcelService } from '../services/excel.service';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  providers: [DatePipe]
})
export class ProjectComponent implements OnInit {

  @Input() project;
  @Input() taskStatus;
  @Input() grp;
  rowGroupMetadata: any;
  userInfo;
  filterBy = {};
  projects;
  siteStatus;
  corporatePartner;
  accountManager;
  projectType;
  allProjects;
  siteType;
  groupBy: any = 0;
  groupByColumns = { 0: 'familyName', 1: 'corporatePartnerName', 2: 'accountManagerName' };


  constructor(
    private productManagerService: ProductManagerService,
    private masterService: MasterService,
    private activatedRoute: ActivatedRoute,
    private siteService: SiteService,
    private httpService: HttpService,
    private corporatePartnerService: CorporatePartnerService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    private excelService: ExcelService
  ) {
    activatedRoute.params.subscribe(val => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.groupBy = this.grp;
    this.filterBy['status'] = this.taskStatus;
    this.filterBy['project'] = this.project;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getCorporatePartner();
    this.getAccountManager();
    this.getProjects(this.filterBy);
    this.getSiteStatus();
    this.getProductType();
    this.getSiteType(masterEnum.site_type);
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.projects) {
      for (let i = 0; i < this.projects.length; i++) {
        let rowData = this.projects[i];
        let familyName = rowData[this.groupByColumns[this.groupBy]];
        try {
          if (i == 0) {
            this.rowGroupMetadata[familyName] = { index: 0, size: 1 };
          }
          else {
            let previousRowData = this.projects[i - 1];
            let previousRowGroup = previousRowData[this.groupByColumns[this.groupBy]];
            if (familyName === previousRowGroup)
              this.rowGroupMetadata[familyName].size++;
            else
              this.rowGroupMetadata[familyName] = { index: i, size: 1 };
          }
        }
        catch (e) {
          
        }
      }
    }
  }

  resetFilter() {
    this.filterBy = {};
    this.groupBy = this.activatedRoute.snapshot.queryParamMap.get('grp') || this.groupBy;
    this.filterBy['status'] = this.activatedRoute.snapshot.queryParamMap.get('task_status') || this.taskStatus;
    this.filterBy['project'] = this.activatedRoute.snapshot.queryParamMap.get('product_type') || this.project;
    this.getProjects(this.filterBy);
  }

  getProjects(filterBy) {
    this.allProjects = {};
    this.projects = {};

    filterBy['formattedStartDate'] = this.datePipe.transform(filterBy.startDate, 'yyyy-MM-dd');
    filterBy['formattedEndDate'] = this.datePipe.transform(filterBy.endDate, 'yyyy-MM-dd');
    filterBy['domainUrl'] = typeof this.filterBy['domainUrl'] == 'object' ? this.filterBy['domainUrl'].domainUrl : this.filterBy['domainUrl'];
    filterBy['siteName'] = typeof this.filterBy['siteName'] == 'object' ? this.filterBy['siteName'].siteName : this.filterBy['siteName'];

    this.productManagerService.getAdvanceSearchData(filterBy, this.userInfo.userId).subscribe(res => {
      if (res) {
        this.allProjects = Object.assign([], res);
        this.projects = res;

        /* Table create */
        this.updateRowGroupMetaData();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  exportAsXLSX() {
    let exelData = this.projects;
    _.forEach(this.projects, (user) => {
      let data = _.pick(user, ['familyName', 'domainUrl', 'siteName', 'siteType', 'corporatePartnerName', 'accountManagerName', 'taskStatusName']);
      exelData.push(data);
    })
    //  for(let i = 0; i < exelData.length; i++){
    //    exelData[i].Project = exelData[i]['familyName'];
    //    exelData[i].Domain_Url = exelData[i]['domainUrl'];
    //    exelData[i].Site_Name = exelData[i]['siteName'];
    //    exelData[i].Site_Type = exelData[i]['siteType'];
    //    exelData[i].Corporate_Partner = exelData[i]['corporatePartnerName'];
    //    exelData[i].Account_Manager = exelData[i]['accountManagerName'];
    //    exelData[i].Status = exelData[i]['taskStatusName'];

    //    delete exelData[i].familyName;
    //    delete exelData[i].familyId;
    //    delete exelData[i].siteId;
    //    delete exelData[i].siteName;
    //    delete exelData[i].domainUrl;
    //    delete exelData[i].siteType;
    //    delete exelData[i].accountManagerName;
    //    delete exelData[i].accountManager;
    //    delete exelData[i].isActive;
    //    delete exelData[i].isLive;
    //    delete exelData[i].isCancel;
    //    delete exelData[i].corporatePartnerName;
    //    delete exelData[i].corporatePartnerId;
    //    delete exelData[i].taskStatus;
    //    delete exelData[i].taskStatusName;
    //    delete exelData[i].issueKey;
    //    delete exelData[i].isUpgrade;

    //  }
    this.excelService.exportAsExcelFile(exelData, 'Project-Report');
  }

  getSiteStatus() {
    this.masterService.getGeneralMasterByType(8).subscribe((res) => {
      if (res) {
        this.siteStatus = res;
      }
    },
      err => {
        throw err;
      })
  }

  getProductType() {
    this.masterService.getGeneralMasterByType(4).subscribe((res) => {
      if (res) {
        this.projectType = res;
      }
    },
      err => {
        throw err;
      })
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getAccountManager() {
    this.siteService.getAccountManager().subscribe(res => {
      if (res) {
        this.accountManager = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getSiteType(id) {
    this.siteService.getMastersListById(id).subscribe((res) => {
      if (res) {
        this.siteType = res.masterList;
      }
    },
      err => {
        throw err;
      })
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.allProjects.filter(v => v.siteName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (result: string) => `${result['siteName']}`;

  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.allProjects.filter(v => v.domainUrl.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  domainFormatter = (result: string) => `${result['domainUrl']}`;
}
