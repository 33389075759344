import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ReportingService } from '../services/reporting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js'; 
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/http.service';
import { PageSampleComponent } from '../../customer/intakes/add-intakes-data/basic/pages/page-sample/page-sample.component';

@Component({
  selector: 'app-manufacturer-updates',
  templateUrl: './manufacturer-updates.component.html',
  styleUrls: ['./manufacturer-updates.component.css'],
  providers: [DatePipe]
})
export class ManufacturerUpdatesComponent implements OnInit {
  manufacturerUpdatesReport;
  reportParams = {startDate:null,endDate:null};
  @Input() siteId;
  constructor(
    private datePipe: DatePipe,
    private reportingService: ReportingService,
    private modalService: NgbModal,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
  }

  // getReport(siteId, params){
  //   let body = {};
  //   body['from_date'] = this.datePipe.transform(params.startDate, 'yyyy-MM-dd');
  //   body['to_date'] = this.datePipe.transform(params.endDate, 'yyyy-MM-dd');
  //   body['site_id'] = siteId;
  //   let emptyVal = _.pickBy(body, function (value, key) {
  //     return _.isEmpty(body[key]) && _.isNull(body[key]);
  //   });
  //   if (_.isEmpty(emptyVal)) {
  //   this.reportingService.getManufacturerUpdates(body).subscribe((res) => {
  //     if (res) {
  //       this.manufacturerUpdatesReport = res;
  //       
  //     }
  //   },
  //     err => {
  //       this.httpService.openErrorPopup(err.error.message);
  //     })
  //   }else{
  //     this.toastrService.error("Please fill all required fields", 'Error');
  //   }
  // }

  getSampleData(pageData) {
    let model = this.modalService.open(PageSampleComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size: 'lg'});
    let data = {
      pageName: pageData.title.rendered,
      content: pageData.content.rendered,
      title: ''
    };
    model.componentInstance.data = data;

  }

  downloadReport(report:string){
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display='none';
       var element = '<div style="padding: 55px;"></div>';
       var opt = {
        margin:       0,
        filename:     'manufacturer-updates.pdf',
        jsPDF:        { unit: 'in', format: 'b3', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };   
      let values = [];
      let gscTableData = this.manufacturerUpdatesReport;
      _.forEach(gscTableData, (data) => {
        let value = _.at(data, ['title.rendered', 'slug', 'post_modified']);
        values.push(value);
      })

    var output = 'save';
    if(report == 'print'){
      output = 'dataurlnewwindow';
    }
    var worker = html2pdf().from(element).set(opt);
    worker.toPdf().get('pdf').then((pdf)=>{
      pdf.autoTable({
        headStyles: {fillColor: null, textColor:[0, 0, 0]},
        head: [['Title', 'Slug', 'Date Modified']],
        body: 
          values,
        columnStyles: {1: {minCellWidth:.75}, 2: {minCellWidth:1},3: {minCellWidth:.75},4: {minCellWidth:.75}},
        startY: 0.5

      });
      document.getElementById("downloadButton").style.display='block';
    }).output(output,'manufacturer-updates.pdf');   
    
  }

}
