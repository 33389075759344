import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { tap } from 'rxjs/operators';
import { PaymentHistory, PaymentHistoryFilter } from '@shared/interfaces/billing';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private httpService: HttpService
  ) {

  }

  Charge(paymentInfo): any {
    return this.httpService.postApi('Stripe/PayInvoice', paymentInfo, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getStripeCards(siteId) {
    return this.httpService.getApi('Stripe/GetCards?SiteId=' + siteId)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        }));
  }
  addCard(cardInfo) {
    return this.httpService.postApi('Stripe/AddCard', cardInfo, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        }));
  }
  editCard(cardInfo) {
    return this.httpService.putApi('Stripe/AddCard', cardInfo, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        }));
  }
  deleteCard(cardInfo) {
    return this.httpService.deleteApi('Stripe/DeleteCard?SiteId=' + cardInfo.siteId + "&cardId=" + cardInfo.Id)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        }));
  }
  UpdateDefaultCard(cardInfo) {
    return this.httpService.putApi('Stripe/UpdateDefaultCard', cardInfo, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        }));
  }
  getStripeKey() {
    return this.httpService.getApi('Stripe/StripeKey')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        }));
  }

  /**
   * Retrieves a list of payments that can be filtered
   *
   * @param {PaymentHistoryF} filterBy
   *
   * @retutrn {Observable}
   */
  getPaymentList(filterBy: PaymentHistoryFilter): Observable<PaymentHistory[]> {
    let query = '';
  
    if (filterBy['site'] && filterBy['site'] !== 0) {
      query += 'SiteId=' + filterBy['site'] + '&';
    }
  
    if (filterBy['status'] && filterBy['status'] !== 'all') {
      query += 'Status=' + filterBy['status'] + '&';
    }
  
    query += filterBy['formattedStartDate']
      ? 'StartDate=' + filterBy['formattedStartDate'] + '&'
      : '';
    query += filterBy['formattedEndDate']
      ? 'EndDate=' + filterBy['formattedEndDate'] + '&'
      : '';
    query += filterBy['cp']
      ? 'SearchByCP=' + filterBy['cp'] + '&'
      : '';
    query += filterBy['paymentBy']
      ? 'paymentBy=' + filterBy['paymentBy'] + '&'
      : '';
    query += filterBy['cpAccountNo']
      ? 'cpAccountNo=' + filterBy['cpAccountNo'] + '&'
      : '';
    query += filterBy['productId']
      ? 'productId=' + filterBy['productId'] + '&'
      : '';
    query += filterBy['siteName']
      ? 'siteName=' + filterBy['siteName'] + '&'
      : '';
    query += filterBy['invoiceStatus']
      ? 'invoiceStatus=' + filterBy['invoiceStatus'] + '&'
      : '';
  

    if (query.endsWith('&')) {
      query = query.slice(0, -1);
    }
  
    
    const finalUrl = query
      ? `Stripe/PaymentList?${query}`
      : 'Stripe/PaymentList';
  

  
    return this.httpService.getApi(finalUrl).pipe(
      tap((res) => {
        return res;
      })
    );
  }
  

  acceptTermCondition(info) {
    return this.httpService.putApi('intake/AcceptTermCondition', info, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        }));
  }

  getProducts() {
    return this.httpService.getApi('salesforce/product')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        }));
  }
  getGeneralMasterByType(type): Observable<any> {
    return this.httpService.getApi(`master/General/${type}`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

}
