import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportingService } from '../../services/reporting.service';
import { ExcelService } from '../../services/excel.service';
import * as _ from 'lodash';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-pmsreport',
  templateUrl: './pmsreport.component.html',
  styleUrls: ['./pmsreport.component.css'],
})
export class PMSReportComponent implements OnInit, PageHasTable {
  @Input() siteId;
  @Input()reportParams;
  @Input()from;
  @Output() goToContacts = new EventEmitter<boolean>();

  PMSReportDate;
  adSources;
  bluePrintClinic;
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  sycleClinic;
  sycleColumnHeaders: ColumnHeader[];
  bluePrintReport;
  sycleReport;
  isShowSycle;
  isShowBluePrint;

  constructor(private reportingService:ReportingService,
              private excelService:ExcelService,
              public modalService: NgbModal) { }

  ngOnInit() {
    this.getAdSource();
    this.getSycleClinic();
    this.getBluePrintClinic();
    this.getBluePrintReport();
    this.getSycleReport();

    this.getTableData();
  }

  backToContacts() {
    this.goToContacts.emit(false);
  }
  downloadSycle(){
    let exelData = [];
    exelData = this.getFormateSycleData();
    exelData.splice(0, 0, {
      locationName:'Location Name',
      dateBook:'Date Book',
      timeBook:'Time Book',
      apptTypeId:'Appt Type',
      apptDate:'Appt Date',
      apptTime:'Appt Time',
      patientPhone:'Patient Phone',
      apptProvider:'Appt Provider',
      adSourceName:'Ad Source'
      });
    this.excelService.exportAsExcelFile(exelData, 'SycleReport');
  }
  getFormateSycleData(){
    let datePipeFilter = new DatePipe("en-US");
    let sycleData = [];

    _.forEach(this.sycleReport, (sycle) => {
      let obj = {
        'locationName':sycle.locationName,
        'dateBook':datePipeFilter.transform(sycle.apptBookOn, 'MMMM d, y') ,
        'timeBook':datePipeFilter.transform(sycle.apptBookOn, 'h:mm A') ,
        'apptTypeId':sycle.apptTypeId,
        'apptDate': datePipeFilter.transform(sycle.apptDate, 'MMMM d, y') ,
        'apptTime': this.convertTime(sycle.apptTime),
        'patientPhone':this.formatPhoneNumber(sycle.patientPhone),
        'apptProvider':sycle.apptProvider,
        'adSourceName':sycle.adSourceName
      }
      sycleData.push(obj)
    })
    return sycleData;
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      appointmentTime: 'string',
      apptBookOn: 'dateLong',
      apptBookOnTime: 'timeFromDate',
      apptDate: 'dateLong',
      apptTime: 'string',
      patientPhone: 'phone',
      timeBooked: 'string',
    };

    this.columnHeaders = [
      { field: 'clinicId', header: 'Clinic Id' },
      { field: 'locationName', header: 'Location Name' },
      { field: 'locationId', header: 'Location ID' },
      { field: 'dateBooked', header: 'Date Booked' },
      { field: 'timeBooked', header: 'Time Booked' },
      { field: 'appointmentType', header: 'Appointment Type' },
      { field: 'appointmentDate', header: 'Appointment Date' },
      { field: 'appointmentTime', header: 'Appointment Time' },
      { field: 'patientPhoneNumber', header: 'Patient Phone' },
      { field: 'appointmentProvider', header: 'Appointment Provider' },
    ];

    this.sycleColumnHeaders = [
      { field: 'locationName', header: 'Location Name' },
      { field: 'apptBookOn', header: 'Date Booked' },
      { field: 'apptBookOnTime', header: 'Time Booked' },
      { field: 'apptTypeId', header: 'Appointment Type' },
      { field: 'apptDate', header: 'Appointment Date' },
      { field: 'apptTime', header: 'Appointment Time' },
      { field: 'patientPhone', header: 'Patient Phone' },
      { field: 'apptProvider', header: 'Appointment Provider' },
      { field: 'adSourceName', header: 'Ad Source' },
    ];
  }

  downloadBlue() {
    let exelData = [];
    exelData = this.getFormateBlueData();
    exelData.splice(0, 0, {
        clinicId: 'Clinic Id',
        locationName: 'Location Name',
        dateBooked: 'Date Booked',
        timeBooked: 'Time Booked',
        appointmentDate: 'Appointment Date',
        appointmentTime: 'Appointment Time',
        patientPhoneNumber: 'Patient Phone',
        appointmentProvider: 'Appointment Provider',
      });
    this.excelService.exportAsExcelFile(exelData, 'Blue Print');
  }
  getFormateBlueData(){
    let datePipeFilter = new DatePipe("en-US");
    let blueData = [];

    _.forEach(this.bluePrintReport, (blue) => {
      let obj = {
        'clinicId':blue.clinicId,
        'locationName':blue.locationName,
        'dateBooked':datePipeFilter.transform(blue.dateBooked, 'MMMM d, y'),
        'timeBooked': this.convertTime(blue.timeBooked),
        'appointmentDate':datePipeFilter.transform(blue.appointmentDate, 'MMMM d, y'),
        'appointmentTime':this.convertTime(blue.appointmentTime),
        'patientPhoneNumber': this.formatPhoneNumber(blue.patientPhoneNumber),
        'appointmentProvider':blue.appointmentProvider
      }
      blueData.push(obj)
    })
    return blueData;
  }
  onValueChangeBlue(event) {
    this.reportParams.startDate = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams.endDate = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams._startDate = moment(event[0]).format('MM/DD/YY');
    this.reportParams._endDate = moment(event[1]).format('MM/DD/YY');
    this.getBluePrintReport();
  }
  onValueChangeSycle(event) {
    this.reportParams.startDate = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams.endDate = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams._startDate = moment(event[0]).format('MM/DD/YY');
    this.reportParams._endDate = moment(event[1]).format('MM/DD/YY');
    this.getSycleReport();
  }
  getAdSource(){
    this.reportingService.getGeneralMaster(11).subscribe(res=>{
      if(res){
        this.adSources=res;
        //this.reportParams['adSource']='';
      }
    },err=>{
      throw err;
    })
  }
  getBluePrintClinic(){
    this.reportingService.getBluePrintSetup(this.siteId).subscribe(res=>{
      if(res){
        this.bluePrintClinic=res;
        this.reportParams['clinicId']='';
        this.isShowBluePrint=this.bluePrintClinic.length==0?false:true;
      }
    },err=>{
      throw err;
    })
  }
  getSycleClinic(){
    this.reportingService.getSycleSetup(this.siteId).subscribe(res=>{
      if(res){
        this.sycleClinic=res;
        this.reportParams['locationName']='';
        this.isShowSycle=this.sycleClinic.length==0?false:true;
      }
    },err=>{
      throw err;
    })
  }
  getBluePrintReport(){
    this.reportingService.getBluePrintReport(this.siteId,this.reportParams).subscribe(res=>{
      if(res){
        this.bluePrintReport=res;
      }
    },err=>{
      throw err;
    })
  }

  /**
   * Queries the API for Sycle report data
   */
  getSycleReport(): void {
    this.reportingService.getSycleReport(this.siteId,this.reportParams).subscribe(
      (res) => {
        if (res) {
          this.sycleReport = res;

        for (let i = 0; i < this.sycleReport.length; i++) {
          console.group('Report');
          
          this.sycleReport[i]['apptBookOnTime'] = this.sycleReport[i].apptBookOn;
          
          console.groupEnd();
        }
      }
    },err=>{
      throw err;
    })
  }

  formatPhoneNumber(phoneNumberString) {
    try {
      if (phoneNumberString != null) {
        phoneNumberString = phoneNumberString.replace(/-/g, '');
      }
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
    }
    catch (e) {
      return phoneNumberString;
    }
    return phoneNumberString
  }
  convertTime(input) {
    return moment(input, 'HH:mm:ss').format('h:mm A');
  }
}

