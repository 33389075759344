import { Component, OnInit, Input } from '@angular/core';
import { IntakesService } from '../../../customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { HttpService } from '../../../../services/http.service';
import { UserService} from '../../../../components/admin/users/services/user-service.service';
import {auditRequest} from '../models/audit-request-model';
import { DataService } from '@shared/data-service.service';
import { routes } from 'app/components/client-communication-manager/cc-dashboard/cc-dashboard.routing';
import {AddressInfo} from '../models/audit-request-model';
@Component({
  selector: 'app-audit-request',
  templateUrl: './audit-request.component.html',
  styleUrls: ['./audit-request.component.css']
})
export class AuditRequestComponent implements OnInit {

  loading:boolean=false;
  auditRequestForm:UntypedFormGroup;
  auditTypeLists=[];
  practiceTypeLists=[];
  submittedBy:number=null;
  requestedByLists=[];
  existingData=[];
  customerId:number=null;
  userInfo;
  currentUser;
  currentUserFullName;
  currentUserId:number=null;
  newAuditRequest = new auditRequest();
  bool:boolean=false;
  AddressInfo=new AddressInfo();
  isShowCompetitor=false;
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private userService: UserService,
    public dataService:DataService
   ) {
     this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.customerId = params.id;
      }
    });
}

  ngOnInit() {
    this.userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
     this.submittedBy=this.userInfo.userId;
     //this.getCustomerForCP(this.submittedBy);
     this.getAuditRequestData(this.submittedBy);
    //this.initializeForm();
  }
   initializeForm() {
    this.auditRequestForm = this.formBuilder.group({
       id:this.existingData?this.existingData[0].id:0,
       auditTypeId:'',
       auditType: this.formBuilder.array(this.auditTypeLists ? this.auditTypeLists.map(c => this.formBuilder.group({
        name: new UntypedFormControl(c.name),
        id: new UntypedFormControl(c.id),
        checked: new UntypedFormControl(false)
      })) : []),
       selectedItems:new UntypedFormControl('',[Validators.required]),
       practiceName:new UntypedFormControl(this.existingData?this.existingData[0].practiceName:null,[Validators.required]),
       websiteUrl:new UntypedFormControl(this.existingData?this.existingData[0].websiteUrl:''),
       customerName:new UntypedFormControl(this.existingData?this.existingData[0].customerName:'',[Validators.required]),
       resoundAccount:new UntypedFormControl(this.existingData?this.existingData[0].resoundAccount:'',[Validators.required]),
       customerEmail:new UntypedFormControl(this.existingData?this.existingData[0].customerEmail:'',[Validators.required]),
       customerPhone:new UntypedFormControl(this.existingData?this.existingData[0].customerPhone:'',[Validators.required]),
       practiceTypeId: '',
       practiceType: this.formBuilder.array(this.practiceTypeLists ? this.practiceTypeLists.map(c => this.formBuilder.group({
        checked: new UntypedFormControl(false)
      })) : []),
       additionalNotes:new UntypedFormControl(this.existingData?this.existingData[0].additionalNotes:''),
       submittedBy:this.existingData?this.existingData[0].submittedBy:this.submittedBy,
       locations: this.formBuilder.array([
        this.newLocationInstance(),
      ])
    })
     this.getAuditType();
     this.getPracticeType();
     this.getUserInfo(this.userInfo.userId);
  }
  // getCustomerForCP(submittedBy){
  //   this.intakesService.getCustomerForCP(submittedBy).subscribe((res) => {
  //     if(res){
  //       this.requestedByLists=res;
  //     }
  //   },
  //     err => {
  //       this.httpService.openErrorPopup(err.error.message);
  //     });
  // }
  getAuditType(){
    this.intakesService.getAuditType().subscribe((res) => {
      if(res){
        this.auditTypeLists=res['masterList'];
        this.auditRequestForm.setControl('auditType', this.formBuilder.array(this.auditTypeLists.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedAuditType(c.id)),
          name: new UntypedFormControl(c.name),
          id: new UntypedFormControl(c.id),
        }))));

        this.auditRequestForm.controls.selectedItems.setValue(this.mapItems(this.auditRequestForm.controls.auditType.value),Validators.required)
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  mapItems(items) {
    let selectedItems = items.filter((item) => item.checked).map((item) => item.id);
    return selectedItems.length ? selectedItems : null;
  }
  valueOnChange(){
    this.bool=true;
    this.auditRequestForm.controls.auditType.valueChanges.subscribe((v) => {
    this.auditRequestForm.controls.selectedItems.setValue(this.mapItems(v),Validators.required);
    let selectedItems = v.filter((item) => item.checked).map((item) => item.id);
    this.isShowCompetitor=selectedItems.includes(236);
  });
  }
  getPracticeType(){
    this.intakesService.getPracticeType().subscribe((res) => {
      if(res){
        this.practiceTypeLists=res['masterList'];
        this.auditRequestForm.setControl('practiceType', this.formBuilder.array(this.practiceTypeLists.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedPracticeType(c.id)),
          name: new UntypedFormControl(c.name),
          id: new UntypedFormControl(c.id),
        }))));
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getCheckedPracticeType(id){
    if(this.existingData){
      let filteredElement = _.filter(this.existingData[0].lstPracticeTypeId, (dataItem) => {
        return dataItem.id == id;
      })
      if(filteredElement.length){
        return true;
      }
    }
  }
  getCheckedAuditType(id){
    if(this.existingData){
      let filteredElement = _.filter(this.existingData[0].lstAuditTypeId, (dataItem) => {
        return dataItem.id == id;
      })
      if(filteredElement.length){
        return true;
      }
    }
  }
getAuditRequestData(submittedBy){
      this.intakesService.getAuditRequestData(submittedBy).subscribe((res)=>{
        if(res){
          this.existingData = _.filter(res, (dataItem) => {
            return dataItem.id == this.customerId;
          })
          if(this.existingData.length <=0){
            this.existingData.push(this.newAuditRequest);
          }
          this.initializeForm();
          this.setLocationControl(this.existingData && this.existingData[0].locations ? this.existingData[0].locations : [this.AddressInfo]);
        }
      },err=>{
         this.httpService.openErrorPopup(err.error.message);
      })
}


isFieldValid(field: string,index:number =0) {
    
  // let control=this.callTrackingForm.get('lstLocation');
  //  if(field=='locationName'){
  //    for (let i=index;i<control['controls'].length;i++){ 
  //      return !control['controls'][i].controls.locationName.valid && control['controls'][i].controls.locationName.touched; 
  //    }
  //  }else if(field=='phoneNumber'){
  //   for (let i=index;i<control['controls'].length;i++){ 
  //     return !control['controls'][i].controls.phoneNumber.valid && control['controls'][i].controls.phoneNumber.touched; 
  //   }
  //  }else{
    return !this.auditRequestForm.get(field).valid && this.auditRequestForm.get(field).touched;
 //}
}

displayFieldCss(field: string) {
  return {
    'has-error': this.isFieldValid(field),
    'has-feedback': this.isFieldValid(field)
  };
}
validateAllFormFields(formGroup: any) {

  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      this.validateAllFormFields(control);
    } else if (control instanceof UntypedFormArray){
      for (let i=0;i<control.controls.length;i++){
        this.validateAllFormFields(control.controls[i]);
      }
    }

  });
}

saveAuditRequest(data){
   
  this.intakesService.saveAuditRequest(data).subscribe((res)=>{
    if(res){
      this.toastrService.success('Saved Successfully', 'Success');
      this.router.navigate(['/corporate-partner/audit-request']);
    }
  },err=>{
     
     this.httpService.openErrorPopup(err.error.message);
  })
}
getId(data){
  let id=[];
  _.forEach(data, (item) => {
        if(item.checked)
        id.push(item.id);
  })
 return id.join(',')
}

saveFormData(form){
    this.auditRequestForm.get('locations').updateValueAndValidity();
    form.value.auditTypeId=this.getId(form.value.auditType);
    form.value.practiceTypeId=this.getId(form.value.practiceType);
    form.value.submittedBy=this.submittedBy;
    let data=form.value;

    if(form.valid){
      this.saveAuditRequest(data);
    }else{
      
      this.validateAllFormFields(this.auditRequestForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
    
  }

  getUserInfo(id) {
    this.userService.getUserInfo(id).subscribe(res => {
      if (res) {
        this.currentUser = res;
        this.currentUserFullName = `${this.currentUser.firstName} ${this.currentUser.middleName} ${this.currentUser.lastName} `;
        this.currentUserId=this.currentUser.id;
      }
    },
      err => {
        throw err;
      });
  }
  setLocationControl(data) {
    this.auditRequestForm.setControl('locations', this.formBuilder.array(data.map(c => 
      this.newLocationInstance(c)
    )))
    
  }
  newLocationInstance(data?) {
    let location = this.formBuilder.group({
              id:new UntypedFormControl(data ? data.id : 0), 
              address1:new UntypedFormControl(data ? data.address1 : '',[Validators.required]),
              address2:new UntypedFormControl(data ? data.address2 : '',),
              city: new UntypedFormControl(data ? data.city : '',[Validators.required]),
              state:new UntypedFormControl(data ? data.state : '',[Validators.required]),
              zipCode:new UntypedFormControl(data ? data.zipCode : '',[Validators.required]),
              phoneNumber:new UntypedFormControl(data ? data.phoneNumber : '',[Validators.required]),
              competitorName1:new UntypedFormControl(data ? data.competitorName1 : null),
              competitorWebsite1:new UntypedFormControl(data ? data.competitorWebsite1 : null,[Validators.pattern(this.dataService.urlRegex)]),
              competitorName2:new UntypedFormControl(data ? data.competitorName2 : null),
              competitorWebsite2:new UntypedFormControl(data ? data.competitorWebsite2 : null,[Validators.pattern(this.dataService.urlRegex)]),
              competitorName3:new UntypedFormControl(data ? data.competitorName3 : null),
              competitorWebsite3:new UntypedFormControl(data ? data.competitorWebsite3 : null,[Validators.pattern(this.dataService.urlRegex)]),
              proposalId:new UntypedFormControl(data?data.proposalId:0),
    });
    return location;
  }
  updateFormControl(i, action){
    if (action == 'add') {
      this.auditRequestForm.controls.locations['controls'].push(
        this.newLocationInstance()
      );
    } else {
      if(this.auditRequestForm.controls.locations['controls'].length > 1){
        this.auditRequestForm.controls.locations['controls'].splice(i, 1);
      }
    }
    this.auditRequestForm.get('locations').updateValueAndValidity();
  }
  isFieldValidLocatio(field: string,index:number =0) {
    let control=this.auditRequestForm.controls.locations;
     if(field=='address1'){
       for (let i=index;i<control['controls'].length;i++){ 
         return !control['controls'][i].controls.address1.valid && control['controls'][i].controls.address1.touched; 
       }
     }else if(field=='address2'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.address2.valid && control['controls'][i].controls.address2.touched; 
      }
     }else if(field=='city'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.city.valid && control['controls'][i].controls.city.touched; 
      }
     }else if(field=='state'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.state.valid && control['controls'][i].controls.state.touched; 
      }
     }else if(field=='zipCode'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.zipCode.valid && control['controls'][i].controls.zipCode.touched; 
      }
    }else if(field=='phoneNumber'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.phoneNumber.valid && control['controls'][i].controls.phoneNumber.touched; 
      }
     }
     else if(field=='competitorWebsite1'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.competitorWebsite1.valid && control['controls'][i].controls.competitorWebsite1.touched; 
      }      
     }
     else if(field=='competitorWebsite2'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.competitorWebsite2.valid && control['controls'][i].controls.competitorWebsite2.touched; 
      }      
     }
     else if(field=='competitorWebsite3'){
      for (let i=index;i<control['controls'].length;i++){ 
        return !control['controls'][i].controls.competitorWebsite3.valid && control['controls'][i].controls.competitorWebsite3.touched; 
      }      
     }
     else{
      return !this.auditRequestForm.get(field).valid && this.auditRequestForm.get(field).touched;
    }
  }


}

