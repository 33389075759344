import { Component, OnInit ,Input,Output,EventEmitter, ViewChild} from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import {DataService} from '../../../../../../../shared/data-service.service'
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';
import {ContentTreeNodeComponent} from '@shared/content-tree-node/content-tree-node.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-content-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ContentProductComponent implements OnInit {
  activeIds: string[] = [];
  @Input() selectedItem;
  @Input() siteId;
  @Input() intakeTypeId;
  @Input() contentMenu;
  searchKeyword;
  selectedContentMenu;
  message: boolean = false;
  @Output() gotoPageSection = new EventEmitter<object>();
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>(); 
  @Output() changeStatus= new EventEmitter<any>();
  @ViewChild(ContentTreeNodeComponent)
  public contentTreeNodeComponent: ContentTreeNodeComponent;
  intakeId;
  allProductsData;
  filteredData = [];
  savedPages=[];
  searchTerm:string='';
  seoDescription:string='';
  title:string='';
  checkSavedPages:boolean=false;
    constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private dataService:DataService,
    public modalService: NgbModal,
    )
     {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
   }

   ngOnInit() {
    this.selectedContentMenu = _.find(this.contentMenu, (menu) => { return menu.name === this.selectedItem.name });
    
    this.getIntakeSavedPages(this.intakeId,this.selectedItem.id);
  }
  gotoPages(){
    this.gotoPageSection.emit({ "menuId": 2, "sectionId": 13, "sectionName": this.selectedContentMenu.name });
  }
  getIntakeSavedPages(intakeId,pageSectionId){

    let intakeID=intakeId?intakeId:this.intakeId;
    let pageSectionID=pageSectionId?pageSectionId:this.selectedItem.id;

    this.intakesService.getIntakeSavedPages(intakeID,pageSectionID).subscribe((res:any) => {
      this.savedPages=[];
      if (res) {
        this.savedPages=res;
        this.allProductsData = Object.assign([], this.savedPages);
        if(res.length){
          this.seoDescription=res[0].seoDescription;
          this.title=res[0].title;
          this.checkSavedPages=false;
        }else{
          this.checkSavedPages=true;
          this.title=this.selectedContentMenu.name;
        }
        this.getProductSectionData(this.intakeTypeId, this.intakeId); 
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  editPage(){
    this.messageEvent.emit(this.message);
  }
  expandAll(){
    this.contentTreeNodeComponent.expandAll();
  };
  collapseAll(){
    this.contentTreeNodeComponent.collapseAll();
  };
  goBack() {
    this.backClicked.next();
  }

  goToNext(){
    this.updateSectionStatus();
    this.continueClicked.next();
  }
  applyFilter(name){
    this.searchTerm=name;
  }
 updateSectionStatus(){
    let sectionStatusData = {
      intakeId: this.intakeId,
      lstIntakeSection: [{
        productSectionId: this.selectedContentMenu.id,
        status: 2,
        percentage: this.selectedContentMenu.percentage
      }],
      IsContentSection: true
    }
    this.postIntakeSection(sectionStatusData);
  }

  getProductSectionData(familyId, intakeId){
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
       this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({basicMenu:this.intakesService.basicMenu,overAllPercentage:res.OverAllPercentage, isSection:true});
      
      }else{
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId); 
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

}
