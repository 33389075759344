import { Component, OnInit,Input ,ViewChild, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr'; 
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';
import { TreeNodeComponent } from '@shared/tree-node/tree-node.component';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  @Input() selectedItem;
  @Input() siteId;
  @Input() intakeTypeId;
  searchKeyword;
  @ViewChild(TreeNodeComponent) TreeNodeComponent;
  @Output() continueClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();  
  @Output() changeStatus= new EventEmitter<any>(); 
  name:string;
  key: string = 'children';
  data: Array<Object>=[];
  intakeId:number=null; 
  title:string='';
  seoDescription:string='';
  allProductsData;
  filteredData = [];
  searchTerm:string='';
 constructor(
   private intakesService: IntakesService,
   private activatedRoute: ActivatedRoute,
   private httpService: HttpService,
   private toastrService: ToastrService
   ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
      }
    });
    }

  ngOnInit() {
    this.getProductInfo(this.selectedItem.name,this.intakeId);
  }

  getProductInfo(selectedItemName,intakeId){
    this.intakesService.getPageList(selectedItemName, intakeId).subscribe((response)=>{
        if(response){
          
          this.allProductsData = response;
            this.data = Object.assign([], this.allProductsData);
          this.title=response[0].title;
          this.seoDescription=response[0].seoDescription;
        }
    },err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }
  saveProductPages(mode?){
    this.TreeNodeComponent.saveFormData(this.selectedItem, mode);
    this.changeStatus.next();
   }
   expandAll(){
    this.TreeNodeComponent.expandAll();
  };
  collapseAll(){
    this.TreeNodeComponent.collapseAll();
  };

  goBack() {
    this.backClicked.next();
  }

  goToNext(){
    this.continueClicked.next();
  }

  applyFilter(name){
    this.searchTerm=name;
    // this.data = _.cloneDeep(this.allProductsData);
    // if(!_.isEmpty(name)){
    //   this.filteredData = [];
    //   this.data = this.getFilteredData(this.data  , name);
    // }      
  }

  getFilteredData(pageList, name){
    _.forEach(pageList, (data) => {
      if(data.type == 'P'){
        if( _.includes(data['name'].toLowerCase(), name)){
          _.forEach(this.filteredData, (item) => {
            if(item.id == data.parent){
              item.children.push(data);
            }
          })
        } 
      }else{
        let children = data.children;
        data.children = [];
        this.filteredData.push(data);
        this.getFilteredData(children, name);
      }
    })
    return this.filteredData;
  }

}
