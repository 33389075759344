import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import {PaymentService}from '../services/payment.service';
import { from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';
@Component({
  selector: 'app-other-payment',
  templateUrl: './other-payment.component.html',
  styleUrls: ['./other-payment.component.css']
})
export class OtherPaymentComponent implements OnInit {
  paymentInfo;
  constructor( public activeModal: NgbActiveModal,
              public paymentService:PaymentService, 
              private httpService: HttpService,
              public toastrService:ToastrService
    ) { }

  ngOnInit() {
    
  }
  charge(){
    let data={
      tokenId:null,
      description:"",
      amount:this.paymentInfo.amount,
      siteId:this.paymentInfo.siteId,
      intakeId:this.paymentInfo.intakeId
    };
    this.paymentService.Charge(data).subscribe(res => {
      if(res){
        this.toastrService.success('Payment Success', 'Success');
      }
      this.activeModal.close();
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      }); 
  }
  dismissModal() {
    this.activeModal.dismiss();
    return false;
  }

}
