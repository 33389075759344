import { Component, OnInit, Input, Output, EventEmitter,ChangeDetectorRef  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { IntakesService } from '../../../../services/intakes.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../../../../services/http.service';
import { SectionStatus } from '../../../basic/review/section-model';
import * as _ from 'lodash';
import { DataService } from '@shared/data-service.service';
@Component({
  selector: 'app-premium-promotions',
  templateUrl: './premium-promotions.component.html',
  styleUrls: ['./premium-promotions.component.css']
})
export class PremiumPromotionsComponent implements OnInit {

  intakeId;
  siteId;
  promotionsForm: UntypedFormGroup;
  referenceData;
  referenceDataOther;
  premiumPages;
  specialServiceData;
  @Input() productType;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Input() requiredFields;
  @Input() submenu;
  ssOther;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus= new EventEmitter<any>(); 
  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public dataService:DataService,
    private cdr: ChangeDetectorRef
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
        this.initializeForm();
      }
    });
  }

  ngOnInit() {
    this.getSpecialServiceData();
    this.getIntakePremium(this.intakeId);
  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }

  getIntakePremium(intakeId) {
    this.intakesService.getIntakePremium(intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.existingData = res;
        this.initializeForm();
        this.getSpecialServiceData();
        this.intakesService.updateRequiredFieldsStatus(res);
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  initializeForm() {
    this.promotionsForm = this.formBuilder.group({
      intakeId: this.intakeId,
      otherWebsitePromotion: new UntypedFormControl(this.existingData ? this.existingData.otherWebsitePromotion : ''),
      websitePromotion: new UntypedFormControl()
    })
  }

  isFieldValid(field: string,index:number =0) {
     let control=this.promotionsForm.controls['websitePromotion'];
     if(field=='textbox'){
       for (let i=index;i<control['controls'].length;i++){ 
         return !control['controls'][i].controls.textbox.valid && control['controls'][i].controls.textbox.touched; 
       }
     }else{
      return !this.promotionsForm.get(field).valid && this.promotionsForm.get(field).touched;
    }
  }

  onInput(event: any) {
    const inputElement = event.target;
    let inputValue = inputElement.value;

// Remove any characters that are not numbers, commas, decimals, or the first decimal point
inputValue = inputValue.replace(/[^0-9,.]|(\.(?=.*\.))/g, '');

    // Update the input value with the sanitized value
    inputElement.value = inputValue;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }

  save(form, mode) {

     if(this.promotionsForm.valid){

      
      let intakeData = _.cloneDeep(form.value);
      intakeData.otherWebsitePromotion = this.getSpecialServiceCheckedData(form.value.websitePromotion, 'other');
      intakeData.lwebsitePromotion = _.filter(form.value.websitePromotion, (val) => {return val.checked});
      intakeData.websitePromotion = this.getSpecialServiceCheckedData(form.value.websitePromotion);
      _.assign(this.existingData, intakeData);
      this.intakesService.postPremiumPagesData(this.existingData).subscribe((res) => {
        if (res) {
          this.changeStatus.next();
          this.toastrService.success("Saved successfully", 'Success');
          this.intakesService.updateRequiredFieldsStatus(form.value);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
          this.postIntakeSection(sectionStatus);
          _.assign(this.existingData, intakeData);
          if (mode == 'continue') {
            this.goToNext();
          }
        }
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });

     }else{

       
       this.validateAllFormFields(this.promotionsForm);
       this.httpService.openErrorPopup(this.dataService.requiredMsg);
     }

   
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getSpecialServiceData() {
    this.intakesService.getSmmSpecialServiceData().subscribe((res) => {
      if (res) {
        this.specialServiceData = res['masterList'];
        _.forEach(this.specialServiceData, (service) => {
          if (service.name.includes('{xx}')) {
            service['textbox'] = '';
            service.tempName = service.name.replace(/{xx}/g, "");
          }
        });
        this.ssOther = _.find(this.specialServiceData, (service) => {
          return service.name.toLowerCase() == 'other';
        });
        this.setPromotionControl();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getSpecialServiceCheckedData(specialService, other?) {
    if (other) {
      let otherPromo = _.find(specialService, (service) => {
        return service.name.toLowerCase() == 'other';
      })
      return otherPromo.textbox;
    } else {
      let specialServiceData = [];
      _.forEach(specialService, (service) => {
        service.textbox = service.textbox ? `{${service.textbox}}` : service.textbox;
        if (service.checked) {
          let value = `${service.id}${(service.textbox && service.name.toLowerCase() != 'other' ? service.textbox : '')}`;
          specialServiceData.push(value);

        }
      })
      let dataString = specialServiceData.join();
      return dataString;
    }
  }
  setPromotionControl() {
    this.existingData['lwebsitePromotion'] = !_.isEmpty(this.intakesService.websitePromotion) ? this.intakesService.websitePromotion['lwebsitePromotion'] : this.existingData['lwebsitePromotion'];
    this.existingData['otherWebsitePromotion'] = !_.isEmpty(this.intakesService.websitePromotion) ? this.intakesService.websitePromotion['otherWebsitePromotion'] : this.existingData['otherWebsitePromotion'];
    this.promotionsForm.setControl('websitePromotion', this.formBuilder.array(this.specialServiceData.map(c => this.formBuilder.group({
      checked: new UntypedFormControl(this.getExistingData('lwebsitePromotion', 'checked', c)),
      name: new UntypedFormControl(c.name),
      tempName: new UntypedFormControl(c.tempName ? c.tempName : c.name),
      textbox: new UntypedFormControl(this.getExistingData('lwebsitePromotion', 'textbox', c) || c.textbox),
      id: new UntypedFormControl(c.id),
      masterTypeId: new UntypedFormControl(c.masterTypeId),
      description: new UntypedFormControl(c.description)
    }))))
  }



  getExistingData(control, field, value) {
    if (this.existingData) {
      let data = _.find(this.existingData[control], (item) => {
        return item.id == value.id;
      })
      if (data) {
        if (field == 'checked') {
          let checked = true;
          return checked;
        }
        else if (field == 'textbox') {
          data.textbox = data.name.toLowerCase() == 'other' && this.existingData.otherWebsitePromotion ? `{${this.existingData.otherWebsitePromotion}}` : data.textbox;
          return data.textbox ? data.textbox.substring(data.textbox.indexOf("{") + 1, data.textbox.indexOf("}")) : data.name.substring(data.name.indexOf("{") + 1, data.name.indexOf("}"));
        }
      }
    }

  }
  validateListField(selectedInput, textField, list) {
    if (selectedInput) {
      let checkedField = _.filter(this.promotionsForm.controls[list]['controls'], (item) => { return item.value.checked && item.value.textbox != null });
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).setValidators([Validators.required]);
          field.get(textField).updateValueAndValidity();
        })
      }
    } else {
      let checkedField = _.filter(this.promotionsForm.controls[list]['controls'], (item) => { return !item.value.checked && item.value.textbox != null });
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).clearValidators();
          field.get(textField).setValue('');
          field.get(textField).updateValueAndValidity();
        })
      }
    }
  }
  goToNext() {
    this.continueClicked.next();
  }
  goBack() {
    this.backClicked.next();
  }

}
