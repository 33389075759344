import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportingService } from './services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../admin/users/services/user-service.service';
import { SiteService } from '../site/service/site.service';
import { MenuService } from '@core/page-header/service/menu.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as _ from 'lodash';
import 'jspdf-autotable';
import { SiteInfo } from '@shared/interfaces/site';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  @ViewChild('typeaheadBasicDom') searchInputViewChild: ElementRef;

  hasPermission = hasPermission;
  productId: number;
  selectedSiteId: number;
  loadReport: any;
  userId: string;
  sites: any;
  roleId: string;
  params: any;
  callTrackingProduct: any;
  imgUrl: any;
  intakeProduct: any;
  intakeTypeId: number;
  selectedSite: any;
  isCustomer: boolean;
  allSites: any;
  adUserReports: any;
  accountData: any;
  siteId: number;
  site: any;
  parentInfo: any;
  userInfo: any;
  uploadData: any;
  refreshFlag = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private reportingService: ReportingService,
    private userService: UserService,
    private siteService: SiteService,
    private menuService: MenuService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {

    if (this.userService.selectedProductId) {
      this.productId = this.userService.selectedProductId;
    }

    // Retrieve the selected site from localStorage
    const siteString = localStorage.getItem('selectedSite');
    if (siteString) {
      try {
        // Parse the JSON string into an object
        const site = JSON.parse(siteString);
        // Access properties of the 'site' object
        this.selectedSiteId = site.siteId;
        this.selectedSite = site;
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
      }
    }

    const currentPath = this.activatedRoute.snapshot.routeConfig.path;
    this.isCustomer = currentPath !== 'other-reports';
  }

  ngOnInit() {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString) {
      try {
        this.userInfo = JSON.parse(userInfoString);
        
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
      }
    }
    this.getData();
  }

  ngAfterViewInit() {
    if (this.searchInputViewChild) {
      this.searchInputViewChild.nativeElement.focus();
    }
  }

  getData() {
    try {
      this.userId = this.userInfo.userId;
      this.roleId = this.userInfo.roleId;
      const siteString = localStorage.getItem('selectedSite');
      this.selectedSite = siteString ? JSON.parse(siteString) : this.site;
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
      this.selectedSite = null;
    }

    
    this.getSites();
  }

  getSites(): void {
    this.siteService.getSites().subscribe(
      (res) => {
        if (res) {
          this.allSites = Object.assign([], res);
          this.sites = res;
          // Retrieve the current site directly from localStorage
          const siteString = localStorage.getItem('selectedSite');
          if (siteString) {
            try {
              this.site = JSON.parse(siteString);
              // Ensure the site object has the required 'id' or 'siteId' property
              if (this.site && this.site.hasOwnProperty('siteId')) {
                this.site['id'] = this.site['siteId'];
              }
            } catch (error) {
              console.error('Error parsing JSON from localStorage:', error);
              this.site = null;
            }
          }
          // Further processing with the retrieved site
          if (this.site) {
            let siteId = '';
            if (this.site.hasOwnProperty('id')) {
              siteId = this.site.id;
            } else if (this.site.hasOwnProperty('siteId')) {
              siteId = this.site.siteId;
            }
            // If a valid siteId is found, proceed to get intake product list
            if (siteId) {
              this.getIntakeProductList(+siteId); // Ensure siteId is a number
            }
          }
        }
      },
      (err) => {
        throw err;
      }
    );
  }

  getIntakeProductList(siteId: number) {
    
    this.siteService.getSiteInfo(siteId).subscribe(
      (res) => {
        
        try {
          localStorage.setItem('selectedSite', JSON.stringify(res));
        } catch (error) {
          console.error('Error stringifying JSON to localStorage:', error);
        }
        this.intakeProduct = res.requiredReport;
        this.intakeTypeId = res.intakeTypeId;
        this.selectedSite = { ...res };

        const product = _.find(this.intakeProduct, (product) => {
          return product.id === this.productId;
        });

        this.productId = product ? product.id : null;
        this.callTrackingProduct = _.find(this.intakeProduct, (product) => {
          return product.id === 4;
        });

        

        // Force refresh by toggling the refresh flag
        this.refreshFlag = !this.refreshFlag;
        this.cdr.detectChanges(); // Manually trigger change detection
      },
      (err) => {
        console.error('Error fetching site info:', err);
      }
    );
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 3
          ? []
          : this.allSites
              .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );

  formatter = (result: any) => {
    // Check if `result.name` already includes `result.domainUrl`
    if (result.domainUrl && !result.name.includes(`(${result.domainUrl})`)) {
      return `${result.name} (${result.domainUrl})`;
    }
    return result.name;
  };

  inputFormatter = (result: any) => {
    // The same check applied to the inputFormatter
    if (result.domainUrl && !result.name.includes(`(${result.domainUrl})`)) {
      return `${result.name} (${result.domainUrl})`;
    }
    return result.name;
  };

  openCallTrackingReport(event: any) {
    this.productId = event.productId;
    this.params = event.params;
  }

  onFileChanged(event: any) {
    this.imgUrl = [];
    this.uploadData = new FormData();
    if (event) {
      _.forEach(event.target.files, (targetFile) => {
        let file = targetFile;
        let name = targetFile.name.split('.');
        name = name[name.length - 1];
        var supportedFormate = 'csv';
        if (supportedFormate.indexOf(name.toLowerCase()) !== -1) {
          this.uploadData.append('myFile', file, file.name);
          let checkimg = event.target.value.toLowerCase();
          var reader = new FileReader();
          reader.readAsDataURL(targetFile); // read file as data url
          reader.onload = (event) => {
            // called once readAsDataURL is completed
            this.imgUrl.push({
              imgUrl: event.target['result'],
              extension: targetFile.name.split('.')[1],
              type: event.target['result'].toString().split('/')[1].split(';')[0],
              name: targetFile.name,
            });
          };
        } else {
          this.toastrService.error('Sorry, this file type is not permitted for security reasons.', 'Error');
        }
      });
    }
  }

  uploadFile(): void {
    if (this.uploadData.has('myFile')) {
      this.reportingService.uploadSalesBudget(this.uploadData).subscribe(
        (res) => {
          if (res) {
            this.imgUrl = [];
            this.toastrService.success('Sales Budget File Uploaded', 'Success');
          }
        },
        (err: Error) => {
          this.toastrService.error(err.message, err.name ?? 'Error');
        }
      );
    }
  }
}
