import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-intake-review',
  templateUrl: './intake-review.component.html',
  styleUrls: ['./intake-review.component.css']
})
/**
 * @deprecated
 */
export class IntakeReviewComponent implements OnInit {
@Input() selectedProduct;
@Output() continue = new EventEmitter<object>();
  constructor() { }

  ngOnInit() {
    
  }

  goToNextIntake(){
    this.continue.next();
  }

}
