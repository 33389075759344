import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddConfigComponent } from '../add-config/add-config.component';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../../services/http.service';
import * as _ from 'lodash';
import { ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.css']
})
export class ConfigListComponent implements OnInit, PageHasTable {
  allConfigData;
  columnHeaders: ColumnHeader[];
  config;
  filterBy = {name:null};

  @ViewChild('dt') dt: Table; // Change this line

  constructor(
    private activatedRoute:ActivatedRoute,
    private configurationService:ConfigurationService,
    public modalService: NgbModal,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.getConfig();
  });
  }

  ngOnInit() {
    this.getTableData();
  }

  getConfig(){
    this.configurationService.getConfig().subscribe(res => {
      if(res){
        this.allConfigData = res;
        this.config = Object.assign([], this.allConfigData);
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      {header: 'Name', field: 'name'},
      {header: 'Value', field: 'value'},
      {header: 'Description', field: 'description'},
    ];
  }

  openAddPopup(config) {
    var model = this.modalService.open(AddConfigComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.existingConfig = config;
    model.result.then((result) => {
      // setTimeout(() => {
        this.getConfig();
      // }, 50);
      // this.showMsg('Add Successfully')
      
    }, (reason) => {
      
    });

  }

  applyFilter(filterBy){
    let filteredUsers = this.allConfigData;
    if (filterBy) {
      if (!_.isEmpty(filterBy.name)) {
        filteredUsers = _.filter(filteredUsers, (user) => {
          return _.includes(user['name'].toLowerCase(), filterBy.name.toLowerCase());
        });
      }
      this.config = filteredUsers;
      this.resetPagination();
    }
  }

  public resetPagination() {
    if (this.dt) {
      this.dt.first = 0; // Reset pagination to the first page
    }
}
}
