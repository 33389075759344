import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { IntakesService } from '../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { SectionStatus } from '../basic/review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
import { PhonePipe } from '@pipes/phone/phone.pipe';

@Component({
  selector: 'app-call-tracking',
  templateUrl: './call-tracking.component.html',
  styleUrls: ['./call-tracking.component.css'],
  providers: [PhonePipe] // Provide the PhonePipe
})
export class CallTrackingComponent implements OnInit {
  callTrackingForm: UntypedFormGroup;
  intakeId;
  selectedIntakeData;
  @Input() submenu;
  @Input() menuId;
  @Input() intakeTypeId;
  @Output() continue = new EventEmitter<any>();  
  @Output() changeStatus = new EventEmitter<any>(); 
  existingData;
  checkLocationField: boolean = false;

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public dataService: DataService,
    private phonePipe: PhonePipe // Inject the PhonePipe
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeProductList(params.id);
      }
    });
  }

  ngOnInit() { 
    this.getExistingData(this.intakeId);
    this.initializeForm();
  }

  initializeForm() {
    this.callTrackingForm = this.formBuilder.group({
      intakeId: this.intakeId,
      isCallTrackingRequired:new UntypedFormControl(this.existingData && this.existingData.isCallTrackingRequired !=null ? this.existingData.isCallTrackingRequired:null,Validators.required),
      intakeType: new UntypedFormControl(this.intakeTypeId),
      lstLocation: this.formBuilder.array([])
    });

    this.setUpPhoneNumberFormatting();
  }

  setUpPhoneNumberFormatting() {
    const lstLocation = this.callTrackingForm.get('lstLocation') as FormArray;
    lstLocation.controls.forEach((group, index) => {
      const phoneNumberControl = group.get('phoneNumber');
      phoneNumberControl.valueChanges.subscribe(value => {
        const formattedPhoneNumber = this.phonePipe.transform(value);
        if (value !== formattedPhoneNumber) {
          phoneNumberControl.setValue(formattedPhoneNumber, { emitEvent: false });
        }
      });
    });
  }

  setLocationControl(data) {
    const locationArray = data.map(c => this.locationInstance(c));
    this.callTrackingForm.setControl('lstLocation', this.formBuilder.array(locationArray));
    this.setUpPhoneNumberFormatting();
  }

  locationInstance(data?) {
    let location = this.formBuilder.group({
      id: data ? data.id : this.callTrackingForm ? this.callTrackingForm.controls.lstLocation['controls'].length : 0, 
      phoneNumber: new UntypedFormControl(data ? data.phoneNumber : '',Validators.pattern(this.dataService.phoneRegex)), 
      locationName:new UntypedFormControl(data ? data.locationName : ''),
    });
    return location;
  }

  getExistingData(intakeId) {
    this.intakesService.getCallTrackingIntake(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        
        if (this.existingData.oIntakeLocation.lstLocation.length == 0) {
          this.initializeForm();
          this.getValue('', 'add');
        } else {
          this.initializeForm();
          this.setLocationControl(this.existingData.oIntakeLocation.lstLocation);
        }
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.selectedIntakeData = res;
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  getValue(i, action) {
    if (action == 'add') {
      this.callTrackingForm.controls.lstLocation['controls'].push(
        this.locationInstance()
      );
      this.setUpPhoneNumberFormatting();
    } else {
      if (this.callTrackingForm.controls.lstLocation['controls'].length > 1) {
        this.callTrackingForm.controls.lstLocation['controls'].splice(i, 1);
      }
    }
    this.callTrackingForm.get('lstLocation').updateValueAndValidity();
  }

  isFieldValid(field: string, index: number = 0) {
    let control = this.callTrackingForm.get('lstLocation');
    if (field == 'locationName') {
      for (let i = index; i < control['controls'].length; i++) { 
        return !control['controls'][i].controls.locationName.valid && control['controls'][i].controls.locationName.touched; 
      }
    } else if (field == 'phoneNumber') {
      for (let i = index; i < control['controls'].length; i++) { 
        return !control['controls'][i].controls.phoneNumber.valid && control['controls'][i].controls.phoneNumber.touched; 
      }
    } else {
      return !this.callTrackingForm.get(field).valid && this.callTrackingForm.get(field).touched;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }

  saveFormData(form, mode?) {
    this.callTrackingForm.get('lstLocation').updateValueAndValidity();
    let intakeData = this.callTrackingForm.value;

    if (this.callTrackingForm.valid || (!intakeData.isCallTrackingRequired && intakeData.isCallTrackingRequired != null)) {
      
      if (!intakeData.isCallTrackingRequired) {
        intakeData.lstLocation = [];
        this.checkLocationField = true;
      } else {
        _.forEach(intakeData.lstLocation, (location) => {
          this.checkLocationField = true;
          if (location.locationName == '' || location.phoneNumber == '') {
            this.checkLocationField = false;
            this.httpService.openErrorPopup(this.dataService.requiredMsg);
            return false;
          }
        });
      }

      if (this.checkLocationField) {
        intakeData = {
          isCallTrackingRequired: intakeData.isCallTrackingRequired,
          oIntakeLocation: intakeData
        };
        this.intakesService.saveCallTrackingIntake(intakeData).subscribe(res => {
          if (res) {
            this.changeStatus.next();
            this.toastrService.success(this.dataService.successMsg, 'Success');
            this.intakesService.updateRequiredFieldsStatus(intakeData);
            let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
            this.postIntakeSection(sectionStatus);
            if (mode == 'continue') {
              this.goToNext('parent');
            }
          }
        },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        });
      }
    } else {
      
      this.validateAllFormFields(this.callTrackingForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    });
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  goToNext(menu) {
    this.continue.next(menu);
  }
}
