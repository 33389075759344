import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ContentGenerationService } from '../content-generation/content-generation.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-content-generation',
  templateUrl: './content-generation.component.html',
  styleUrls: ['./content-generation.component.css'],
})
export class ContentGenerationComponent implements OnInit {
  links: string[] = [];
  filenames: string[] = [];
  csvFile: File;
  dateRange: Date[] = [];
  chatGPTModel: string = '';
  articleTypeModel: string = '';
  fileFilter: string = '';
  fileSelected: boolean = false;

  columnHeaders = [
    { field: 'name', header: 'Document Name' }
  ];

  constructor(
    private contentGenerationService: ContentGenerationService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    const now = new Date();
    const endDate = now.toISOString();
    const startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000).toISOString();

    this.contentGenerationService.getLinksForContent(startDate, endDate, this.articleTypeModel).subscribe(
      (response) => {
        this.links = response; 
        this.filenames = response.map(link => this.extractFilename(link));
      },
      (error) => {
        this.toastrService.error('Failed to fetch content links.', 'Error');
      }
    );
  }

  extractFilename(link: string): string {
    const urlParts = link.split('?')[0].split('/');
    return decodeURIComponent(urlParts[urlParts.length - 1]);
  }

  onFileChange(event: any): void {
    this.csvFile = event.target.files[0];
    if (this.csvFile) {
      this.fileSelected = true;
    } else {
      this.fileSelected = false;
    }
  }

  submitFile(): void {
    this.contentGenerationService.generateContentFromCSVFile(this.csvFile, this.chatGPTModel, this.articleTypeModel).subscribe(
      (response) => {
        this.toastrService.success('CSV file processed successfully!', 'Success');
        this.clearFileInput();
      },
      (error) => {
        this.toastrService.error('Failed to process CSV file.', 'Error');
      }
    );
  }

  clearFileInput(): void {
    const fileInput = document.getElementById('csvFileInput') as HTMLInputElement;

    if (fileInput) {
      fileInput.value = '';
    }

    this.dateRange = null;
  }

  bulkDownloadContent(): void {
    if (!this.dateRange || this.dateRange.length !== 2) {
      this.toastrService.error('Please select a date range.', 'Error');
      return;
    }
    
    const startDate = this.dateRange[0].toISOString();
    const endDate = this.dateRange[1].toISOString();
  
    this.contentGenerationService.bulkDownloadContent(startDate, endDate, this.articleTypeModel).subscribe(
      (response: Blob) => {
        const blob = new Blob([response], { type: 'application/zip' });
        const filename = `Content_${startDate.split('T')[0]}_to_${endDate.split('T')[0]}.zip`;
        saveAs(blob, filename);
        this.toastrService.success('Content downloaded successfully!', 'Success');
        this.clearFileInput();
      },
      (error) => {
        this.toastrService.error('Failed to download content.', 'Error');
      }
    );
  }

  displayContentByDate(): void {
    if (!this.dateRange || this.dateRange.length !== 2) {
      this.toastrService.error('Please select a date range.', 'Error');
      return;
    }
    
    const startDate = this.dateRange[0].toISOString();
    const endDate = this.dateRange[1].toISOString();

    this.contentGenerationService.getLinksForContent(startDate, endDate, this.articleTypeModel).subscribe(
      (response) => {
        this.links = response;
        this.filenames = response.map(link => this.extractFilename(link));
        this.clearFileInput();
      },
      (error) => {
        this.toastrService.error('Failed to fetch content links.', 'Error');
      }
    );
  }
  
  get filteredDocuments() {
    if (!this.fileFilter) {
      return this.links.map((link, index) => ({
        name: this.filenames[index],
        link: link
      }));
    }

    const filter = this.fileFilter.toLowerCase();
    return this.links
      .map((link, index) => ({
        name: this.filenames[index],
        link: link
      }))
      .filter(doc => doc.name.toLowerCase().includes(filter));
  }
}