import { Injectable } from '@angular/core';
import { HttpService } from '@services/http.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription, SubscriptionSwapInfo } from '@shared/interfaces/models';
import { HttpParams } from '@angular/common/http';
import { ProductPlanResponse } from '@shared/interfaces/billing';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  subscriptions: Subscription[] = [];

  constructor(private httpService: HttpService) { }

  /**
   * Retrieves a list of all products
   *
   * @return {Observable}
   */
  queryProducts(): Observable<any> {
    return this.httpService.getApi('Stripe/GetProducts/?activeOnly=true&limit=100')
      .pipe( (res) => res);
  }

  /**
   * Retrieves a list of products with the metadata attribute "nointake"
   *
   * @return {Observable}
   */
  queryProductsNoIntake(): Observable<any> {
    return this.httpService.getApi('stripe/product/nointake')
      .pipe((response) => response);
  }

  /**
   * Retrieves a list of all plans/price books belonging to a specific product
   *
   * @param {string} productID
   *
   * @return {Observable}
   */
  queryPlansByProduct(productID: string): Observable<ProductPlanResponse> {
    const urlParameters = new HttpParams({
      fromObject: {
        activeOnly: 'true',
        limit: '100',
        productId: productID },
    });

    return this.httpService.getApi(
      `stripe/getprices?${urlParameters.toString()}`
    );
  }

  /**
   * Retrieves a list of products and their prices based on their compatibility with the given family and salesforce corporate partner id
   *
   * @param {string} family
   * @param {number} familyType
   * @param {string} salesforceCorporatePartnerId
   * @param {string} oldPriceId
   *
   * @return {Observable}
   */
   queryCompatibleProductsForSwapping(family: string, familyType: number, cpId: number, oldPriceId: string): Observable<any> {
    const urlParameters = new HttpParams({
      fromObject: {
        family: family,
        familyType: familyType.toString(),
        cpId: cpId.toString(),
        oldPriceId: oldPriceId },
    });

    return this.httpService.getApi(
      `stripe/product/compatibleForSwap?${urlParameters.toString()}`
    );
  }

  /**
   * Queries for the prices for a product by using the Product ID and the Corporate Partner ID
   *
   * @param {string} productID The Product ID
   * @param {number} cpId The ID of the Corporate Partner
   * @param {string} oldPriceId The original Price ID, which will be filtered out of the results
   *
   * @return {Observable}
   */
  queryPricesByProductAndCorporatePartnerId(
    productID: string,
    cpId: string,
    oldPriceId = ''
  ): Observable<any> {
    const urlParameters = new HttpParams({
      fromObject: {
        'productId': productID,
        'corporatePartnerId': cpId,
        'oldPriceId': oldPriceId,
      },
    });

    return this.httpService.getApi(
      `stripe/GetPricesByProductCpid?${urlParameters.toString()}`
    );
  }

  /**
   * Retrieves a list of subscriptions
   *
   * @return {Observable}
   */
  getSubscriptions(): Observable<any[]> {
    return this.httpService.getApi(`intake/product/nointake`).pipe(
      tap((res) => {
        if (res) {
          
        }
      })
    );
  }

  /**
   * Sends subscription data to the API
   *
   * @param {Subscription} subscriptionData
   *
   * @return {Observable}
   */
  postSubscriptions(subscriptionData: Subscription): Observable<any> {
    return this.httpService.postApi('intake/product/nointake', subscriptionData);
  }

  /**
   * Replaces the plan on a subscription with another plan
   *
   * @param {SubscriptionSwapInfo} productData
   *
   * @return {Observable}
   */
  replaceSubscriptionPlan(productData: SubscriptionSwapInfo) {
    return this.httpService.postApi('stripe/product/subscriptionswap', productData);
  }
}
