import { Component, OnInit, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';
import { SectionStatus } from '../../review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../../services/http.service';

@Component({
  selector: 'app-enhanced-content',
  templateUrl: './enhanced-content.component.html',
  styleUrls: ['./enhanced-content.component.css']
})
export class EnhancedContentComponent implements OnInit {
  intakeId;
  siteId;
  enhancedPages;
  contentForm: UntypedFormGroup;
  premiumPages;
  premiumPagesOther;
  @Input() productType;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  @Input() requiredFields;
  @Input() submenu;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();

  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
        this.getEnhancedData(this.intakeId);
      }
    });
  }


  ngOnInit() {
    this.initializeForm();
    this.getPremiumPagesData();
    this.getRequiredFieldData();
  }

  getRequiredFieldData(gmb?) {
    let requiredFields = _.cloneDeep(this.requiredFields);
    _.forEach(this.submenu, (menu) => {
      menu.requiredFields = _.filter(requiredFields, (field) => {
        field.percentage = menu.percentage / requiredFields.length;
        return field.sectionId == menu.id;
      })
    })
  }



  initializeForm() {
    
    this.contentForm = this.formBuilder.group({
      intakeId: this.intakeId ? this.intakeId : null,
      referencedContentId: new UntypedFormControl(this.existingData && this.existingData.referencedContentId != null ? this.existingData.referencedContentId : ''),
      referencedContentOther: new UntypedFormControl(this.existingData ? this.existingData.referencedContentOther : ""),
      contentWrites: new UntypedFormControl()
    })
  }

  getEnhancedData(intakeId) {
    this.intakesService.getEnhancedData(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.initializeForm();
        this.getEnhancedPagesData();
        
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getEnhancedPagesData() {
    this.intakesService.getEnhancedPages().subscribe((res) => {
      if (res) {
        this.enhancedPages = res['masterList'];
        this.setContentWritesControl(this.enhancedPages);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  setContentWritesControl(contentWrites?) {
    var isNew = 1;
    if (this.existingData && this.existingData.contentWrites && this.existingData.contentWrites.length) {
      contentWrites = this.existingData.contentWrites;
      isNew = 0;
      this.contentForm.setControl('contentWrites', this.formBuilder.array(contentWrites.map(c => this.formBuilder.group({
        title: new UntypedFormControl(c.title || ''),
        id: new UntypedFormControl(isNew > 0 ? 0 : c.id || 0),
        notes: new UntypedFormControl(c.notes || '')
      }))));

    }else{
      this.contentForm.setControl('contentWrites', this.formBuilder.array(contentWrites.map(c => this.formBuilder.group({
        title: new UntypedFormControl(c.name || ''),
        id: new UntypedFormControl(isNew > 0 ? 0 : c.id || 0),
        notes: new UntypedFormControl('')
      }))));
    }
   
  }

  getExistingNotes(id) {
    if (this.existingData) {
      let content = _.find(this.existingData.contentWrites, (data) => {
        return data.masterId == id;
      })
      return content.notes;
    }

  }

  getPremiumPagesData() {
    this.intakesService.getReferenceContent().subscribe((res) => {
      if (res) {
        this.premiumPages = res['masterList'];
        this.premiumPagesOther = _.find(this.premiumPages, (page) => {
          return page.name.toLowerCase() == 'other';
        });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

isFieldValid(field: string,index:number =0) {
  let control=this.contentForm.get('contentWrites');
  if(field=='title'){
    for (let i=index;i<control['controls'].length;i++){ 
      return !control['controls'][i].controls.title.valid && control['controls'][i].controls.title.touched; 
    }
  }else{
   return !this.contentForm.get(field).valid && this.contentForm.get(field).touched;
 }
}

displayFieldCss(field: string) {
  return {
    'has-error': this.isFieldValid(field),
    'has-feedback': this.isFieldValid(field)
  };
}
validateAllFormFields(formGroup: any) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      this.validateAllFormFields(control);
    } else if (control instanceof UntypedFormArray){
      for (let i=0;i<control.controls.length;i++){
        this.validateAllFormFields(control.controls[i]);
      }
    }
  });
}


  saveFormData(form, mode?) {

    if(this.contentForm.valid){
      
     let enhancedIntake = form.value;
      if (enhancedIntake.referencedContentId != 156) {
        enhancedIntake.referencedContentOther = '';
      }
    this.intakesService.saveEnhancedIntakeData(enhancedIntake).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success(this.dataService.successMsg, 'Success');
        this.existingData = enhancedIntake;
        this.intakesService.updateRequiredFieldsStatus(this.existingData);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if (mode == 'continue') {
          this.goToNext();
        }
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
   }else{
     this.validateAllFormFields(this.contentForm);
     this.httpService.openErrorPopup(this.dataService.requiredMsg);
     
   }

  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.submenu[0].familyId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  goBack() {
    this.backClicked.next();
  }

  goToNext() {
    this.continueClicked.next();
  }
  validateField(selectedInput, textField, data) {
    if (data) {
      data && selectedInput == data.id ? this.contentForm.get(textField).setValidators([Validators.required]) : this.contentForm.get(textField).clearValidators();
    } else {
      selectedInput ? this.contentForm.get(textField).setValidators([Validators.required]) : this.contentForm.get(textField).clearValidators();
    }
    this.contentForm.controls[textField].setValue('');
    this.contentForm.get(textField).updateValueAndValidity();
  }
}
