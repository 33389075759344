import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddInstaPageComponent } from './add-insta-page/add-insta-page.component';
import { ConfirmModalComponent } from '@shared/confirm-modal/confirm-modal.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-google-ads',
  templateUrl: './google-ads.component.html',
  styleUrls: ['./google-ads.component.css'],
})
export class GoogleAdsComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  googleAdsForm: UntypedFormGroup;
  hasPermission = hasPermission;
  @Input() siteInfo;
  @Output() updateSite = new EventEmitter<any>();
  instapageData;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  siteId;

  constructor(
    private siteService: SiteService,
    private toastrService: ToastrService,
    public modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,) {
      this.activatedRoute.params.subscribe(params => {
        if (params.siteId) {
          this.siteId = params.siteId;
          this.getInstapageData(params.siteId);
        }
      });
    }

  ngOnInit() {
    this.initializeForm();

    this.getTableData();
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {

    };

    this.columnHeaders = [
      { field: 'pageId', header: 'Page ID' },
      { field: 'pageName', header: 'Page Name' },
    ];
  }

  initializeForm() {
    this.googleAdsForm = this.formBuilder.group({
      siteId: [this.siteInfo.id ? this.siteInfo.id : 0, [Validators.required]],
      gAdsCustomerId: [this.siteInfo.oSiteSetting.gAdsCustomerId ? this.siteInfo.oSiteSetting.gAdsCustomerId : '']
    });
  }
  // getSiteInfo(siteId) {
  //   this.siteService.getSiteInfo(siteId).subscribe((res) => {
  //     if (res) {
  //       this.siteInfo = res;
  //       this.initializeForm();
  //     }
  //   },
  //     err => {
  //       throw err;
  //     });
  // }
  setUp(form) {
    if (form.valid) {
      this.siteService.siteSetup(form.value).subscribe(res => {
        if (res) {
          this.toastrService.success('Google Ads Setup Done', 'success');
          this.updateSite.next(this.siteInfo.id);
        }
      },
        err => {
          this.toastrService.error(err.error);
        })
    }

  }

  getInstapageData(siteId){
    this.siteService.getInstapageData(siteId).subscribe(res=>{
      if(res){
        this.instapageData=res;
        
      }
    },
    err=>{
      this.toastrService.error(err.error);
    })
  }

  openAddPopup(page?) {
    var model = this.modalService.open(AddInstaPageComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.existingData = page;
    model.componentInstance.msg= page ? 'Updatd Successfully' : 'Added Successfully';
    model.result.then((result) => {
      setTimeout(() => {
        this.getInstapageData(this.siteId);
      }, 50);
      
    }, (reason) => {
      
    });

  }

  deleteInstaPage(Id){
    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = 'Are you sure to delete?';
    model.result.then((result) => {
      if(result){
      this.siteService.deleteInstaPage(Id).subscribe(res=>{
        if(res){
          this.toastrService.success('Delete successfully', 'success');
          this.getInstapageData(this.siteId);
        }
      },err=>{
        this.toastrService.error(err, 'Error');
      })
    }
    }, (reason) => {
      
    });
  }

}
