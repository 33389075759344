import { Component, OnInit } from '@angular/core';
import { DesignerService } from '../services/designer.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { HttpService } from '../../../services/http.service';
import {MenuService} from '../../../core/page-header/service/menu.service';

@Component({
  selector: 'app-des-dashboard',
  templateUrl: './des-dashboard.component.html',
  styleUrls: ['./des-dashboard.component.css']
})
export class DesDashboardComponent implements OnInit {
  dashboardData;
  notifications;
  pageNo = 1;
  showMore;
  userInfo=JSON.parse(localStorage.getItem('userInfo'));
  constructor(
    private designerService: DesignerService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private router: Router,
    private menuService:MenuService
  ) {
  }

  ngOnInit() {
    this.getDashboardData();
    this.getNotifications(0,this.pageNo);
  }

  getDashboardData() {
    this.designerService.getDasboardData(this.userInfo).subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }
  gotoCustomer(type?) {
    let params = {};
    switch (type) {
      case 'progress':
        params = { status: -5,isActive:true };
        break;
      case 'design':
      params = { status: 2 ,isActive:true};
        break;
      case 'review':
      params = { status: 4 ,isActive:true};
        break;
      case 'revision':
      params = {status:-5, back: 1,isActive:true };
        break;
      default:
        params = {}
    }
    this.router.navigate(['designer/sites'], { queryParams: params });
  }
  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }


}
