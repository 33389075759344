import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { DataService } from '@shared/data-service.service';
import { IntakesService } from '../../../app/components/customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { SectionStatus } from '../../components/customer/intakes/add-intakes-data/basic/review/section-model';
import * as _ from 'lodash';
import { HttpService } from '../../services/http.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PageSampleComponent } from '../../components/customer/intakes/add-intakes-data/basic/pages/page-sample/page-sample.component';
import { NodeItem, TreeCallbacks, TreeMode, TreeNgxComponent } from '../../modules/tree-ngx';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css']
})
export class TreeNodeComponent implements OnInit {
  
  @ViewChild('treeRef') treeRef: TreeNgxComponent; 
  @Input('data') items: Array<Object>;
  @Input('searchTerm') searchTerm:string;
  @Input() intakeTypeId;
  @Output() continueClicked = new EventEmitter<any>();
  intakeId:number=null;
  ProductSectionId: number = null;
  public checkedPageList= [];
  selectedPageList = {
    "ProductSectionId": this.ProductSectionId,
    "IntakeId": this.intakeId,
    "lstIntakePages": []
  };
  checkedPage = {
    "ItemId": null,
    "Type": '',
    "ParentId": null,
    "Name": '',
    "IsEditable": null,
    "notSubscribed":''
  }
  selectedPages=[];
  public treeCallbacks: TreeCallbacks;
  options = {
    mode: TreeMode.MultiSelect,
    checkboxes: true,
    alwaysEmitSelected: true
  }
  constructor(
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService, 
    public modalService: NgbModal,
    private httpService: HttpService,
    private dataService: DataService,
    private renderer2: Renderer2) { 
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.intakeId = params.id;
        }
      }); 
    }

  ngOnInit() { 
    this.treeCallbacks = {
      select: this.onSelect,
      unSelect: this.onUnselect
    };
  }
  private onSelect(item: NodeItem<any>) {
    
  }

  private onUnselect(item: NodeItem<any>) {
  
  }
  public selecedItemsChanged(items:any[]) {
    
  }


  saveFormData(selectedItem, mode?) {
    this.selectedPages = [];
    _.forEach(this.checkedPageList, (item) => {
      
      this.checkedPage = {
        "ItemId": item.id,
        "Type": item.type,
        "ParentId": item.parent,
        "Name": item.name,
        "IsEditable": item.isEditable,
        "notSubscribed":item.notSubscribed
      }
      this.selectedPages.push(this.checkedPage);
    })
    let homepageMenu = this.getHomePageMenu(selectedItem);
    this.ProductSectionId = selectedItem.id;
    this.selectedPageList = {
      "ProductSectionId": this.ProductSectionId,
      "IntakeId": this.intakeId,
      "lstIntakePages": this.selectedPages
    };
    let sectionStatusData = {
      intakeId: this.intakeId,
      lstIntakeSection: [{
        productSectionId: this.ProductSectionId,
        status: this.selectedPageList.lstIntakePages.length ? 2 : 1,
        percentage: this.selectedPageList.lstIntakePages.length ? selectedItem.percentage : 0
      }]

    }
    if (!homepageMenu.status) {
      let homepageStatus = {
        productSectionId: homepageMenu.id,
        status: 2,
        percentage: homepageMenu.percentage
      }
      sectionStatusData.lstIntakeSection.push(homepageStatus);
    }

    
    this.intakesService.saveIntakePages(this.selectedPageList).subscribe((response) => {
      if (response) {
        this.toastrService.success(this.dataService.successMsg, 'Success');
        this.postIntakeSection(sectionStatusData);
        if (mode == 'continue') {
          this.goToNext();
        }
      }
    }, err => {
      this.httpService.openErrorPopup(err.error.message);
    });

  };
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getHomePageMenu(selectedItem) {
    
    let homePageMenu;
    _.forEach(this.intakesService.basicMenu, (menu) => {
      if (menu.id == selectedItem.parent) {
        homePageMenu = _.find(menu.submenu, (sMenu) => {
          return sMenu.name == 'Homepage';
        })
      }
    })
    return homePageMenu;
  }
  getSampleData(page) {
    this.intakesService.getSampleData(page.id).subscribe((res) => {
      this.openSamplePopup(res);
    },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    })
  }
  openSamplePopup(pageData) {
    let model = this.modalService.open(PageSampleComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size: 'lg'});
    if(pageData.name=='Swimmer&#8217;s Ear'){
      pageData.name="Swimmer's Ear";
    }
    let data = {
      pageName: pageData.name,
      content: pageData.content,
      title: ''
    };
    model.componentInstance.data = data;
  }
  goToNext() {
    this.continueClicked.next();
  }
  expandAll() {
    this.treeRef.expandAll()
  };
  collapseAll() {
    this.treeRef.collapseAll()
  };
}
