import { Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteInfo } from '@shared/interfaces/site';
import { EmptyUser, ImpersonateUserConfig, UserInfo, UserProfile } from '@shared/interfaces/user';
import { roles } from '@shared/master-enum.enum';
import { UserService } from '../../../../components/admin/users/services/user-service.service';
import { TicketService } from '../../../../components/ticket/service/ticket.service';
import { WordPressHelper } from '@shared/utilities/WordPressUtilities';
import { hasPermission } from '@shared/policies/permissions';
import { SiteService } from '../../../../components/site/service/site.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-glyphs-client-actions',
  templateUrl: './glyphs-client-actions.component.html',
  styleUrls: ['./glyphs-client-actions.component.css'],
})
export class GlyphsClientActionsComponent implements OnInit, OnChanges {
  @Input() currentUser?: EmptyUser | UserInfo;
  @Input() enabled = true;
  @Input() parentInfo;
  @Input() site: Partial<SiteInfo>;
  @Input() showBilling = true;
  @Input() showConfig = true;
  @Input() showImpersonation = false;
  @Input() showJira = true;
  @Input() showReports = true;
  @Input() showWordPress = true;

  public hasPermission = hasPermission;
  public userRoleId: number;
  private userRole: string;

  private jiraURL: string;
  private jiraProjectKey: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private siteService: SiteService,
    private ticketService: TicketService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userRole = this.userService.getCurrentUserRole();
    this.userRoleId = Number(this.currentUser?.roleId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.site) {
      if (this.site) {
        this.updateActionsForNewSite();
      } else {
        this.handleSiteReset();
      }
      this.cdr.detectChanges();
    }
  }

  updateActionsForNewSite(): void {
    // If site data doesn't have `id`, normalize it
    if (this.site && !this.site.hasOwnProperty('id')) {
      this.site = this.siteService.normalizeIds(this.site);
    }
    this.enabled = true; 
    this.cdr.detectChanges();
  }

  handleSiteReset(): void {
    this.enabled = false; 
    this.userRoleId = null; 
    this.cdr.detectChanges();
  }

  get canImpersonateUser(): boolean {
    if (this.enabled && this.site?.userId > 0) {
      return hasPermission(this.userRoleId, 'IMPERSONATE_USER') && !this.parentInfo;
    }
    return false;
  }

  get hasWPSite(): boolean {
    return (
      this.site &&
      this.site.hasOwnProperty('wpSitePath') &&
      this.site.wpSitePath &&
      this.site.wpSitePath !== ''
    );
  }

  /**
   * Example: Go directly to Billing with query params (siteId), but do not read from localStorage.
   * We only write 'selectedSite' so legacy code won't break.
   */
  goToBillingSection(site: SiteInfo): void {
    if (!this.enabled) return;

    // Write only (no getItem usage)
    localStorage.setItem('selectedSite', JSON.stringify(site));

    this.router.navigate([`${this.userService.currentUserRole}/billing`], {
      queryParams: { siteId: site.id, productId: 1 },
    });
  }

  
  goToJiraDashboard(site: SiteInfo): void {
    if (this.enabled) {
      this.ticketService.getJiraUrl().subscribe(
        (res) => {
          if (res) {
            this.jiraURL = `${res['url']}/jira/core`;
            this.jiraProjectKey = res['ProjectKey'];

            window.open(
              `${this.jiraURL}/projects/${this.jiraProjectKey}${site.id}/board`,
              '_blank'
            );
          }
        },
        (err) => console.error(err)
      );
    }
  }


  goToReports(site: SiteInfo): void {
    if (this.enabled) {
      localStorage.setItem('selectedSite', JSON.stringify(site));
      this.router.navigateByUrl(`${this.userRole}/client-reports?siteId=${site.id}`);
    }
  }

  
  impersonateUser(site: SiteInfo): void {
    if (this.enabled) {
      localStorage.setItem('parentInfo', localStorage.getItem('userInfo'));

      const data = {
        userId: site.userId,
      };
      // This call returns userConfig with updated user info
      this.userService.impersonateUser(data).subscribe(
        (userConfig: ImpersonateUserConfig) => {
          if (userConfig) {
            localStorage.setItem('userInfo', JSON.stringify(userConfig));
            // We only store the new site ID, do not read anything from localStorage
            localStorage.setItem('impersonateSite', JSON.stringify(site.id));
            localStorage.setItem('selectedSite', JSON.stringify(site));

            this.router.navigate([], {
              queryParams: { siteId: site.id, productId: 1 },
              queryParamsHandling: 'merge'
            });

            // Or optionally load that user’s profile
            this.getUserProfile(userConfig);
          }
        },
        (err) => console.error(err)
      );
    }
  }

  getUserProfile(user: ImpersonateUserConfig): void {
    this.userService.getUserProfile(user.userId).subscribe(
      (res: UserProfile) => {
        if (res) {
          this.userService.setUserProfile(res);
          // Then go to the impersonated user’s default dashboard, if needed
          this.goToDashboard(user);
        }
      },
      (err) => console.error(err)
    );
  }

  goToDashboard(userConfig: ImpersonateUserConfig): void {
    const matchedRole = _.find(roles, (r) => r.roleId === userConfig.roleId);
    if (matchedRole) {
      this.router.navigateByUrl(matchedRole.url);
    }
  }

  logInToWordPress(site: SiteInfo): void {
    if (this.enabled && this.hasWPSite) {
      localStorage.setItem('selectedSite', JSON.stringify(site));
      const WordPress = new WordPressHelper(this.el, this.renderer, this.userService);
      WordPress.logInToWordPress(this.currentUser, site);
    }
  }

  /**
   * For "Site Config" action, we navigate to a new route with siteId as a parameter or query param.
   */
  viewSiteConfig(site: SiteInfo): void {
    if (this.enabled) {
      localStorage.setItem('selectedSite', JSON.stringify(site));
      this.router.navigate(['../site-config/', site.id], { relativeTo: this.activatedRoute });
    }
  }
}
