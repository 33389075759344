import { Component, OnInit, Inject } from '@angular/core';
import { SiteService } from '../../service/site.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewGenSetupComponent } from './review-gen-setup/review-gen-setup.component';
import { ConfirmModalComponent } from '../../../../shared/confirm-modal/confirm-modal.component';
import { DOCUMENT } from '@angular/common';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-review-gen',
  templateUrl: './review-gen.component.html',
  styleUrls: ['./review-gen.component.css'],
})
export class ReviewGenComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnDataTypesEmailTable ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  columnHeadersEmailTable: ColumnHeader[];
  hasPermission = hasPermission;
  userInfo=JSON.parse(localStorage.getItem("userInfo"));

  constructor(
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public modalService: NgbModal,
    private httpService: HttpService,
    @Inject(DOCUMENT) private document: any
  ) { }
  profiles
  users
  siteId
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.siteId) {
        this.siteId=params.siteId
        this.getProfile(this.siteId);
        this.getUser(this.siteId);
      }
    });

    this.getTableData();
  }

  getProfile(siteId){
    this.siteService.getReviewGen(siteId).subscribe((res) => {
      if (res) {
        this.profiles = res;
      }
    },
      err => {
         this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnHeaders = [
      { field: 'gradusId', header: 'Profile ID'},
      { field: 'profileName', header: 'Profile Name' },
    ];

    this.columnHeadersEmailTable = [
      { field: 'email', header: 'Email' },
    ];
  }

  getUser(siteId){
    this.siteService.getReviewGenUser(siteId).subscribe((res) => {
      if (res) {
        this.users = res;
      }
    },
      err => {
         this.httpService.openErrorPopup(err.error.message);
      });
  }
  setUpReviewGen(profile){
    let data;
    if(!profile){
       data={
        siteId:this.siteId
      };
    }
    else
      data= profile;
    var model = this.modalService.open(ReviewGenSetupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.existingData = data;
    model.componentInstance.type = 1;
    model.result.then((result) => {
      this.getProfile(this.siteId);
      
    }, (reason) => {
      
    });
  }
  deleteReviewGen(Id,Type){
    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = 'Are you sure to delete';
    model.result.then((result) => {
      if(result){
      this.siteService.deleteReviewGen(Id,Type).subscribe(res=>{
        if(res){
          this.toastrService.success('Delete successfully', 'success');
          if(Type==1)
            this.getProfile(this.siteId);
          else
            this.getUser(this.siteId);
        }
      },err=>{
        this.toastrService.error(err, 'Error');
      })
    }
    }, (reason) => {
      
    });
  }
  saveUser(user){
    let data;
    if(!user){
       data={
        siteId:this.siteId
      };
    }
    else
      data= user;
    var model = this.modalService.open(ReviewGenSetupComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.existingData = data;
    model.componentInstance.type = 2;
    model.result.then((result) => {
      this.getUser(this.siteId);
      
    }, (reason) => {
      
    });
  }
  type=1;
  openPage(type) {
    this.type = type;
  }
  getSSSOUrl(){
    this.siteService.getReviewSSOUrl(this.siteId).subscribe(res=>{
      if(res){
        
        window.open(res.url, "_blank");
        //this.document.location.href = res.url;
      }
    },err=>{
      throw err;
    })
  }

}
