import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../services/http.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  @Input() submenu;
  @Input() menuId;
  @Input() intakeTypeId;
  @Input() selectedMenu;
  @Output() continue = new EventEmitter<object>();
  @Output() back = new EventEmitter<object>();
  @Output() changeStatus = new EventEmitter<any>();
  selectedItem = {};
  siteId;
  intakeId;

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeProductList(this.intakeId);
      }
    });
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        
        _.forEach(obj.basicMenu, (menu) => {
          if (menu.id == this.menuId) {
            this.submenu = menu.submenu;
          }
        })
      }
    });
  }

  ngOnInit() {
    
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = _.find(this.selectedMenu.submenu, (item) => { return item.id == this.selectedMenu.selectedSectionId });
    }
    this.updateSubmenuPercentage();
  }

  updateSubmenuPercentage() {
    _.forEach(this.submenu, (menu) => {
      menu.percentage = 100 / this.submenu.length;
      menu.updatedPercentage = menu.status == 2 ? menu.percentage : 0;
    })
  }

  openPage(item?) {
    this.selectedItem = item ? item : {id: 0};
    // localStorage.setItem('selectedItem',JSON.stringify(this.selectedItem));
    this.intakesService.accordionCheckedPageList = [];
  }

  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res: any) => {
      if (res) {
        this.siteId = res.siteId;
        
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goBack(menu?) {
    if (menu == 'parent') {
      this.back.emit();
    } else {
      let selectedItem = this.selectedItem;
      if (selectedItem['id'] !== 11) {
        let index = _.findIndex(this.submenu, function (menu) {
          return menu.id == selectedItem['id'];
        });
        let i = this.submenu[index - 1].name == 'Homepage' ? 2 : 1;
        this.selectedItem = this.submenu[index - i];
      } else {
        this.selectedItem = {};
      }
      window.scroll(0,0);
    }
  }

  goToNext(menu?) {
    if (menu == 'parent') {
      this.continue.emit();
    } else {
      let selectedItem = this.selectedItem;
      let index = _.findIndex(this.submenu, function (menu) {
        return menu.id == selectedItem['id'];
      });
      let i = 1;
      //let i = this.submenu[index+1].name == 'Homepage' ? 2 : 1;
      this.selectedItem = this.submenu[index + i];
      window.scroll(0,0);
    }
  }
  changeIntakeStatus() {
    this.changeStatus.next();
  }

}
