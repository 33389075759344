import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IntakeFormComponent, IntakeTag } from '@modules/intakes/intake-component.interface';
import { SiteInfo } from '@shared/interfaces/site';
import { UserService } from '../../../../components/admin/users/services/user-service.service';
import { ActivatedRoute } from '@angular/router';
import { IntakeLocalListing, LlRequiredData } from '@shared/interfaces/intakes';
import { LocalListingsService } from '@shared/services/local-listings/local-listings.service';
import { ToastrService } from 'ngx-toastr';
import { IntakesService } from 'app/components/customer/intakes/services/intakes.service';
import { SectionStatus } from 'app/components/customer/intakes/add-intakes-data/basic/review/section-model';
import { IntakeLocalListingsComponent } from '@modules/intakes/intake-local-listings/intake-local-listings.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';

@Component({
  selector: 'app-local-listings-business-info',
  templateUrl: './local-listings-business-info.component.html',
  styleUrls: ['./local-listings-business-info.component.css'],
})
export class LocalListingsBusinessInfoComponent implements IntakeFormComponent, OnInit, OnChanges {
  @Input() llIntake: IntakeLocalListing;
  @Input() llRequiredData: LlRequiredData;
  @Input() tags: IntakeTag[];
  @Input() intakeTypeId: number;
  @Input() sectionId: number;

  businessInfoForm = new UntypedFormGroup({
    crowd: new UntypedFormControl(null),
    fromTheBusiness: new UntypedFormControl(null),
    health_insurances: new UntypedFormControl(null),
    healthAndSafety: new UntypedFormControl(null),
    logo: new UntypedFormControl(null),
    payment: new UntypedFormControl(null),
    website: new UntypedFormControl(null, Validators.required),
  });
  intakeId: string;
  siteId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private intakesService: IntakesService,
    private localListingsService: LocalListingsService,
    private toastrService: ToastrService,
    private intakeLocalListingsComponent: IntakeLocalListingsComponent,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params: {id: string, siteId: string}) => {
        if (params.id && params.siteId) {
          this.intakeId = params.id;
          this.siteId = params.siteId;
        }
      }
    );

// Attempt to retrieve the selected site from localStorage
const storedSite = localStorage.getItem('selectedSite');
if (storedSite) {
  const selectedSite: SiteInfo = JSON.parse(storedSite);

  if (selectedSite.hasOwnProperty('siteId')) {
    this.siteId = selectedSite.siteId;
  } else {
    this.siteId = selectedSite.id.toString();
  }
} else {
 
  this.siteId = null; 
}
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.llIntake){
      this.setFormValues();
    }
  }

  setFormValues(): void {
    if(this.llIntake){
      const tags = this.intakesService.getTagsByName(this.llIntake, true);

      this.businessInfoForm.patchValue(this.llIntake);
      this.businessInfoForm.patchValue({
        crowd: tags.hasOwnProperty('Crowd') ? tags['Crowd'] : [],
        fromTheBusiness: tags.hasOwnProperty('From the Business') ? tags['From the Business'] : [],
        healthAndSafety: tags.hasOwnProperty('Health & Safety') ? tags['Health & Safety'] : [],
        payment: tags.hasOwnProperty('Payment') ? tags['Payment'] : [],
      });
    }
  }

  /**
   * Save the data in the Local Listings Business Info form
   */
  saveForm(): void {
    if(this.businessInfoForm.valid){
      let formData = this.businessInfoForm.getRawValue();
      let listingTags = [];

      for(let i =0; i<[...formData.crowd, ...formData.fromTheBusiness, ...formData.healthAndSafety, ...formData.payment].length;i++)
      {
        // in order to save or update listingTags we only need the ll_tag_id and the ll_business_id or ll_location_id it belongs to.
        let listingTag = {
          'll_tag_id':[...formData.crowd, ...formData.fromTheBusiness, ...formData.healthAndSafety, ...formData.payment][i],
          'tagInfo': formData.tagInfo,
          'll_business_id':this.llIntake?.ll_business_id ? this.llIntake.ll_business_id : 0
        };
        listingTags.push(listingTag);
      }

      let postRequestData = {} as IntakeLocalListing;
      // ll_business_id must come from llIntake object or if none it should be zero
      postRequestData.ll_business_id = this.llIntake?.ll_business_id ? this.llIntake?.ll_business_id : 0;
      postRequestData.intake_id = this.intakeId !== '' ? +this.intakeId : 0;
      postRequestData.website = formData.website;
      postRequestData.logo = formData.logo;
      postRequestData.health_insurances = formData.health_insurances;
      postRequestData.locations = formData.location;
      postRequestData.business_tags = listingTags;

      //Means that listing already exists and we want to updated it
      if(postRequestData.ll_business_id > 0)
      {
        this.localListingsService.updateBusinessForm(postRequestData).subscribe(
          (res) => {
            if (res) {
              this.updateIntakeSectionStatus(postRequestData);
            }
          }, () => {
            this.toastrService.error('There was an unknown error trying to save', 'Error');
          }
        );
      }
      else
      {
        this.localListingsService.saveBusinessForm(postRequestData).subscribe(
          (res) => {
            if (res) {
              this.updateIntakeSectionStatus(postRequestData);
            }
          }, () => {
            this.toastrService.error('There was an unknown error trying to save', 'Error');
          }
        );
      }
    }
  }

  updateIntakeSectionStatus(postRequestData): void {
    this.toastrService.success('Saved successfully', 'Success');
    this.llRequiredData.website = postRequestData.website;
    this.intakesService.updateRequiredFieldsStatus(this.llRequiredData);
    let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
    this.postIntakeSection(sectionStatus);
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.toastrService.error(err.error.message, 'Error');
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (res) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });
        this.intakeLocalListingsComponent.getExistingIntake();
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  openUploadLogoPopup() {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    // set the existing logo if there is one. Must be in an array.
    model.componentInstance.selectedFile = this.businessInfoForm.get('logo').value ? [this.businessInfoForm.get('logo').value] : [];
    model.result.then((result) => {
      if (result) {
        this.businessInfoForm.controls.logo.setValue(result[0]);
      }
    }, (reason) => {
      
    });
  }
}
