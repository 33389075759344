import { Component, Input, OnInit } from '@angular/core';
import { ModalFrameComponent } from '@shared/components/frames/modal-frame/modal-frame.component';
import { Product, ProductForSwap, PricebookEntry, CompatibleProductQueryResult } from '@shared/interfaces/billing';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '@shared/services/subscription.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionSwapInfo } from '@shared/interfaces/models';
import { SuccessResponse } from '@shared/interfaces/application';
import { SiteInfo } from '@shared/interfaces/interfaces';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-change-product',
  templateUrl: './change-product.component.html',
  styleUrls: ['./change-product.component.css'],
})
export class ChangeProductComponent extends ModalFrameComponent implements OnInit {
  @Input() currentProduct: Product;
  @Input() site: SiteInfo;
  @Input() corporatePartnerList: [];

  confirmButtonLabel = 'Confirm Plan Change';

  changeProductForm = new UntypedFormGroup({
    plan: new UntypedFormControl(null, Validators.required),
    product: new UntypedFormControl(null, Validators.required)
  });

  errorMessage: string = null;

  plans: PricebookEntry[] = [];
  products: ProductForSwap[] = [];
  priceSelectMessage = 'Select a Stripe price plan...';

  /**
   * @param {SubscriptionService} subscriptionService
   * @param {NgbActiveModal} activeModal
   */
  constructor(public subscriptionService: SubscriptionService, public activeModal: NgbActiveModal) {
    super(activeModal);
  }

  ngOnInit(): void {
    // get the products and prices available for swapping with the selected product's family
    this.subscriptionService.queryCompatibleProductsForSwapping(
        this.currentProduct.family,
        this.currentProduct.familyType,
        this.site.corporatePartnerId,
        this.currentProduct.stripePricebookId)
      .subscribe((res: CompatibleProductQueryResult) => {
        if (res.success){
          this.products = res.data;
          var indexOfCurrentProduct = this.products.findIndex(product => product.description === this.currentProduct.stripePriceDescription);
          if(indexOfCurrentProduct > -1){
            this.products[indexOfCurrentProduct].description = "(Current Product) " + this.products[indexOfCurrentProduct].description;
            this.products[indexOfCurrentProduct].name = "(Current Product) " + this.products[indexOfCurrentProduct].name;
          }
        }
        if (res.errorMessage) {
          return throwError(new Error(res.errorMessage));
        }
      },
      (error) => console.error(error)
    );
  }

  get cpForm(): any {
    return this.changeProductForm.value;
  }

  // sets the selected product's price to the plans variable for the select to use
  setProduct(): void {
    this.plans = this.changeProductForm.controls.product.value.pricebookEntries;
  }

  changeProduct(): void {
    var formData = this.changeProductForm.value;
    const productData: SubscriptionSwapInfo = {
      oldStripePricePlanId: this.currentProduct.stripePricebookId,
      newStripePricePlanId: formData.plan.stripe_Pricebook_Id__c,
      siteId: this.site.id
    };

    this.subscriptionService.replaceSubscriptionPlan(productData).subscribe(
      (response: SuccessResponse) => {
        if (response && response.errorMessage) {
          this.errorMessage = response.errorMessage;

          this.changeProductForm.setErrors({ 'invalid': true });

          return throwError(new Error(response.errorMessage));
        } else {
          this.dismissModal();
        }
      },
      (error) => 
        console.error('Error occurred:', error)

    );
  }
}
