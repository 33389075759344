import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import {AccordionComponent} from '@shared/accordion/accordion.component';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpService } from '../../../../../../../services/http.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  @Input() selectedItem;
  @Input() siteId;
  @Input() intakeTypeId;
  @ViewChild(AccordionComponent) AccordionComponent;
  name:string;
  key: string = 'children';
  data: Array<Object>=[];
  intakeId:number=null;
  constructor(
    private intakesService: IntakesService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService
    ) {
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.intakeId = params.id;
        }
      });
     }

    ngOnInit() {
      this.getHomepageInfo(this.selectedItem.name,this.intakeId);
    }
  
    getHomepageInfo(selectedItemName,intakeId){
      this.intakesService.getPageList(selectedItemName, intakeId).subscribe((response)=>{
          if(response){
            
            this.data=response;
          }
      },err => {
        this.httpService.openErrorPopup(err.error.message);
      });
    }
    saveHomepagePages(){
      this.AccordionComponent.saveFormData(this.selectedItem.id);
     }

}
