import { Component, OnInit } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { ClinicLocationsService } from '@shared/services/clinic-locations/clinic-locations.service';
import { AddressData } from '@shared/interfaces/addresses';
import { HttpErrorResponse } from '@shared/interfaces/responses';
import { ToastrService } from 'ngx-toastr';
import statesData from '@shared/data/states.json';
import { SiteInfo } from '@shared/interfaces/site';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
  styleUrls: ['./manage-locations.component.css'],
})
export class ManageLocationsComponent extends TabComponent implements OnInit {
  locations: AddressData[] = [];
  site: SiteInfo; 
  addressErrorMessages = {};
  filteredLocations: AddressData[] = [];
  hasBillingAddress = false;
  locationToEdit: AddressData;
  showLocationAddButton = false;
  showAddForm = false;
  showEditForm = false;

  constructor(
    private clinicLocationsService: ClinicLocationsService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    // Load site from local storage
    const siteString = localStorage.getItem('selectedSite');
    if (siteString) {
      try {
        this.site = JSON.parse(siteString);
      } catch (error) {
      }
    } else {
      console.warn('No site found in local storage');
    }

    // If site is valid, fetch locations
    if (this.site && this.site.id) {
      this.getLocations();
    } else {

    }
  }

  get filterHasResults(): boolean {
    return this.filteredLocations.length > 0;
  }

  get hasLocations(): boolean {
    return this.locations.length > 0;
  }

  get numberOfLocations(): number {
    return this.locations.length;
  }

  deleteAddress(locationId: number) {
    this.clinicLocationsService.deleteAddress(locationId).subscribe(
      () => {
        this.toastrService.success('Location Removed');
        this.getLocations();
      },
      ({ error }: HttpErrorResponse) => {
        this.addressErrorMessages[locationId] = error.message;
        this.getLocations();
      }
    );
  }

  filterLocations(searchString: string) {
    this.filteredLocations = this.locations.filter((location: AddressData) => {
      return JSON.stringify(Object.values(location))
        .toLowerCase()
        .includes(searchString.toLowerCase());
    });
  }

  getLocations(overrideShowAddForm = false): void {
    if (!this.site || !this.site.id) {
      return;
    }

    this.hasBillingAddress = false;

    this.clinicLocationsService.getAddressesBySite(this.site.id).subscribe(
      (res) => {
        this.locations = res || [];
        this.filteredLocations = [];

        this.locations.forEach((location: AddressData) => {
          const locationData = location;

          // Append stateFullName if available
          if (location.state && location.country) {
            const isoCountryCode = location.country.toLowerCase();
            if (statesData.hasOwnProperty(isoCountryCode)) {
              const subdivisions = statesData[isoCountryCode];
              const matchedSubdivision = subdivisions.find(
                (x) => x.abbreviation.toLowerCase() === location.state.toLowerCase()
              );
              if (matchedSubdivision) {
                locationData.stateFullName = matchedSubdivision.name;
              }
            }
          }

          if (location.isBillingAddress === true) {
            this.hasBillingAddress = true;
          }

          if (this.addressErrorMessages.hasOwnProperty(location.addressId)) {
            locationData.errorMessage = this.addressErrorMessages[location.addressId];
          }

          this.filteredLocations.push(locationData);
        });
      },
      (err) => {

        throw err;
      },
      () => {
        this.showEditForm = false;
      }
    );
  }

  hideAddForm(): void {
    this.showAddForm = false;
  }

  showAddLocationForm(): void {
    this.showAddForm = true;
    this.showEditForm = false;
    this.showLocationAddButton = true;
  }

  showEditLocationForm(location: AddressData): void {
    this.locationToEdit = location;
    this.showAddForm = false;
    this.showEditForm = true;
    this.showLocationAddButton = true;
  }

  toggleAddLocationForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  private clearAllData(): void {
    this.locations = [];
    this.filteredLocations = [];
    this.addressErrorMessages = {};
    this.hasBillingAddress = false;
    this.locationToEdit = null;
    this.showLocationAddButton = false;
    this.showAddForm = false;
    this.showEditForm = false;
  }
}
