import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SalesAndMarketingService } from '../services/sales-and-marketing.service';
import { roles } from '@shared/master-enum.enum';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { MenuService } from '@core/page-header/service/menu.service';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-sm-dashboard',
  templateUrl: './sm-dashboard.component.html',
  styleUrls: ['./sm-dashboard.component.css']
})
export class SmDashboardComponent implements OnInit {
  dashboardData;
  selectedSite;
  notifications;
  pageNo = 1;
  role;
  showMore;
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private httpService: HttpService,
    private menuService: MenuService,
    private salesAndMarketingService: SalesAndMarketingService
  ) {
    this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));
  }

  ngOnInit() {
    this.getDashboardData();
    this.getNotifications(this.selectedSite ? this.selectedSite['siteId']: 0, this.pageNo);
    let currentRole = JSON.parse(localStorage.getItem('userInfo')).roleId;
    this.role = _.find(roles, (role) => {
      return role.roleId == currentRole;
    })
  }

  getDashboardData() {
    this.salesAndMarketingService.getDasboardData().subscribe(res => {
      if (res) {
        this.dashboardData = res[0];
        
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  gotoCustomer(params) {

    // let params = {};
    // if(type == 'new'){
    //   params = { dt: 1, startDate: moment().subtract(7, 'days').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY') };
    // }else{
    //   params = { isLive: true, product_type: productType || null };
    // }
    this.router.navigate([`${this.role.url}/sites`], { queryParams: params });
  }

  gotoCompletedProjects(){
    this.router.navigate([`${this.role.url}/completed-projects`]);
  }

  gotoProjects(params){
    this.router.navigate([`${this.role.url}/projects`], { queryParams: params });
  }
  gotoAuditProposal(){
    let params = {};
    params = { startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY') };
    this.router.navigate([`${this.role.url}/audit-request`], { queryParams: params });
  }

  getNotifications(Siteid, pageNo) {
    this.menuService.getNotifications(Siteid, pageNo).subscribe((res) => {
      if (res) {
        this.pageNo = pageNo;
        this.notifications = res;
        this.showMore = this.notifications.length < (pageNo * 10) ? false : true;
      }
    },
      err => {
        throw err;
      });
  }

}
